export enum Services {
    MyDocuments,
    DocumentsSharedWithMe,
    Experts,
    Patients,
    Billing,
    ExpertisesSent,
    ExpertisesReceived,
    AccountsAdministration,
    Invoices,
}
