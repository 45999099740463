import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Form, Input, Tooltip } from 'antd';
import { memo, ReactElement, useEffect, useState } from 'react';
import { renderMessage } from 'services/helpers';
import './index.less';
import TokenValidatingModal from './token-validating-modal';
import { useUsersActions } from 'redux/actions/users.action';

interface IProps {
    save: (values: IRequestResetParams) => void;
    validateCode: (values: IValidationCodeParams) => void;
    hideDocumentsModal: () => void;
    isValidatingModalShown: boolean;
    message?: IMessage;
    user?: IUser;
}

const EmailComponent = (props: IProps): ReactElement => {
    const isEmailVerified = true;
    const [hasChanged, setChanged] = useState<boolean>(!isEmailVerified);
    const [form] = Form.useForm<IRequestResetParams>();
    const { hasEmail } = useUsersActions();
    const onSave = (values: IRequestResetParams) => {
        props.save(values);
    };

    const onValidateCode = (values: IValidationCodeParams) => {
        props.validateCode(values);
    };

    const onFieldsChange = () => {
        setChanged(form.getFieldValue('email') !== props.user?.email || !isEmailVerified);
    };

    const setForm = (user: IUser) => {
        const { email } = user;

        form.setFieldsValue({
            email,
        });
    };

    useEffect(() => {
        const user = props.user;
        if (user) {
            setForm(user);
            onFieldsChange();
        }
    }, [props.user]);

    const renderVerifyTooltip = (): ReactElement => {
        return hasChanged ?
            <Tooltip title="Non vérifiée"><CloseOutlined /></Tooltip> :
            <Tooltip title="Vérifiée"><CheckOutlined /></Tooltip>;
    };

    return <Card className="email" bordered={false} title="Email">
        <Form
            form={form}
            layout="vertical"
            onFinish={onSave}
            initialValues={{
                email: props.user?.email,
            }}
            onFieldsChange={onFieldsChange}
        >
            {renderMessage(props.message)}

            {!hasEmail ?
                <Alert type="warning" message="Une adresse email est obligatoire pour recevoir les notifications" /> :
                <Alert type="info" message="Votre adresse mail est votre identifiant de connexion" />}

            <Form.Item name="email" label="Adresse mail">
                <Input className="field" suffix={renderVerifyTooltip()} />
            </Form.Item>

            <Form.Item className="actions">
                <Button type="primary" htmlType="submit" disabled={!hasChanged}>Vérifier et sauver</Button>
            </Form.Item>
        </Form>

        <TokenValidatingModal show={props.isValidatingModalShown} validate={onValidateCode}
            onHide={props.hideDocumentsModal} />
    </Card>;
};

export default memo(EmailComponent);
