import { BulbOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Alert, Empty, Select, Spin } from 'antd';
import { ReactElement, memo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Rpps } from '.';
import './index.less';

interface IProps {
    searchRpps: (value: string) => Promise<IRpps[]>;
    getRpps: (rpps: Rpps) => Promise<void>;
    isSearching: boolean;
}

const { Option } = Select;

const RppsSearchComponent = (props: IProps): ReactElement | null => {
    const [users, setUsers] = useState<IRpps[]>();

    const debounced = useDebouncedCallback(
        async (value: string) => {
            setUsers(undefined);
            setUsers(await props.searchRpps(value));
        }, 400,
    );

    const onSearchRpps = async (value: string) => {
        debounced(value)?.catch((err: Error) => {
            console.log(err);
        });
    };

    const onChangeRpps = async (value?: Rpps) => {
        if (!value) {
            return;
        }

        await props.getRpps(value);
    };

    const displayRpps = (rpps: string): string => {
        return rpps.slice(0, 1) + ' ' + rpps.slice(1);
    };

    const renderUsers = (usersIn?: IRpps[]): (ReactElement | undefined)[] => {
        return (usersIn || []).map((user: IRpps) => {
            const { rpps, firstName, lastName } = user;

            return rpps ? <Option value={rpps} key={`pp_${rpps}`}>
                <div className="list-item-main-info">
                    <div>{user.civility} {firstName} {lastName}</div>
                    <div>{displayRpps(rpps)}</div>
                </div>
                <div className="list-item-secondary-info">
                    {
                        user.professionLabel ? <div>
                            <div><BulbOutlined /></div>
                            <div>{user.professionLabel}</div>
                        </div> : null
                    }
                </div>
                {
                    user.city ? <div className="addresses">
                        <div><EnvironmentOutlined />{user.city}</div>
                    </div> : null
                }
            </Option> : undefined;
        });
    };

    const renderDropdown = (menu: ReactElement): ReactElement => {
        return props.isSearching ?
            <div className='search-loading-indicator'><Spin tip='Loading' /></div> :
            <>
                {menu}
                <Alert className="search-warning" type="warning" message="Affinez votre recherche pour afficher plus de résultats" />
            </>;
    };

    return <Select
        className="rpps-search"
        showSearch
        placeholder="nom, prénom, numéro RPPS, adresse e-mail"
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={onSearchRpps}
        onChange={onChangeRpps}
        notFoundContent={props.isSearching ? <div className='search-loading-indicator'><Spin tip='Loading' /></div> : <Empty description={<span>Pas de résultat</span>} />}
        allowClear
        dropdownRender={renderDropdown}
    >
        {renderUsers(users)}
    </Select>;
};

export default memo(RppsSearchComponent);
