import NotificationComponent from './component';
import React, { memo, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

const Notification = (): ReactElement | null => {
    const { pathname, hash } = useLocation();
    const id = pathname.split('/')[2];
    const token = hash.slice(1);

    return <NotificationComponent id={id} token={token}/>;
};

export default memo(Notification);
