import { AxiosResponseHeaders } from 'axios';
import { routes } from 'routes';
import * as Api from './api.interface';
import { Civility, CivilityOptions, DefaultCivility } from './helpers';
import moment from 'moment/moment';

const RealApi: Api.IApi = {
    login: async (credentials: ILoginParams): Promise<ISession> => {
        return toSession((await Api.request(
            routes.login,
            undefined,
            credentials,
        ))?.data);
    },
    proSanteConnectLogin: async (pscToken: string): Promise<ISession> => {
        return toSession((await Api.request(
            routes.proSanteConnectLogin,
            undefined,
            {
                pscToken,
            },
        ))?.data);
    },
    register: async (credentials: IRegisterParams): Promise<IUser> => {
        return toUser((await Api.request(
            routes.register,
            undefined,
            credentials,
        ))?.data);
    },
    requestPasswordReset: async (credentials: IRequestResetParams): Promise<void> => {
        return (await Api.request(
            routes.requestPasswordReset,
            undefined,
            credentials,
        ))?.data;
    },
    resetPassword: async (credentials: IResetParams, token?: string): Promise<void> => {
        return (await Api.request(
            routes.resetPassword,
            undefined,
            credentials,
            undefined,
            undefined,
            token,
        ))?.data;
    },
    searchRpps: async (filters: ISearchParams, session: ISession): Promise<IRpps[]> => {
        return ((await Api.request(
            routes.searchRpps,
            {
                param: filters.search,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data || []).map(toUserFromRpps);
    },
    getRpps: async (filters: IRppsGetParams, session: ISession): Promise<IUser> => {
        return toUserFromRpps((await Api.request(
            routes.getRpps,
            {
                rpps: filters.rpps,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    completeAccount: async (values: IAccountParams, session: ISession): Promise<void> => {
        return (await Api.request(
            routes.updateProfile,
            undefined,
            values,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getAccount: async (session: ISession): Promise<IUser> => {
        return toUser((await Api.request(
            routes.getProfile,
            undefined,
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    updateProfileStatus: async (userId: IUser['id'], status: IUser['status'], session: ISession): Promise<IUser> => {
        return (await Api.request(
            routes.updateProfileStatus,
            { userId },
            { status },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    updateAccountSecurity: async (payload: IAccountSecurityParams, session: ISession): Promise<void> => {
        const { password, doubleAuthentication } = payload;
        return (await Api.request(
            routes.updateAccountSecurity,
            undefined,
            {
                password,
                doubleAuthentications: doubleAuthentication ? 'EMAIL' : 'NO',
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    requestEmailCode: async (payload: IRequestResetParams, session: ISession): Promise<void> => {
        return (await Api.request(
            routes.requestEmailCode,
            undefined,
            payload,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    verifyCode: async (code: IValidationCodeParams, session: ISession): Promise<void> => {
        return (await Api.request(
            routes.verifyToken,
            {
                token: code.code,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    validateCode: async (code: IValidationCodeParams, session: ISession): Promise<void> => {
        return (await Api.request(
            routes.validateToken,
            undefined,
            {
                token: code.code,
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    verifyAccessToken: async (session: ISession): Promise<void> => {
        return (await Api.request(
            routes.verifyAccessToken,
            undefined,
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    refreshAccessToken: async (session: ISession): Promise<ISession> => {
        return (await Api.request(
            routes.refreshAccessToken,
            undefined,
            undefined,
            undefined,
            undefined,
            session.refreshToken,
        ))?.data;
    },
    sendToken: async (session: ISession): Promise<void> => {
        return (await Api.request(
            routes.sendToken,
            undefined,
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    createGroup: async (group: IGroupParams, session: ISession): Promise<IGroup> => {
        const { name, email, visible, address, city, postalCode } = group;
        const payload: any = {
            name,
            email,
            visible: visible ?? false,
        };

        if (address || city || postalCode) {
            payload.address = {
                address,
                city,
                postalCode,
            };
        }

        return toGroup((await Api.request(
            routes.createGroup,
            undefined,
            payload,
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    updateGroup: async (group: IGroupParams, session: ISession): Promise<void> => {
        const { name, email, visible, address, city, postalCode, id } = group;
        return (await Api.request(
            routes.updateGroup,
            {
                groupId: id,
            },
            {
                name,
                email,
                visible: visible ?? false,
                address: {
                    address,
                    city,
                    postalCode,
                },
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    removeUserFromGroup: async (userId: IUser['id'], groupId: IGroup['id'], session: ISession): Promise<void> => {
        return (await Api.request(
            routes.removeUserFromGroup,
            {
                groupId,
                userId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    leaveGroup: async (groupId: IGroup['id'], session: ISession): Promise<void> => {
        return (await Api.request(
            routes.leaveGroup,
            {
                groupId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    requestGroupJoining: async (groupId: IGroup['id'], session: ISession): Promise<void> => {
        return (await Api.request(
            routes.requestGroupJoining,
            undefined,
            {
                groupId,
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    invitePersonInGroup: async (personId: IPerson['id'], groupId: IGroup['id'], session: ISession): Promise<void> => {
        return (await Api.request(
            routes.invitePersonInGroup,
            {
                groupId,
            },
            {
                personId,
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getGroupRequests: async (groupId: IGroup['id'], session: ISession): Promise<IGroupRequest[]> => {
        return (await Api.request(
            routes.getGroupRequests,
            {
                groupId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getGroups: async (payload: ISearchParams, session: ISession): Promise<IGroup[]> => {
        return ((await Api.request(
            routes.getGroups,
            payload,
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data || []).map(toGroup);
    },
    getPersons: async (payload: ISearchParams, session: ISession): Promise<IPerson[]> => {
        return (await Api.request(
            routes.getPersons,
            payload,
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getPatients: async (payload: ISearchPatientParams, session: ISession): Promise<IPaginatedResults<IPatient>> => {
        return (await Api.request(
            routes.getPatients,
            Object.fromEntries(Object.entries({
                ...payload.params,
                param: payload.param,
            }).filter(([k, value]) => value !== undefined)
                .map(([k, v]) => (v instanceof Date) ? [k, moment(v).format('YYYY-MM-DD')] : [k, v])
            ),
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getGroup: async (groupId: IGroup['id'], session: ISession): Promise<IGroup> => {
        return toGroup((await Api.request(
            routes.getGroup,
            {
                groupId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    getUser: async (userId: IUser['id'], session: ISession): Promise<IUser> => {
        return (await Api.request(
            routes.getUser,
            {
                userId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    deleteGroupRequest: async (groupRequest: IGroupRequestParams, session: ISession): Promise<void> => {
        const { groupId, id } = groupRequest;
        return (await Api.request(
            routes.deleteGroupRequest,
            {
                groupId,
                requestId: id,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    declineGroupInvitation: async (groupInvitation: IGroupInvitationParams, session: ISession): Promise<void> => {
        return (await Api.request(
            routes.updateGroupInvitation,
            {
                inviteId: groupInvitation.id,
            },
            {
                status: 'REJECTED',
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    acceptGroupInvitation: async (groupInvitation: IGroupInvitationParams, session: ISession): Promise<void> => {
        return (await Api.request(
            routes.updateGroupInvitation,
            {
                inviteId: groupInvitation.id,
            },
            {
                status: 'ACCEPTED',
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getGroupInvitations: async (session: ISession): Promise<IGroupInvitation[]> => {
        return ((await Api.request(
            routes.getGroupInvitations,
            {
                receiverId: session.user?.id,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data || []).map(toGroupInvitation);
    },
    getUsers: async (payload: ISearchParams, session: ISession): Promise<IPaginatedResults<IUser>> => {
        const data = (await Api.request(
            routes.getUsers,
            {
                search: payload.search || '',
                offset: payload.offset || 0,
                limit: payload.limit,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;

        if (data) {
            const { results, count } = data;
            return {
                results: (results ?? []).map(toGroup),
                count,
            };
        }

        return {
            results: [],
        };
    },
    getContacts: async (payload: ISearchParams, session: ISession): Promise<IPaginatedResults<IContact>> => {
        const data = (await Api.request(
            routes.getContacts,
            {
                search: payload.search || '',
                offset: payload.offset || 0,
                limit: payload.limit,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;

        if (data) {
            const { results, count } = data;
            return {
                results: (results ?? []).map(toGroup),
                count,
            };
        }

        return {
            results: [],
        };
    },
    getInvoices: async (payload: IQuoteSearchParams, session: ISession): Promise<IPaginatedResults<IInvoice>> => {
        const data = (await Api.request(
            routes.getInvoices,
            {
                ...payload,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;

        if (data) {
            const { results, count } = data;
            return {
                results: (results ?? []).map(toGroup),
                count,
            };
        }

        return {
            results: [],
        };
    },
    getInvoicesCsv: async (payload: IQuoteSearchParams, session: ISession): Promise<Buffer> => {
        const response = await Api.request(
            routes.getInvoicesCsv,
            {
                ...payload,
            },
            undefined,
            {
                responseType: 'arraybuffer',
            },
            undefined,
            session.accessToken,
        );
        return Buffer.from(response?.data);
    },
    printInvoice: async (expertiseId: number, session: ISession): Promise<Buffer> => {
        const response = await Api.request(
            routes.printInvoice,
            {
                expertiseId,
            },
            undefined,
            {
                responseType: 'arraybuffer',
            },
            undefined,
            session.accessToken,
        );
        return Buffer.from(response?.data);
    },
    getUserInvoices: async (payload: ISearchParams, session: ISession): Promise<IPaginatedResults<IUserInvoice>> => {
        const data = (await Api.request(
            routes.getUserInvoices,
            {
                search: payload.search || '',
                offset: payload.offset || 0,
                limit: payload.limit,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;

        if (data) {
            const { results, count } = data;
            return {
                results: (results ?? []).map(toGroup),
                count,
            };
        }

        return {
            results: [],
        };
    },
    deleteContact: async (contactId: number, session: ISession): Promise<void> => {
        await Api.request(
            routes.deleteContact,
            {
                contactId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        );
    },
    getDocuments: async (role: DocumentRole, offset: number, limit: number, session: ISession): Promise<IGroupDocument[]> => {
        return ((await Api.request(
            routes.getDocuments,
            {
                role,
                offset,
                limit,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data || []).map(toGroupDocument);
    },
    getDocument: async (documentId: IDocument['id'], session: ISession): Promise<[Blob, AxiosResponseHeaders | undefined]> => {
        const response = await Api.request(
            routes.getDocument,
            {
                documentId,
            },
            undefined,
            {
                responseType: 'blob',
            },
            undefined,
            session.accessToken,
        );
        return [response?.data, response?.headers];
    },
    getDocumentMetadata: async (documentId: IDocument['id'], session: ISession): Promise<IDocument> => {
        return (await Api.request(
            routes.getDocumentMetadata,
            {
                documentId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    uploadDocument: async (document: File, session: ISession, onUploadProgress?: (progressEvent: ProgressEvent) => void): Promise<IDocument> => {
        return toDocument((await Api.request(
            routes.uploadDocument,
            undefined,
            undefined,
            undefined,
            document,
            session.accessToken,
            onUploadProgress,
        ))?.data);
    },
    shareDocumentWithGroup: async (documentId: IDocument['id'], groupId: IGroup['id'], session: ISession): Promise<IGroupDocument> => {
        return toGroupDocument((await Api.request(
            routes.shareDocumentWithGroup,
            {
                documentId,
                groupId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    shareDocumentWithEmail: async (documentId: IDocument['id'], email: string, session: ISession): Promise<IGroupDocument> => {
        return toGroupDocument((await Api.request(
            routes.shareDocumentWithEmail,
            {
                documentId,
            },
            {
                email,
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    updateDocument: async (document: IDocument, session: ISession): Promise<void> => {
        return (await Api.request(
            routes.updateDocument,
            {
                documentId: document.id,
            },
            {
                name: document.name,
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    deleteDocument: async (documentId: IDocument['id'], groupId: IGroup['id'], session: ISession): Promise<void> => {
        return (await Api.request(
            routes.deleteDocument,
            {
                documentId,
                groupId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getExpertises: async (role: ExpertiseRole, statuses: IExpertise['status'][], session: ISession, offset: number = 0): Promise<IExpertise[]> => {
        const params = {
            role,
            offset,
            statuses,
        };
        return ((await Api.request(
            routes.getExpertises,
            params,
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data || []).map(toExpertise);
    },
    createExpertiseFromPerson: async (payload: IExpertiseParams, session: ISession): Promise<IExpertise> => {
        const { patient, subject, recipient } = payload;
        return toExpertise((await Api.request(
            routes.createExpertiseFromPerson,
            undefined,
            {
                patientId: patient?.id,
                subject,
                recipientId: recipient?.id,
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    createExpertiseFromEmail: async (payload: IExpertiseParams, session: ISession): Promise<IExpertise> => {
        const { patient, subject, recipient } = payload;
        return toExpertise((await Api.request(
            routes.createExpertiseFromEmail,
            undefined,
            {
                patientId: patient?.id,
                subject,
                email: recipient?.email,
                to: payload?.to,
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    getExpertise: async (expertiseId: IExpertise['id'], session: ISession): Promise<IExpertise> => {
        return toExpertise((await Api.request(
            routes.getExpertise,
            {
                expertiseId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    updateExpertise: async (expertiseId: IExpertise['id'], session: ISession, patientId?: IPatient['id'] | null): Promise<IExpertise> => {
        return toExpertise((await Api.request(
            routes.updateExpertise,
            {
                expertiseId,
            },
            {
                patientId,
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    getMessagesFromExpertise: async (expertiseId: IExpertise['id'], session: ISession, fromMessageId?: IExpertiseMessage['id']): Promise<IExpertiseMessage[]> => {
        const param = fromMessageId ? { expertiseId, fromMessageId } : { expertiseId };
        return ((await Api.request(
            routes.getMessageFromExpertise,
            param,
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data || []).map(toExpertiseMessage);
    },
    sendMessageToExpertise: async (expertiseId: IExpertise['id'], payload: IExpertiseMessageParams, session: ISession): Promise<IExpertiseMessage> => {
        return toExpertiseMessage((await Api.request(
            routes.sendMessageToExpertise,
            { expertiseId },
            payload,
            undefined,
            undefined,
            session.accessToken,
        ))?.data);
    },
    setMessageAsRead: async (expertiseId: IExpertise['id'], messageId: IExpertiseMessage['id'], session: ISession): Promise<ISeenMessage> => {
        return (await Api.request(
            routes.setMessageAsRead,
            {
                expertiseId,
                messageId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    createPatient: async (patient: IPatientParams, session: ISession): Promise<IPatient> => {
        return (await Api.request(
            routes.createPatient,
            undefined,
            {
                ...patient,
                fromVitale: !!patient.fromVitale,
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    createPatients: async (patients: IPatientParams[], session: ISession): Promise<void> => {
        return (await Api.request(
            routes.createPatients,
            undefined,
            patients,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    updatePatient: async (patientId: IPatient['id'], patient: IPatientParams, session: ISession): Promise<IPatient> => {
        return (await Api.request(
            routes.updatePatient,
            {
                patientId,
            },
            patient,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    linkDocumentToExpertise: async (documentId: IDocument['id'], expertiseId: IExpertise['id'], session: ISession): Promise<void> => {
        return (await Api.request(
            routes.linkDocumentToExpertise,
            {
                documentId,
                expertiseId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    finalizeExpertise: async (expertiseId: IExpertise['id'], replierId: IPerson['id'], session: ISession): Promise<void> => {
        return (await Api.request(
            routes.updateExpertise,
            {
                expertiseId,
            },
            {
                replierId,
                status: 'REPLIED',
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    archiveExpertise: async (expertiseId: IExpertise['id'], replierId: IPerson['id'], session: ISession): Promise<void> => {
        return (await Api.request(
            routes.archiveExpertise,
            {
                expertiseId,
            },
            {
                replierId,
                status: 'ARCHIVED',
            },
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    exportPdf: async (expertiseId: IExpertise['id'], session: ISession): Promise<Buffer> => {
        const response = await Api.request(
            routes.exportPdf,
            {
                expertiseId,
            },
            undefined,
            {
                responseType: 'arraybuffer',
            },
            undefined,
            session.accessToken,
        );
        return Buffer.from(response?.data);
    },
    getDocumentFromExpertise: async (expertiseId: IExpertise['id'], documentId: IDocument['id'], session: ISession): Promise<[Blob, AxiosResponseHeaders | undefined]> => {
        const response = await Api.request(
            routes.getDocumentFromExpertise,
            {
                expertiseId,
                documentId,
            },
            undefined,
            {
                responseType: 'blob',
            },
            undefined,
            session.accessToken,
        );
        return [response?.data, response?.headers];
    },
    deleteDocumentFromExpertise: async (expertiseId: IExpertise['id'], documentId: IDocument['id'], session: ISession): Promise<void> => {
        return (await Api.request(
            routes.deleteDocumentFromExpertise,
            {
                expertiseId,
                documentId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getDocumentFromExpertiseMetadata: async (expertiseId: IExpertise['id'], documentId: IDocument['id'], session: ISession): Promise<IDocument> => {
        return (await Api.request(
            routes.getDocumentFromExpertiseMetadata,
            {
                expertiseId,
                documentId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getConstants: async (): Promise<IConstants> => {
        return (await Api.request(
            routes.getConstants,
        ))?.data;
    },
    getNotifications: async (userId: IUser['id'], session: ISession): Promise<INotifications> => {
        return (await Api.request(
            routes.getNotifications,
            {
                userId,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getNotification: async (notificationId: string,
        token: string,
        force: boolean,
        session: ISession): Promise<INotification> => {
        return (await Api.request(
            routes.getNotification,
            {
                notificationId,
                token,
                forced: force,
            },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    setupPayment: async (session: ISession): Promise<ISetupPayment> => {
        return (await Api.request(
            routes.setupPayment,
            {},
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getPaymentMethods: async (session: ISession): Promise<IPaymentMethod[]> => {
        return (await Api.request(
            routes.getPaymentMethods,
            {},
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    getBillingAddress: async (session: ISession): Promise<IBillingAddress> => {
        return (await Api.request(
            routes.getBillingAddress,
            {},
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    updateBillingAddress: async (address: IBillingAddress, session: ISession): Promise<void> => {
        return (await Api.request(
            routes.updateBillingAddress,
            {},
            address,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    refreshUserInvoice: async (invoiceId: number, session: ISession): Promise<void> => {
        return (await Api.request(
            routes.refreshUserInvoice,
            { invoiceId },
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
    generateUserInvoices: async (session: ISession): Promise<void> => {
        return (await Api.request(
            routes.generateUserInvoices,
            {},
            undefined,
            undefined,
            undefined,
            session.accessToken,
        ))?.data;
    },
};

const toSession = (props: any): ISession => {
    return {
        ...props,
        user: toUser(props.user),
    } as ISession;
};

const toUser = (user: any): IUser => {
    return {
        ...user,
        rpps: user.rpps ?? undefined,
        lastName: user.lastName ?? undefined,
        firstName: user.firstName ?? undefined,
        createdAt: user.createdAt ? new Date(user.createdAt) : undefined,
        updatedAt: user.updatedAt ? new Date(user.updatedAt) : undefined,
        knowledgeLabel: user.knowledgeLabel ?? user.knowledge_label ?? user.subSpeciality ?? undefined,
        professionLabel: user.professionLabel ?? user.profession_label ?? user.speciality ?? undefined,
        address: user.address ? toPostalAddress(user.address) : undefined,
        addresses: (user.addresses ?? []).map(toPostalAddress),
        expertPathology: user.expertPathology ?? undefined,
        phoneNumber: user.phoneNumber ?? undefined,
    } as IUser;
};

const toPostalAddress = (address: any): IPostalAddress => {
    return {
        ...address,
        city: address.addressCityLabel || address.address_city_label || address.city || '',
        postalCode: address.addressPostalCode || address.address_postal_code || address.postal_code || address.postalCode || '',
        address: address.address || `${address.addressNumber || address.address_number || ''} ${address.addressRoadTypeLabel || address.address_road_type_label || ''} ${address.addressRoad || address.address_road || ''}`,
    } as IPostalAddress;
};

const toUserFromRpps = (user: any): IUser => {
    const civility = CivilityOptions[user.civility as Civility] || DefaultCivility;
    return {
        ...user,
        civility,
        rpps: user.rpps,
        lastName: user.lastName || user.last_name || undefined,
        firstName: user.firstName || user.first_name || undefined,
        knowledgeLabel: user.knowledgeLabel || user.knowledge_label || undefined,
        professionLabel: user.professionLabel || user.profession_label || undefined,
        addresses: (user.addresses || []).map(toPostalAddress),
    } as IUser;
};

const toDocument = (document: any): IDocument => {
    const { deletedFromExpertiseAt } = document;
    return {
        ...document,
        createdAt: new Date(document.createdAt),
        updatedAt: new Date(document.updatedAt),
        deletedFromExpertiseAt: deletedFromExpertiseAt ? new Date(deletedFromExpertiseAt) : undefined,
    } as IDocument;
};

const toGroupDocument = (groupDocument: any): IGroupDocument => {
    return {
        ...groupDocument,
        createdAt: new Date(groupDocument.createdAt),
        updatedAt: new Date(groupDocument.updatedAt),
        expiresAt: new Date(groupDocument.expiresAt),
        document: toDocument(groupDocument.document),
    } as IGroupDocument;
};

const toGroupInvitation = (groupInvitation: any): IGroupInvitation => {
    return {
        ...groupInvitation,
        createdAt: groupInvitation.createdAt ? new Date(groupInvitation.createdAt) : undefined,
        updatedAt: groupInvitation.updatedAt ? new Date(groupInvitation.updatedAt) : undefined,
    } as IGroupInvitation;
};

const toExpertise = (expertise: any): IExpertise => {
    const { patient, documents } = expertise;
    const expertiseIn: IExpertise = {
        ...expertise,
        createdAt: expertise.createdAt ? new Date(expertise.createdAt) : undefined,
        updatedAt: expertise.updatedAt ? new Date(expertise.updatedAt) : undefined,
        documents: (documents || []).map(toDocument),
    };

    if (patient) {
        return {
            ...expertiseIn,
            patient: {
                ...patient,
                birthDate: patient?.birthDate ? new Date(patient.birthDate) : undefined,
            },
        };
    }

    return expertiseIn;
};

const toExpertiseMessage = (expertiseMessage: any): IExpertiseMessage => {
    const { document } = expertiseMessage;
    return {
        ...expertiseMessage,
        createdAt: expertiseMessage.createdAt ? new Date(expertiseMessage.createdAt) : undefined,
        updatedAt: expertiseMessage.updatedAt ? new Date(expertiseMessage.updatedAt) : undefined,
        document: document ? toDocument(document) : undefined,
    } as IExpertiseMessage;
};

const toGroup = (group: any): IGroup => {
    return {
        ...group,
        address: {
            ...group.address,
            postalCode: group.address?.postal_code,
        },
    } as IGroup;
};

export default RealApi;
