import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Card, Divider } from 'antd';
import { saveAs } from 'file-saver';
import { ReactElement, memo, useState } from 'react';
import { useSessionActions } from '../../redux/actions/session.action';
import { renderMessage, useMessage } from '../../services/helpers';
import { BillingStatusFilter, Filters } from './filters.component';
import './index.less';
import TableComponent from './table';

const BillingComponent = (): ReactElement => {
    const { getInvoicesCsv } = useSessionActions();
    const [message, setMessage] = useMessage();
    const [filterState, setFilterState] = useState<BillingStatusFilter>('TODO');

    const onDownload = async () => {
        try {
            const query: IQuoteSearchParams = {};
            if (filterState !== 'ALL') query.statuses = [filterState];
            saveAs(new Blob([await getInvoicesCsv(query).catch((err: Error) => {
                throw err;
            })], {
                type: 'text/csv',
            }), `${new Date().toLocaleDateString('fr-FR')}-expertises.csv`);
        }
        catch (err) {
            setMessage(err);
        }
    };

    return <Card className="billing" bordered={false} title="Cotations">
        {renderMessage(message)}
        <div>
            <Filters
                setFilterState={setFilterState}
                filterState={filterState}
            /><br />
            <Button
                icon={<FileExcelOutlined />}
                onClick={() => onDownload()}
            >
                Exporter
            </Button>

            <Divider />
            <TableComponent
                setMessage={setMessage}
                filterState={filterState}
            />
        </div>
    </Card>;
};

export default memo(BillingComponent);
