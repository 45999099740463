import { memo, ReactElement, useState } from 'react';
import { useSessionActions } from 'redux/actions/session.action';
import PersonsSearchComponent from './component';
import { useSession } from '../../../redux/selectors/session.selector';

interface IProps {
    placeholder?: string;
    onChange?: (patient?: IPatient) => void;
    clearPatient?: () => void;
    value?: IPatient;
}

const PatientSearch = (props: IProps): ReactElement | null => {
    const [isSearching, setIsSearching] = useState(false);
    const { getPatients } = useSessionActions();
    const session = useSession();

    const onSearchPersons = async (search: string): Promise<IPatient[] | undefined> => {
        if (!search || search.trimEnd().length < 3) {
            setIsSearching(false);

            return;
        }

        try {
            setIsSearching(true);

            return (await getPatients({
                param: search,
            }).then(({results: patients}) => {
                setIsSearching(false);

                return patients;
            }).catch((err: Error) => {
                throw err;
            }));
        }
        catch (err: any) {
            setIsSearching(false);

            console.log(err);
        }
    };

    const onSelectPerson = async (patient?: IPatient) => {
        try {
            if (!patient) {
                return props.onChange?.(undefined);
            }
            else {
                props.onChange?.(patient);
            }
        }
        catch (err: any) {
            console.log(err);
        }
    };

    const clearPatient = () => {
        props.clearPatient?.();
    };

    return session.user ? <PersonsSearchComponent
        getPatients={onSearchPersons}
        selectPatient={onSelectPerson}
        onClearPatient={clearPatient}
        isSearching={isSearching}
        placeholder={props.placeholder}
        value={props.value}
    /> : null;
};

export default memo(PatientSearch);
