import { BulbOutlined, MailOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, Table } from 'antd';
import { memo, ReactElement, useEffect, useState } from 'react';
import { useGroupActions } from 'redux/actions/group.action';
import { renderMessage, useMessage } from 'services/helpers';
import { CloseType } from './component';

interface IProps {
    validate: (person: IPerson) => void;
    onHide: () => void;
    show: boolean;
    group?: IPerson;
    type: CloseType;
}

interface IPersonKeyed extends IPerson {
    key: React.Key;
}

const ExpertiseCloseModal = (props: IProps): ReactElement | null => {
    const [person, setPerson] = useState<IPerson>();
    const [persons, setPersons] = useState<IPersonKeyed[]>();
    const [message, setMessage] = useMessage();

    const { getGroup } = useGroupActions();

    const onValidate = async () => {
        try {
            if (person) {
                props.validate(person);
            }

            hide();
        }
        catch (err: any) {
            console.log(err);
        }
    };

    const onSelectPerson = (personIn: IPerson) => {
        setPerson(personIn);
    };

    const hide = () => {
        props.onHide();
    };

    useEffect(() => {
        (async () => {
            if (props.show) {
                setPersons((await getGroup(props.group?.id)).members?.map((personIn: IPerson, index: number) => {
                    return {
                        ...personIn,
                        key: String(index),
                    };
                }));
            }
        })().catch((err: Error) => {
            setMessage(err);
        });
    }, [props.show]);

    return <Modal
        className="expertise-close-modal"
        title={props.type === CloseType.Finalize ? 'Cloture de l\'expertise' : 'Suppression de l\'expertise'}
        centered
        visible={props.show}
        onOk={onValidate}
        onCancel={hide}
        width="60rem"
        footer={[
            <Button key="back" onClick={hide}>
                Annuler
            </Button>,
            <Button key="submit" danger={props.type === CloseType.Archive} type="primary" onClick={onValidate} disabled={person === undefined}>
                {props.type === CloseType.Finalize ? 'Conclure l\'expertise' : 'Supprimer la conversation'}
            </Button>,
        ]}>

        {renderMessage(message)}
        <Alert type="info" message="Veuillez indiquer la personne ayant répondu à votre demande." />
        <Table
            pagination={false}
            showHeader={false}
            rowSelection={{
                type: 'radio',
                onChange: (_: React.Key[], personsIn: IPersonKeyed[]) => {
                    if (personsIn.length > 0) {
                        onSelectPerson(personsIn[0]);
                    }
                },
            }}
            columns={[
                {
                    render: (personIn: IPerson) => {
                        const { firstName, lastName, civility, email, speciality } = personIn;
                        return <div>
                            <div className="list-item-main-info" key={'p' + personIn.id}>{`${civility || ''} ${firstName || ''} ${lastName || ''}`}</div>
                            <div className="list-item-secondary-info">
                                <div>
                                    <div><BulbOutlined /></div>
                                    <div>{speciality}</div>
                                </div>
                                <div>
                                    <div><MailOutlined /></div>
                                    <div>{email}</div>
                                </div>
                            </div>
                        </div>;
                    },
                },

            ]}
            dataSource={persons || []}
        />
    </Modal>;
};

export default memo(ExpertiseCloseModal);
