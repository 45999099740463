import { memo, ReactElement, useState } from 'react';
import { useSessionActions } from 'redux/actions/session.action';
import RppsSearchComponent from './component';

export type Rpps = IRppsGetParams['rpps'];

interface IProps {
    selectUser: (user?: IUser) => void;
}

const RppsSearch = (props: IProps): ReactElement | null => {
    const [isSearching, setIsSearching] = useState(false);
    const { searchRpps, getRpps } = useSessionActions();

    const onSearchRpps = async (search: string): Promise<IRpps[]> => {
        if (!search || search.trimEnd().length < 3) {
            setIsSearching(false);
            return [];
        }
        setIsSearching(true);
        return await searchRpps({
            search,
        })
            .then((rpps: IRpps[]) => {
                setIsSearching(false);
                return rpps;
            })
            .catch((err: Error) => {
                setIsSearching(false);
                console.log(err);
                return [];
            });
    };

    const onGetRpps = async (rpps: Rpps) => {
        try {
            props.selectUser(await getRpps({
                rpps,
            }).catch((err: Error) => {
                throw err;
            }));
        }
        catch (err: any) {
            console.log(err);
        }
    };

    return <RppsSearchComponent
        searchRpps={onSearchRpps}
        getRpps={onGetRpps}
        isSearching={isSearching}
    />;
};

export default memo(RppsSearch);
