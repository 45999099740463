import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input } from 'antd';
import pscImage from 'assets/images/psc.png';
import pages from 'pages';
import { memo, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { renderMessage } from 'services/helpers';
import './index.less';

interface IProps {
    message?: IMessage;
    pscEnabled?: boolean;
    login: (loginParams: ILoginParams) => void;
}

const LoginComponent = (props: IProps): ReactElement => {
    const [form] = Form.useForm<ILoginParams>();

    const onLogin = (credentials: ILoginParams) => {
        props.login(credentials);
    };

    return <Form
        form={form}
        onFinish={onLogin}
        className="login"
    >
        {renderMessage(props.message)}
        <Form.Item
            name="email"
            rules={[{ required: true, message: 'Vous devez saisir une adresse mail' }]}
        >
            <Input placeholder="Email" prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
            name="password"
            rules={[{ required: true, message: 'Vous devez saisir un mot de passe' }]}
        >
            <Input.Password placeholder="Mot de passe" prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item>
            <Button className="login-action" type="primary" htmlType="submit">Connexion</Button>
        </Form.Item>

        <Form.Item className="forgotten-password">
            <Link to={pages.reset.path}>Mot de passe oublié ?</Link>
        </Form.Item>

        {props.pscEnabled ? <>
            <Divider className="connection-separator" plain>ou</Divider>

            <Form.Item>
                <a href="/api/pro-sante-connect/login">
                    <img className="psc-action" src={pscImage} />
                </a>
            </Form.Item>
        </> : undefined}
    </Form>;
};

export default memo(LoginComponent);
