import { FileAddOutlined, SendOutlined, UploadOutlined, UserAddOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Avatar, Button, Card, Input, List, Modal, Skeleton, Tag, Tooltip, Upload } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { ChangeEvent, KeyboardEvent, memo, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducers';
import { isUploadedDocument, renderBirthDate, renderFileIcon, renderMessage } from 'services/helpers';
import { renderDocumentPreviewModal } from '../document/upload-modal';
import { renderDocumentVisuModal } from '../document/visu-modal';
import ExpertiseList from '../list';
import DocumentsModal from '../request/documents-modal';
import ExpertiseCloseModal from './expertise-close-modal';
import './index.less';
import PatientModal from '../../patient-modal/patient-modal';
import PatientCreationModal from '../../patient-modal/patient-creation-modal';

export enum CloseType {
    Archive,
    Finalize,
}

interface IProps {
    expertise?: IExpertise;
    previousExpertise?: IExpertise;
    expertisesInProgress: IExpertise[];
    expertisesEnded: IExpertise[];
    documents?: IDocument[];
    user?: IUser;
    message?: IMessage;
    messages: IExpertiseMessage[];
    isExpertisesSent: boolean;
    refreshExpertise: (withFullMessagesRefresh?: boolean) => void;
    loadingMessages: boolean;
    loadingExpertises: boolean;
    changeExpertise: (expertise: IExpertise) => void;
    sendMessage: (message: IExpertiseMessageParams) => void;
    finalizeExpertise: (person: IPerson) => void;
    archiveExpertise: (person: IPerson) => void;
    saveDocument: (document: IDocument) => void;
    exportPdf: () => void;
    shareDocument: (document: IDocument[]) => void;
    addPatient: (patient: IPatientParams) => void;
    assignPatient: (patient: IPatient | null) => void;
    removeErrorMessage: () => void;
}

const { confirm } = Modal;
type Avatar = Record<number, string>;

const Colours = [
    225, 348, 124, 32, 298, 188, 5, 149, 85, 252,
];

const Component = (props: IProps): ReactElement => {
    const user = useSelector((state: AppState) => state.session.user);
    const [patientModal, setPatientModal] = useState<ReactElement | null>(null);
    const { patient, requester, status, recipient } = props.expertise ?? {};

    const getAvatarColors = (avatarColorsIn?: Avatar) => {
        const avatarColors = getAvatarColorForUser(avatarColorsIn);

        props.messages.forEach((expertiseMessage: IExpertiseMessage) => {
            const { id: senderId } = expertiseMessage.sender;
            if (senderId !== undefined && !(senderId in avatarColors)) {
                avatarColors[senderId] = generateAvatarColor();
            }
        });

        return avatarColors;
    };

    const generateAvatarColor = () => {
        const avatarColorLength = avatarColor ? Object.keys(avatarColor).length : 0;
        return 'hsl(' + (avatarColorLength in Colours ? Colours[avatarColorLength] : Colours[0]) + ', 40%, 50%)';
    };

    const getAvatarColorForUser = (avatarColorsIn?: Avatar) => {
        const avatarColors = avatarColorsIn || {};
        const id = user?.id;
        if (id && !(id in avatarColors)) {
            avatarColors[id] = generateAvatarColor();
        }
        return avatarColors;
    };

    const [message, setMessage] = useState<string>();
    const [closeType, setCloseType] = useState<CloseType>(CloseType.Finalize);
    const [isCloseExpertiseModalShown, setCloseExpertiseModalShown] = useState<boolean>(false);
    const [avatarColor, setAvatarColor] = useState<Avatar>();
    const [inputRows, setInputRows] = useState<number>(1);
    const messageInput = useRef<TextAreaRef | null>(null);
    const [documentsModalVisible, setDocumentsModalVisible] = useState<boolean>(false);

    useEffect(() => {
        const messagesList = document.querySelector('.chat .messages');
        if (messagesList) {
            if (messagesList.scrollTop === 0) messagesList.scroll(0, messagesList.scrollHeight);
        }
        const avatarColors = getAvatarColors(avatarColor);

        if (Object.values(avatarColors).length > 0) {
            // Spreading allows to re-render
            setAvatarColor({ ...avatarColors });
        }
    }, [props.messages]);

    const sendMessage = (messageIn?: string) => {
        if (!messageIn || messageIn === '') {
            return;
        }

        props.sendMessage({
            content: messageIn,
        });

        setMessage(undefined);
        setInputRows(1);

        messageInput.current?.focus();
    };

    const onFinalizeExpertise = () => {
        const { recipient: recipientIn } = props.expertise || {};
        if (recipientIn?.type === 'UserEntity') {
            onConfirmFinalizeExpertise(recipientIn);
        }
        else {
            setCloseType(CloseType.Finalize);
            showCloseExpertiseModal();
        }
    };

    const onArchiveExpertise = () => {
        const { recipient: recipientIn } = props.expertise || {};
        if (recipientIn?.type === 'UserEntity') {
            onConfirmArchiveExpertise(recipientIn);
        }
        else {
            setCloseType(CloseType.Archive);
            showCloseExpertiseModal();
        }
    };

    const onConfirmFinalizeExpertise = (person: IPerson) => {
        confirm({
            title: 'Voulez vous vraiment conclure cette expertise ?',
            icon: <WarningOutlined />,
            onOk: () => {
                props.finalizeExpertise(person);
            },
            cancelText: 'Annuler',
            centered: true,
        });
    };

    const onConfirmArchiveExpertise = (person: IPerson) => {
        confirm({
            title: 'Voulez vous vraiment supprimer cette conversation ?',
            icon: <WarningOutlined />,
            onOk: () => {
                props.archiveExpertise(person);
            },
            cancelText: 'Annuler',
            centered: true,
        });
    };

    const onExportPdf = () => {
        props.exportPdf();
    };

    const onMessageKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.ctrlKey && ['\n', 'Enter'].includes(e.key)) {
            sendMessage(e.currentTarget.value);
        }
    };

    const onMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.currentTarget.value;
        setMessage(value);
        setInputRows(value.split('').filter((c) => c === '\n').length + 1);
    };

    const onUploadDocument = async (uploadRequestOptions: UploadRequestOption) => {
        renderDocumentPreviewModal({
            uploadRequestOptions,
            saveDocument: props.saveDocument,
        });
    };

    const viewDocument = (document: IDocument, expertise?: IExpertise) => {
        if (!isUploadedDocument(document)) return;
        const isOwner = isDocumentOwner(document);
        renderDocumentVisuModal({
            document,
            expertise,
            deleteDocument: () => props.refreshExpertise(true),
            isOwner,
        });
    };

    const isExpertiseReadOnly = (statusIn: IExpertise['status']) => ['REPLIED', 'ARCHIVED', 'PRINTED'].includes(statusIn);
    const isExpertiseRunning = (statusIn: IExpertise['status']) => ['DRAFT', 'SENT'].includes(statusIn);

    const isDocumentOwner = (documentIn?: IDocument) => {
        if (!documentIn)
            return false;
        const doc = props.messages.find((message) => message.document?.id === documentIn.id);
        return doc?.sender.id === user?.id;
    };

    const renderActions = (requesterId: IPerson['id'], statusIn: IExpertise['status']): ReactNode => {
        let actions: ReactNode[] = [];

        if (isExpertiseRunning(statusIn)) {
            actions = actions.concat([
                <Button
                    key="close"
                    type="primary"
                    title='Marquer la téléexpertise comme répondue'
                    onClick={onFinalizeExpertise}
                >
                    Conclure l&apos;expertise
                </Button>,
                <Button
                    key="archive"
                    type="primary"
                    title='Clôturer cette téléexpertise sans valider la réponse'
                    danger
                    onClick={onArchiveExpertise}
                >
                    Supprimer la conversation
                </Button>,
            ]);
        }

        if (isExpertiseReadOnly(statusIn)) {
            actions = actions.concat([
                <Button
                    key="export"
                    type="primary"
                    onClick={onExportPdf}
                >
                    Exporter au format PDF
                </Button>,
            ]);
        }

        return actions;
    };

    const renderStatusMessage = (statusIn?: IExpertise['status']): ReactElement | undefined => {
        switch (statusIn) {
            case 'REPLIED':
                return <Alert type="warning" message="Cette conversation est close" />;
            case 'ARCHIVED':
                return <Alert type="warning" message="Cette conversation est archivée" />;
            default:
                return undefined;
        }
    };

    const renderExpertiseInfo = (expertiseIn: IExpertise): ReactNode => {
        const { recipient: recipientIn, requester: requesterIn } = expertiseIn;

        return <div>
            {props.isExpertisesSent ? getPersonName(recipientIn) : getPersonName(requesterIn)}
            <div className="expertise-date">{expertiseIn.createdAt.toLocaleDateString('fr-FR')} </div>
        </div>;
    };

    const getInitials = (person?: IPerson): string => {
        if (!person) {
            return '';
        }

        const { firstName, lastName } = person;
        return `${getInitial(firstName)}${getInitial(lastName)}`;
    };

    const getInitial = (name?: string): string => {
        return name && name.length > 0 ? name[0].toUpperCase() : '';
    };

    const getPersonName = (person?: IPerson): string => {
        if (!person) {
            return '';
        }

        if (person.type === 'UserEntity') {
            return person.firstName && person.lastName ? `${person.civility} ${person.firstName} ${person.lastName}` : (person.email ?? '');
        }

        return person.name ?? person.email ?? '';
    };

    const renderChatDescription = (expertiseIn?: IExpertise, documents?: IDocument[]): ReactNode => {
        if (!expertiseIn || props.loadingMessages) {
            return undefined;
        }

        const patientModalButton = <UserAddOutlined onClick={showPatientCreationModal}/>;

        const { patient: patientIn } = expertiseIn;

        return <>
            {renderExpertiseInfo(expertiseIn)}
            {patientIn ?
                renderPatient(patientIn, expertiseIn.status) :
                <div className="secondary-info">Patient non défini {patientModalButton}</div>
            }
            {renderDocuments(documents)}
        </>;
    };

    const isPatientReadOnly = patient?.fromVitale
        || isExpertiseReadOnly(status ?? 'DRAFT')
    ;

    const showPatientModal = () => {
        props.removeErrorMessage();
        setPatientModal(<PatientModal
            onHide={hidePatientModal}
            show={true}
            validate={onAddPatient}
            values={patient}
            readonly={isPatientReadOnly}
            isAuthor={props.isExpertisesSent}
        />);
    };

    const showPatientCreationModal = () => {
        props.removeErrorMessage();
        setPatientModal(<PatientCreationModal
            onHide={hidePatientModal}
            validate={onAddPatient}
            onSelectPatient={onSelectPatient}
        />);
    };

    const hidePatientModal = () => {
        setPatientModal(null);
    };

    const onAddPatient = (infoPatient: IPatientParams) => {
        props.addPatient(infoPatient);
    };

    const onSelectPatient = (patient: IPatient) => {
        props.assignPatient(patient);
        hidePatientModal();
    };

    const renderDocuments = (documents?: IDocument[]): ReactNode => {
        return documents && documents.length > 0 ? <div className="secondary-info">
            <span className="documents-title">Documents : </span>
            <div className="document-list">
                {documents?.map(renderDocument)}
            </div>
        </div> : undefined;
    };

    const renderDocument = (document: IDocument, index: number): ReactElement => {
        const content = <>
            {renderFileIcon(document.mimetype)}
            {document.name}
        </>;
        return document.deletedFromExpertiseAt ?
            <Tooltip key={'doc' + index} title={`Supprimé le ${document.deletedFromExpertiseAt.toLocaleDateString('fr-FR')}`}>
                <Tag color="lightgray" className="document deleted">{content}</Tag>
            </Tooltip> :
            <Tag key={'doc' + index} className="document" onClick={() => viewDocument(document, props.expertise)}>{content}</Tag>;
    };

    const renderDocumentInChat = (document: IDocument, expertise: IExpertise): ReactElement => {
        const content = <>
            <div>{renderFileIcon(document.mimetype)}</div>
            <div className="list-item-main-info">{document.name}</div>
        </>;

        return document.deletedFromExpertiseAt ?
            <Tooltip title={`Supprimé le ${document.deletedFromExpertiseAt.toLocaleDateString('fr-FR')}`}>
                <div className="document-content deleted">
                    {content}
                </div>
            </Tooltip> : <div className="document-content" onClick={() => viewDocument(document, expertise)}>
                {content}
            </div>;
    };

    const renderPatient = (patientIn: IPatient, statusIn: IExpertise['status']): ReactNode => {
        let tagProps: any = {
        };

        if(props.isExpertisesSent && !isExpertiseReadOnly(statusIn)) {
            tagProps = {
                ...tagProps,
                closable: true,
                onClose: () => {
                    props.assignPatient(null);
                },
            };
        }

        return <div className="secondary-info">Patient : <Tag color="blue" className="selected-patient" onClick={showPatientModal} {...tagProps}>
            <span>
                {[patientIn.lastName, patientIn.firstName].filter(v => !!v).join(' ')}, {renderBirthDate(patientIn.birthDate)}
            </span>
        </Tag></div>;
    };

    const hideDocumentsModal = () => {
        setDocumentsModalVisible(false);
    };

    const showDocumentsModal = () => {
        setDocumentsModalVisible(true);
    };

    const hideCloseExpertiseModal = () => {
        setCloseExpertiseModalShown(false);
    };

    const showCloseExpertiseModal = () => {
        setCloseExpertiseModalShown(true);
    };

    const onShareDocument = (document: IDocument[]) => {
        props.shareDocument(document);
    };


    return <div className="expertises">
        <ExpertiseList
            changeExpertise={props.changeExpertise}
            expertisesInProgress={props.expertisesInProgress}
            expertisesEnded={props.expertisesEnded}
            isExpertisesSent={props.isExpertisesSent}
            loadingExpertises={props.loadingExpertises}
            expertise={props.expertise}
            previousExpertise={props.previousExpertise}
        ></ExpertiseList>
        <Card bordered={false} title={renderChatDescription(props.expertise, props.documents)} className="chat">
            {renderMessage(props.message)}
            <div className="chat-content">
                {!props.loadingMessages && renderStatusMessage(status)}
                <div className="messages-container">
                    <List
                        className="messages"
                        itemLayout="vertical"
                        dataSource={props.messages}
                        loading={props.loadingMessages}
                        renderItem={(item: IExpertiseMessage, index: number) => {
                            const { id: senderId } = item.sender;

                            if (props.loadingMessages) {
                                return <Skeleton active={true} avatar />;
                            }
                            return <List.Item key={'m' + index}>
                                <List.Item.Meta
                                    avatar={<Avatar
                                        style={{ background: senderId !== undefined && avatarColor && senderId in avatarColor ? avatarColor[senderId] : 'black' }}>
                                        {getInitials(item.sender)}
                                    </Avatar>}
                                    title={<div className="list-item-main-info">
                                        <div>{`${getPersonName(item.sender)}`}</div>
                                    </div>}
                                    description={<div className="list-item-secondary-info">
                                        {`${item.createdAt.toLocaleString()}`}
                                    </div>}
                                />
                                {item.document && props.expertise ?
                                    renderDocumentInChat(item.document, props.expertise) :
                                    <pre className="message-content">{item.content}</pre>}
                            </List.Item>;
                        }}
                        size="small"
                        locale={{ emptyText: 'Aucun message' }}
                    />
                    {status && !isExpertiseReadOnly(status) ?
                        <div className="message-input">
                            <div className="input">
                                <Input.TextArea
                                    placeholder="Entrez votre message"
                                    value={message}
                                    onChange={onMessageChange}
                                    onKeyPress={onMessageKeyPress}
                                    rows={inputRows}
                                    ref={messageInput}
                                    autoSize={{
                                        minRows: 1,
                                        maxRows: 8,
                                    }}
                                />
                                <div className="hint">Ctrl + Entrée pour envoyer votre message</div>
                            </div>

                            <div className="actions">
                                <Button
                                    disabled={!message || message === ''}
                                    type="primary"
                                    icon={<SendOutlined />}
                                    onClick={() => sendMessage(message)} />
                                <Upload className='upload' showUploadList={false} customRequest={onUploadDocument}>
                                    <Tooltip title="Envoyer un document depuis votre ordinateur">
                                        <Button
                                            icon={<UploadOutlined />} />
                                    </Tooltip>
                                </Upload>
                                <Tooltip title="Envoyer un document depuis la plateforme">
                                    <Button
                                        icon={<FileAddOutlined />}
                                        onClick={showDocumentsModal}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        : undefined
                    }
                </div>
            </div>
            <div className="actions">
                {!props.loadingMessages && status && renderActions(requester?.id, status)}
            </div>
            <DocumentsModal
                onHide={hideDocumentsModal}
                show={documentsModalVisible}
                validate={onShareDocument}
            />
            <ExpertiseCloseModal
                onHide={hideCloseExpertiseModal}
                show={isCloseExpertiseModalShown}
                validate={closeType === CloseType.Archive ? onConfirmArchiveExpertise : onConfirmFinalizeExpertise}
                type={closeType}
                group={recipient}
            />
            {patientModal}
        </Card>
    </div>;
};

export const ExpertiseChatComponent = memo(Component);
