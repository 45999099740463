const fr: Record<string, string> = {
    'Connection refused': 'Connexion refusée',
    'Unauthorized': 'Non autorisé',
    'Invalid code': 'Code invalide',
    'Another request has been made in the last minute': 'Une autre requête a été effectuée dans les dernières minutes',
    'No authentication method available': 'Aucune méthode d\'authentification disponible',
    'Not Found': 'Non trouvé',
    'User can not access document': 'L\'utilisateur ne peut pas accéder à ce document',
    'Document not found': 'Document non trouvé',
    'Group not found': 'Groupe non trouvé',
    'You have no access to this document': 'Vous n\'avez pas accès à ce document',
    'Group already have access to document': 'Le groupe a déjà accès à ce document',
    'User not in group': 'L\'utilisateur n\'est pas dans le groupe',
    'Expertise document not found': 'Document d\'expertise non trouvé',
    'The user is not activated': 'L\'utilisateur n\'est pas activé',
    'Expertise not found': 'Expertise non trouvée',
    'You do not have access to this expertise': 'Vous n\'avez pas accès à cette expertise',
    'Recipient not found': 'Destinataire non trouvé',
    'Patient not found': 'Patient non trouvé',
    'Expertise already closed': 'Expertise déjà fermée',
    'You are not the requester': 'Vous n\'êtes pas le demandeur',
    'Expertise has no patient. A patient is required to close an expertise': 'L\'expertise n\'a pas de patient. Un patient est requis pour facturer une expertise',
    'Patient info is not complete. Please complete it before closing an expertise.': 'Les informations du patient ne sont pas complétées. Veuillez les compléter avant de facturer l\'expertise.',
    'Replier not found': 'Répondant non trouvé',
    'Replier is not a member of the group': 'Le répondant n\'est pas membre du groupe',
    'Message not found': 'Message non trouvé',
    'Message already seen': 'Message déjà vu',
    'You are not the document owner': 'Vous n\'êtes pas le propriétaire du document',
    'You are not allowed to access this document': 'Vous n\'êtes pas autorisé à accéder à ce document',
    'Expertise data not found': 'Données d\'expertise non trouvées',
    'Sender is not a user': 'L\'expéditeur n\'est pas un utilisateur',
    'You are not part of this group': 'Vous ne faites pas partie de ce groupe',
    'Person not found': 'Personne non trouvée',
    'Invite not found': 'Invitation non trouvée',
    'You are not the recipient of this invite': 'Vous n\'êtes pas le destinataire de cette invitation',
    'Invite does not exist': 'L\'invitation n\'existe pas',
    'You are not the sender of this invite': 'Vous n\'êtes pas l\'expéditeur de cette invitation',
    'Invite already responded': 'L\'invitation a déjà été répondue',
    'Requester not found': 'Demandeur non trouvé',
    'Requester is not a user': 'Le demandeur n\'est pas un utilisateur',
    'You are already part of this group': 'Vous faites déjà partie de ce groupe',
    'Request not found': 'Demande non trouvée',
    'You are not part of this group.': 'Vous ne faites pas partie de ce groupe.',
    'City is required': 'Ville requise',
    'Group name is required': 'Nom de groupe requis',
    'Member not found': 'Membre non trouvé',
    'Patient is not linked to an expertise': 'Le patient n\'est pas lié à une expertise',
    'You are not the requester of the expertise linked to the patient': 'Vous n\'êtes pas le demandeur de l\'expertise liée au patient',
    'Patient didn\'t update': 'Le patient n\'a pas été mis à jour',
    'Search must be at least 3 characters long': 'La recherche doit contenir au moins 3 caractères',
    'No one found with this search': 'Aucun résultat pour cette recherche',
    'User not verified yet': 'Utilisateur non vérifié',
    'User not found': 'Utilisateur non trouvé',
    'This email has not been verified yet.': 'Cet email n\'a pas encore été vérifié.',
    'User already exists': 'L\'utilisateur existe déjà',
    'Email already exists': 'Cet email est déjà utilisé',
    'RPPS already exists': 'Ce RPPS est déjà attribué à un utilisateur',
    'status must be a string': 'Le statut doit être une chaîne de caractères',
    'Forbidden resource': 'Accès refusé',
    'Recipient did not replied': 'Le requis n\'a pas répondu',
    'No request found with this token': 'Code inconnu',
};

export default fr;
