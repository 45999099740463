import { ClockCircleOutlined, FileFilled, ShareAltOutlined } from '@ant-design/icons';
import { Button, Checkbox, Empty, List, Modal, Skeleton, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import clone from 'clone';
import { memo, ReactElement, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDocumentActions } from 'redux/actions/document.action';

export const Limit = 20;

interface IProps {
    validate: (documents: IDocument[]) => void;
    onHide: () => void;
    show: boolean;
}

const DocumentsModal = (props: IProps): ReactElement | null => {
    const [data, setData] = useState<IGroupDocumentKeyed>();
    const [loading, setLoading] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0);
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const { getOwnDocuments } = useDocumentActions();

    const onValidate = async () => {
        props.validate(documents);
        hide();
    };

    const hide = () => {
        props.onHide();
    };

    const onSelectGroupDocument = (event: CheckboxChangeEvent, groupDocument: IGroupDocument) => {
        if (event.target.checked) {
            setDocuments([
                ...documents,
                ...[groupDocument.document],
            ]);
        }
        else {
            setDocuments(documents.filter((documentIn: IDocument) => documentIn.id !== groupDocument.document.id));
        }
    };

    const getGroupDocumentsKeyed = (documentsIn: IGroupDocument[], initialData?: IGroupDocumentKeyed): IGroupDocumentKeyed => {
        const documentsGroupsKeyed = clone(initialData || {});
        for (const groupDocument of documentsIn) {
            const { id } = groupDocument.group;
            if (id) {
                if (!(id in documentsGroupsKeyed)) {
                    documentsGroupsKeyed[id] = [];
                }

                documentsGroupsKeyed[id].push(groupDocument);
            }
        }
        return documentsGroupsKeyed;
    };

    const loadMoreFiles = async () => {
        if (loading) {
            return;
        }

        try {
            setLoading(true);
            const documentsIn = await getNextDocuments(offset).catch((err: Error) => {
                throw err;
            });
            const dataIn = offset === 0 ? {} : data;
            setData(documentsIn.length > 0 ? getGroupDocumentsKeyed(documentsIn, dataIn) : undefined);
            setLoading(false);
        }
        catch (err: any) {
            console.log(err);
        }
    };

    const getNextDocuments = async (offsetIn?: number): Promise<IGroupDocument[]> => {
        const newOffset = offsetIn !== undefined ? offsetIn : offset + Limit;
        setOffset(newOffset);
        return getOwnDocuments(newOffset, Limit).catch((err: Error) => {
            throw err;
        });
    };

    useEffect(() => {
        (async () => {
            if (props.show) {
                await loadMoreFiles();
            }
        })().catch((err: Error) => {
            console.log(err);
        });
    }, [props.show]);

    const renderDocuments = (): ReactElement => {
        const dataIn = Object.values(data || {});

        const documentsCount = dataIn.reduce((total: number, groupDocuments: IGroupDocument[]) => total + groupDocuments.length, 0);

        return documentsCount > 0 ?
            <div className="files" id="files">
                <InfiniteScroll
                    dataLength={documentsCount}
                    next={loadMoreFiles}
                    hasMore={documentsCount % Limit === 0}
                    loader={<Skeleton paragraph={{ rows: 0 }} active />}
                    scrollableTarget="files"
                >
                    {dataIn.map((groupDocuments: IGroupDocument[], index: number) => {
                        return groupDocuments.length > 0 ? <List
                            key={'fg' + index}
                            className="files-group"
                            itemLayout="horizontal"
                            dataSource={groupDocuments}
                            header={groupDocuments[0].group.name}
                            size="small"
                            renderItem={(groupDocumentIn: IGroupDocument, indexIn: number) => {
                                const { sharedBy, document } = groupDocumentIn;
                                const { name } = document;

                                const shareElement = sharedBy ? <div>
                                    <div><ShareAltOutlined /></div>
                                    <div>{sharedBy.civility} {sharedBy.firstName} {sharedBy.lastName}</div>
                                </div> : undefined;

                                return <List.Item>
                                    <Tooltip title={name}>
                                        <List.Item.Meta
                                            avatar={<>
                                                <Checkbox className="files-checkbox" key={'d' + indexIn} onChange={(e: CheckboxChangeEvent) => onSelectGroupDocument(e, groupDocumentIn)} />
                                                <FileFilled className="filetype" />
                                            </>}
                                            title={<div className="list-item-main-info">
                                                <div>{name}</div>
                                            </div>}
                                            description={<div className="list-item-secondary-info">
                                                {shareElement}
                                                <div>
                                                    <div><ClockCircleOutlined /></div>
                                                    <div>{groupDocumentIn.expiresAt?.toLocaleDateString('fr-FR')}</div>
                                                </div>
                                            </div>}
                                        />
                                    </Tooltip>
                                </List.Item>;
                            }}
                            locale={{
                                emptyText: 'Aucun document',
                            }}
                        /> : undefined;
                    })}
                </InfiniteScroll>
            </div> :
            <Empty description="Aucun document" />;
    };

    return <Modal
        className="documents-modal"
        title="Sélectionnez vos documents"
        centered
        visible={props.show}
        onOk={onValidate}
        onCancel={hide}
        width="60rem"
        footer={[
            <Button key="back" onClick={hide}>
                Annuler
            </Button>,
            <Button key="submit" type="primary" onClick={onValidate}>
                Valider
            </Button>,
        ]}>

        {renderDocuments()}

    </Modal>;
};


export default memo(DocumentsModal);
