import React, { useCallback, useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import { bufferCount, from, mergeMap } from 'rxjs';
import { FilesReader } from 'services/file-reader';
import { ICardioPremierPAR, ICardioPremierXML } from '../types';
import moment from 'moment/moment';
import convert from 'xml-js';
import { usePatientActions } from 'redux/actions/patient.action';
import { useWindowExitProtection } from 'services/helpers';

interface IProps {
    hide: () => void;
    onFinish?: (countCreatedPatients: number) => void;
}

function parseINIString(data: string) {
    const regex = {
        section: /^\s*\[\s*([^\]]*)\s*\]\s*$/,
        param: /^\s*([^=]+?)\s*=\s*(.*?)\s*$/,
        comment: /^\s*;.*$/,
    };
    const value: Record<string, any> = {};
    const lines = data.split(/[\r\n]+/);
    let section: string | null = null;
    lines.forEach(function (line: string) {
        if (regex.comment.test(line)) {
            return;
        } else if (regex.param.test(line)) {
            const match = line.match(regex.param) as RegExpMatchArray;

            if (section) {
                value[section][match[1]] = match[2];
            } else {
                value[match[1]] = match[2];
            }
        } else if (regex.section.test(line)) {
            const match = line.match(regex.section) as RegExpMatchArray;
            value[match[1]] = {};
            section = match[1];
        } else if (line.length == 0 && section) {
            section = null;
        }
    });
    return value;
}


const extractCardioPremier = async function* (onDirectoryHandle: () => void): AsyncGenerator<IPatientParams> {
    const reader = new FilesReader('cardiopremier');
    const decoder = new TextDecoder('iso-8859-1');
    const directoryHandle = await reader.getDirectoryHandle();
    onDirectoryHandle();
    for await(const file of reader.listFiles(directoryHandle)) {
        try {
            if (file.name === 'CV.par') {
                const f = await file.getFile();
                const obj = parseINIString(decoder.decode(await f.arrayBuffer())) as ICardioPremierPAR;
                yield {
                    firstName: obj.Bénéficiaire.Prénom,
                    lastName: obj.Bénéficiaire.Nom,
                    birthName: obj.Bénéficiaire.Nom_naissance,
                    birthDate: moment(obj.Bénéficiaire.Date_de_naissance, 'DD/MM/YYYY').toDate(),
                    birthRank: parseInt(obj.Bénéficiaire.Rang, 10),
                    sex: obj.Bénéficiaire.Numéro.startsWith('1') ? 'M' : 'F',
                    INS: `${obj.Bénéficiaire.Numéro}${obj.Bénéficiaire.Clé}`,
                    insuranceCode: `${obj.Médico_administratif.Code_régime}${obj.Médico_administratif.Caisse_gestionnaire}${obj.Médico_administratif.Centre_gestionnaire}`,
                    fromVitale: true,
                } as IPatientParams;
            } else if (file.name === 'CarteVitale.xml') {
                const f = await file.getFile();
                const data = convert.xml2js(await f.text(), {
                    ignoreComment: true,
                    alwaysChildren: true,
                    compact: true,
                }) as ICardioPremierXML;

                const ident = data.T_AsnDonneesVitale.listeBenef.T_AsnBeneficiaire.ident;
                const amo = data.T_AsnDonneesVitale.listeBenef.T_AsnBeneficiaire.amo;

                const INS = ident.nir._text.replace(' ', '');
                const insuranceCode = `${amo.codeRegime._text}${amo.caisse._text}${amo.centreCarte._text}`;
                yield {
                    firstName: ident.prenomUsuel._text,
                    lastName: ident.nomUsuel._text,
                    birthName: ident.nomPatronymique?._text,
                    birthDate: moment(ident.naissance.date._text, 'DDMMYYYY').toDate(),
                    birthRank: parseInt(ident.rangDeNaissance._text),
                    sex: INS.startsWith('1') ? 'M' : 'F',
                    INS,
                    insuranceCode,
                    fromVitale: true,
                } as IPatientParams;
            }
        } catch (e) {
            console.error(e);
        }
    }
};

export const CardioPremierModal = (props: IProps): React.ReactElement => {
    const { createPatients } = usePatientActions();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState<boolean>(false);
    const {
        mount: mountWindowExitProtection,
        unmount: unmountWindowExitProtection,
    } = useWindowExitProtection({
        title: 'Veuillez attendre la fin de l\'importation avant de quitter la page',
    });

    useEffect(() => {
        if (loading) {
            mountWindowExitProtection();
        } else {
            unmountWindowExitProtection();
        }

        return () => {
            unmountWindowExitProtection();
        };
    }, [loading]);

    const cardioPremierImport = useCallback(async (onFinish: (count: number) => void) => {
        try {
            let counter = 0;

            const files = extractCardioPremier(() => {
                setLoading(true);
                api.info({
                    message: 'Importation CardioPremier en cours',
                    description: 'Merci de rester sur cette page jusque la fin de l\'importation',
                    duration: 5,
                });
            });

            const source$ = from(files);

            source$.pipe(
                bufferCount(50),
                mergeMap(patients => {
                    counter += patients.length;

                    return from(createPatients(patients));
                }, 1),
            ).subscribe().add(() => {
                setLoading(false);
                onFinish(counter);
            });
        } catch (e) {
            if (e instanceof TypeError) {
                console.error(e);
                api.error({
                    message: 'Une erreur est survenue',
                    description: 'Veuillez utiliser un autre navigateur, tel que Google Chrome pour cette fonctionnalité',
                    duration: 20,
                });
            }
        }
    }, []);

    return <>
        {contextHolder}
        <Modal
            closable={!loading}
            title="Import cardioPremier"
            open
            onCancel={props.hide}
            onOk={async () => {
                await cardioPremierImport((progress) => {
                    props.onFinish && props.onFinish(progress);
                    props.hide();
                });
            }}
            cancelText="Annuler"
            cancelButtonProps={{ disabled: loading }}
            okText={loading ? 'Importation en cours' : 'Importer'}
            okButtonProps={{ loading: loading }}
        >
            <p>
                Pour importer vos patients depuis cardiopremier allez dans le dossier « CardioPremier » puis
                sélectionnez le
                dossier « BaseDoc » enfin acceptez l’affichage des fichiers. (<a
                    href="https://www.youtube.com/watch?v=x3XNMynbbUg" target="_blank">Vidéo explicative</a>)

            </p>


            <p>
                <strong>Attention l’import ne fonctionne que sur Chrome
                    ou Edge !</strong>
            </p>
        </Modal>
    </>;
};
