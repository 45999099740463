import { TablePaginationConfig } from 'antd';
import { memo, ReactElement, useEffect, useState } from 'react';
import { useSessionActions } from 'redux/actions/session.action';
import { useMessage } from 'services/helpers';
import { useContactActions } from '../../redux/actions/contact.action';
import ContactsComponent from './component';

const Limit = 10;

const Contacts = (): ReactElement | null => {
    const { getContacts } = useSessionActions();
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [pagination, setPagination] = useState<IPagination>();
    const [message, setMessage] = useMessage();

    const { deleteContactAction } = useContactActions();

    const searchContacts = (page?: number, pageSize?: number, searchIn?: string) => {
        const limit = pageSize ?? Limit;
        getContacts({ limit, offset: ((page ?? 1) - 1) * limit, search: searchIn })
            .then((data: IPaginatedResults<IContact>) => {
                const { results, count } = data;
                setContacts(results);
                setPagination({
                    current: page,
                    pageSize: pageSize ?? Limit,
                    defaultPageSize: Limit,
                    total: count ?? 0,
                    pageSizeOptions: [Limit, Limit * 2, Limit * 5, Limit * 10],
                });
            })
            .catch((err: Error) => {
                setMessage(err);
            });
    };

    const search = async (searchIn?: string) => {
        searchContacts(undefined, undefined, searchIn);
    };

    const changePagination = (paginationIn: TablePaginationConfig) => {
        const { current, pageSize } = paginationIn;
        searchContacts(current, pageSize);
    };

    const deleteContact = async (contact: IContact) => {
        await deleteContactAction(contact.id)
            .then(async () => {
                const newContacts = [...contacts];
                newContacts.splice(newContacts.findIndex((c) => c.id = contact.id), 1);
                setContacts(newContacts);
            })
            .catch((err: Error) => {
                setMessage(err);
            });
    };

    useEffect(() => {
        searchContacts(1);
    }, []);

    return <ContactsComponent
        contacts={contacts}
        deleteContact={deleteContact}
        changePagination={changePagination}
        search={search}
        message={message}
        pagination={pagination}
    />;
};

export default memo(Contacts);
