import { Tabs } from 'antd';
import React, { memo, ReactElement, useEffect, useState } from 'react';
import HomeComponent from 'components/home/component';
import Login from './login';
import Register from './register';
import { Tab } from '.';

const { TabPane } = Tabs;

interface IProps {
    navigate: (tab: string) => void;
    tab?: Tab;
}

const AuthenticationComponent = (props: IProps): ReactElement => {
    const [activeTab, setActiveTab] = useState<string>(String(props.tab));

    useEffect(() => {
        setActiveTab(String(props.tab));
    }, [props.tab]);

    const changeTab = (tab: string) => {
        props.navigate(tab);
    };

    return <HomeComponent>
        <Tabs activeKey={String(activeTab)} onTabClick={changeTab} centered>
            <TabPane tab="Se connecter" key={Tab.Login}>
                <Login />
            </TabPane>
            <TabPane tab="S'inscrire" key={Tab.Register}>
                <Register />
            </TabPane>
        </Tabs>
    </HomeComponent>;
};

export default memo(AuthenticationComponent);
