import {
    FileExcelOutlined,
    FileImageOutlined,
    FileMarkdownOutlined,
    FileOutlined,
    FilePdfOutlined,
    FilePptOutlined,
    FileTextOutlined,
    FileWordOutlined,
    FileZipOutlined,
} from '@ant-design/icons';
import { Alert } from 'antd';
import ApiError from 'exceptions/api.exception';
import ApiSessionError from 'exceptions/api.session.exception';
import pages from 'pages';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import React, {
    ComponentType,
    FunctionComponent,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { useDocumentActions } from 'redux/actions/document.action';
import { AppState } from 'redux/reducers';
import store from 'redux/store';
import { IRoute } from 'routes';
import I18N from './i18n';
import moment from 'moment';

export function useMessage(): [IMessage | undefined, (value: any, type?: IMessage['type']) => void] {
    const [messageState, setMessageState] = useState<IMessage>();
    const setMessage = (value: any, type: IMessage['type'] = 'error') => {
        switch (true) {
            case value instanceof ApiError:
                setMessageState({
                    type,
                    message: value.messages,
                });
                break;
            case value instanceof Error:
                setMessageState({
                    type,
                    message: value.message,
                });
                break;
            case value === undefined:
                setMessageState(undefined);
                break;
            default:
                setMessageState({
                    type,
                    message: String(value),
                });
                break;
        }
    };

    return [messageState, setMessage];
}

export function renderMessage(messageIn?: IMessage): ReactNode {
    if (!messageIn) {
        return undefined;
    }

    return <Alert type={messageIn.type} message={getMessage(messageIn)} />;
}

export function getMessage(messageIn: IMessage): string | JSX.Element[] {
    const { message } = messageIn;
    if (Array.isArray(message)) {
        return message.map((value: string, index: number) => toJsx(I18N.translate(value), index));
    }

    return I18N.translate(message);
}

function toJsx(text: string, index: number): JSX.Element {
    return <p key={'m' + index}>{text}</p>;
}

export function renderBirthDate(birthDate?: Date | null): string | undefined {
    return birthDate ? `${moment(birthDate).format('DD/MM/YYYY')} (${moment().diff(birthDate, 'years')} ans)` : undefined;
}

export function useApiErrorHandling(refreshSession: () => Promise<void>): (err: Error) => Promise<never> {
    const navigate = useNavigate();

    return async (err: Error) => {
        if (!(err instanceof ApiSessionError)) {
            throw err;
        }

        await refreshSession().catch((errIn: Error) => {
            navigate(pages.login.path);
            throw errIn;
        });

        document.location.reload();

        // Is only useful for typescript not to complain about promise return because we don't care as we reload the page
        throw err;
    };
}

interface ITabNavigation {
    onNavigate: (tab: string) => void;
    tab?: number;
}

export function useTabNavigation(
    routeTabMap: Record<IRoute['path'], number>,
    tabRouteMap: Record<number, IRoute['path']>,
    defaultRoute: IPage,
): ITabNavigation {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [routePath, setRoutePath] = useState<string | undefined>(pathname);

    const sortedPathesByLength = Object.keys(routeTabMap).sort((a: string, b: string) => b.length - a.length);

    useEffect(() => {
        const route = sortedPathesByLength.find((path: string) => {
            return pathname.match(new RegExp(path));
        });
        setRoutePath(route);
    }, [pathname]);

    const onNavigate = (tab: string) => {
        const index = Number(tab);
        navigate(index in tabRouteMap ? tabRouteMap[index] : defaultRoute.path);
    };

    return {
        onNavigate,
        tab: routePath && routePath in routeTabMap ? routeTabMap[routePath] : undefined,
    };
}

interface IUseDocumentUploadParams {
    uploadRequestOptions: UploadRequestOption;
    onBeforeUpload?: () => void;
    onAfterUpload?: (document: IDocument) => void;
    onUploadProgress?: (loaded: number, total: number) => void;
}

export function useDocumentUpload(): (params: IUseDocumentUploadParams) => Promise<void> {
    const { uploadDocument } = useDocumentActions();

    return async (params: IUseDocumentUploadParams) => {
        const { uploadRequestOptions, onBeforeUpload, onAfterUpload, onUploadProgress } = params;
        if (onBeforeUpload) {
            onBeforeUpload();
        }

        const document = await uploadDocument(uploadRequestOptions.file as File, async (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            if (onUploadProgress) {
                onUploadProgress(loaded, total);
            }
        }).catch((err: Error) => {
            throw err;
        });

        if (onAfterUpload) {
            onAfterUpload(document);
        }
    };
}

interface IUseDocumentPreviewParams {
    uploadRequestOptions: UploadRequestOption;
    onBeforeUpload?: () => void;
    onAfterUpload?: (file: File) => void;
}

export function useDocumentPreview(): (params: IUseDocumentPreviewParams) => Promise<void> {
    return async (params: IUseDocumentPreviewParams) => {
        const { uploadRequestOptions, onBeforeUpload, onAfterUpload } = params;
        if (onBeforeUpload) {
            onBeforeUpload();
        }

        if (onAfterUpload) {
            onAfterUpload(uploadRequestOptions.file as File);
        }
    };
}

export function useRequestParams<T>(): T {
    const { search } = useLocation();
    const requestParams: Record<string, unknown> = {};
    search.replace(/^\?/, '').split('&').forEach((param: string) => {
        const [name, value = true] = param.split('=');
        requestParams[name] = value;
    });
    return requestParams as T;
}

export function renderStandaloneElement<T>(component: FunctionComponent<T>, props: T): void {
    const { name } = component;
    let container = document.querySelector(`[data-id='${name}']`);
    if (!container) {
        container = document.createElement('div');
        container.setAttribute('data-id', name);
    }
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                {React.createElement(component as any, props as any)}
            </BrowserRouter>
        </Provider>,
        container,
    );
}

export function renderFileIcon(mimetype: string): ReactElement {
    switch (mimetype) {
        case 'image/gif':
        case 'image/jpeg':
        case 'image/png':
        case 'image/svg+xml':
            return <FileImageOutlined />;
        case 'application/pdf':
            return <FilePdfOutlined />;
        case 'application/zip':
            return <FileZipOutlined />;
        case 'text/plain':
        case 'text/html':
            return <FileTextOutlined />;
        case 'text/markdown':
            return <FileMarkdownOutlined />;
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return <FileWordOutlined />;
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return <FileExcelOutlined />;
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return <FilePptOutlined />;
        default:
            return <FileOutlined />;
    }
}

export const withExpertisesAccess = <P extends object>(Component: ComponentType<P>): FunctionComponent<P> => ({
    ...props
}: P | null) => {
    const navigate = useNavigate();

    const isUserActive = useSelector((state: AppState) => {
        return state.session.user?.status === 'ACTIVE';
    });
    const adeli = useSelector((state: AppState) => {
        return state.session.user?.addresses.find((address) => address.selected)?.adeli;
    });
    const hasAdeli = !!adeli;

    useEffect(() => {
        if (!isUserActive || !hasAdeli) {
            navigate(pages.expertisesPlaceholder.path, { state: { isUserActive, hasAdeli } });
        }
    }, []);

    return isUserActive ? <Component {...props} /> : null;
};

export type Civility = NonNullable<IPerson['civility']>;

export const DefaultCivility: Civility = 'Dr';

export const CivilityOptions: Record<Civility, string> = {
    Dr: 'Dr',
    M: 'M',
    Mme: 'Mme',
    Pr: 'Pr',
};

export enum Sex {
    U = 'Non renseigné ou non défini',
    F = 'Femme',
    M = 'Homme',
    O = 'Autre',
}

export function isUploadedDocument(document: IDocument): document is IUploadedDocument {
    return document.id !== undefined;
}

declare const window: Window & {
    dataLayer: Record<string, unknown>[], Tawk_API: {
        login: (obj: any, errorHandler: (error: string) => void) => void
    }
};

export function dataLayer(data: any) {
    window.dataLayer.push(data);
}

export function tawkLogin(data: any, errorHandler: any) {
    window.Tawk_API.login && window.Tawk_API.login(data, errorHandler);
}

const windowListeners: Record<string, [EventListener, AddEventListenerOptions | undefined][]> = {};

const useWindowListeners = () => {
    const addListener = useCallback((name: string, f: EventListener, options?: AddEventListenerOptions) => {
        window.addEventListener(name, f, options);
        windowListeners[name] = [...(windowListeners[name] ?? []), [f, options]];
    }, []);


    const removeListeners = (eventName: string) => {
        windowListeners[eventName].forEach(([eventListener, options]) => {
            window.removeEventListener(eventName, eventListener, options);
        });

        windowListeners[eventName] = [];
    };

    const removeAllListeners = () => {
        Object.keys(windowListeners).forEach((name) => {
            removeListeners(name);
        });
    };

    return {
        addListener,
        removeAllListeners,
        removeListeners,
    };
};

interface IWindowExitProtectionProps {
    title: string;
}

export const useWindowExitProtection = (props: IWindowExitProtectionProps) => {
    const {
        removeAllListeners,
        addListener,
    } = useWindowListeners();
    const mount = () => {
        addListener('beforeunload', function (event: BeforeUnloadEvent) {
            event.returnValue = props.title;

            return props.title;
        }, { capture: true });
    };


    const unmount = () => {
        removeAllListeners();
    };

    return {
        mount,
        unmount,
    };

};
