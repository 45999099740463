import { useSelector } from 'react-redux';
import Api from '../../services/api';
import { useApiErrorHandling } from '../../services/helpers';
import { AppState } from '../reducers';
import { useSessionActions } from './session.action';

export function useContactActions() {
    const { refreshSession } = useSessionActions();
    const errorHandling = useApiErrorHandling(refreshSession);
    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const { session } = mapStateToObj;

    return {
        deleteContactAction: async (contactId: number): Promise<void> => {
            return Api.deleteContact(contactId, session)
                .catch(errorHandling);
        },
    };
}