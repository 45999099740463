import { Layout } from 'antd';
import { memo, ReactElement } from 'react';
import './index.less';

const { Content } = Layout;

interface IProps {
    component: any;
}

const AuthenticationLayout = (props: IProps): ReactElement => {
    const { component: MyComponent } = props;

    return <Layout className="authentication-layout">
        <Content className="content">
            <MyComponent />
        </Content>
    </Layout>;
};

export default memo(AuthenticationLayout);
