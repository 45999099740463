import { Button, Card, Space } from 'antd';
import { memo, ReactElement } from 'react';
import './index.less';
import TableComponent from './table';
import pages from '../../pages';
import { Link } from 'react-router-dom';
import { useSessionActions } from '../../redux/actions/session.action';

const InvoiceComponent = (): ReactElement => {
    const {isAdmin, generateUserInvoices} = useSessionActions();

    return <Card
        bordered={false}
        title={'Vos factures'}
        extra={
            <Space size={32}>
                <Link to={pages.paymentMethods.path}>Moyens de paiements</Link>
                {isAdmin() && <Button
                    onClick={async () => {
                        await generateUserInvoices();
                        window.location.reload();
                    }}
                    title={'Visible uniquement par les admins'}
                    size={'small'}
                    type={'primary'}
                >
                    Générer les factures
                </Button>}
            </Space>
        }
    >
        <div>
            <TableComponent/>
        </div>
    </Card>;
};

export default memo(InvoiceComponent);
