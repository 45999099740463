import { Card } from 'antd';
import logo from 'assets/images/logo.svg';
import React, { HTMLAttributes, memo, PropsWithChildren, ReactElement, useEffect } from 'react';
import './index.less';
import { useAppActions } from 'redux/actions/app.action';

const HomeComponent = (props: PropsWithChildren<unknown> & HTMLAttributes<unknown>): ReactElement => {
    const {fetchConstants, constants} = useAppActions();
    const {version, cguUrl} = constants;

    useEffect(() => {
        (async () => {
            await fetchConstants();
        })().catch((err: Error) => {
            console.log(err);
        });
    }, []);

    return <div className="home">
        <Card bordered={false} className={props.className}>
            <div className="logo">
                <img src={logo} alt={'Medz app logo'}/>
            </div>
            {props.children}
            <div className="footer">
                <div className="cgu">
                    En utilisant les services de medz, vous acceptez les <a href={cguUrl} target='_blank'>CGU</a>
                </div>
                <div className="version">{version}</div>
            </div>
        </Card>
    </div>;
};

export default memo(HomeComponent);
