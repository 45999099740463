import { Table, TablePaginationConfig } from 'antd';
import { saveAs } from 'file-saver';
import { ReactElement, memo, useEffect, useState } from 'react';
import { useSessionActions } from '../../redux/actions/session.action';
import { getColumns } from './columns';
import { BillingStatusFilter as QuoteStatusFilter } from './filters.component';
import './index.less';

const Limit = 10;

interface IProps {
    setMessage: (message: any) => void;
    filterState: QuoteStatusFilter;
}

const TableComponent = (props: IProps): ReactElement => {
    const { getInvoices, printInvoice } = useSessionActions();
    const [invoices, setInvoices] = useState<IInvoice[]>([]);
    const [pagination, setPagination] = useState<IPagination>();

    const searchInvoices = (page?: number, pageSize?: number) => {
        const limit = pageSize ?? Limit;
        const query: IQuoteSearchParams = {
            limit,
            offset: ((page ?? 1) - 1) * limit,
        };
        if (props.filterState !== 'ALL') query.statuses = [props.filterState];
        getInvoices(query)
            .then((data: IPaginatedResults<IInvoice>) => {
                const { results, count } = data;
                setInvoices(results);
                setPagination({
                    current: page,
                    pageSize: pageSize ?? Limit,
                    defaultPageSize: Limit,
                    total: count ?? 0,
                    pageSizeOptions: [Limit, Limit * 2, Limit * 5, Limit * 10],
                });
            })
            .catch((err: Error) => {
                props.setMessage(err);
            });
    };

    const onDownload = async (invoice: IInvoice) => {
        try {
            saveAs(new Blob([await printInvoice(invoice?.id).catch((err: Error) => {
                throw err;
            })], {
                type: 'application/pdf',
            }), `${new Date(invoice.repliedAt).toLocaleDateString('fr-FR')}-${invoice.patient}.pdf`);
        }
        catch (err) {
            props.setMessage(err);
        }
    };

    const changePagination = (paginationIn: TablePaginationConfig) => {
        const { current, pageSize } = paginationIn;
        searchInvoices(current, pageSize);
    };

    const onChangePagination = (pagination: TablePaginationConfig) => {
        changePagination(pagination);
    };

    useEffect(() => {
        searchInvoices(1);
    }, []);

    useEffect(() => {
        searchInvoices(1);
    }, [props.filterState]);

    return <Table
        columns={getColumns(onDownload)}
        dataSource={invoices.map((invoice) => { return { key: invoice.id, ...invoice }; })}
        pagination={pagination}
        onChange={onChangePagination}
    />;
};

export default memo(TableComponent);
