import { Button, Card, Form, Input } from 'antd';
import { memo, ReactElement, useEffect } from 'react';
import { renderMessage } from 'services/helpers';

interface IProps {
    securityMessage?: IMessage;
    save: (values: IAccountSecurityParams) => void;
    user?: IUser;
}

const SecurityComponent = (props: IProps): ReactElement | null => {
    const [form] = Form.useForm<IAccountSecurityParams>();

    const onSave = (values: IAccountSecurityParams) => {
        props.save(values);
    };

    useEffect(() => {
        form.setFieldsValue({
            doubleAuthentication: props.user?.doubleAuthentication === 'EMAIL',
        });
    }, [props.user]);

    return <Card className="security" bordered={false} title="Sécurité">
        <Form
            form={form}
            layout="vertical"
            onFinish={onSave}
        >
            {renderMessage(props.securityMessage)}
            <Form.Item name="password" label="Mot de passe">
                <Input.Password />
            </Form.Item>

            <Form.Item name="passwordConfirmation" label="Confirmation du mot de passe">
                <Input.Password />
            </Form.Item>

            <Form.Item className="actions">
                <Button type="primary" htmlType="submit">Sauver</Button>
            </Form.Item>
        </Form>
    </Card>;
};

export default memo(SecurityComponent);
