export class FilesReader {
    private directoryHandle: FileSystemDirectoryHandle | undefined;


    public constructor(private readonly id: string) {
    }


    public async getDirectoryHandle(): Promise<FileSystemDirectoryHandle> {
        this.directoryHandle ??= await window.showDirectoryPicker({
            id: this.id,
            mode: 'read',
        });

        return this.directoryHandle;
    }

    public async* listFiles(directoryHandle: FileSystemDirectoryHandle): AsyncGenerator<FileSystemFileHandle> {
        const iterator = directoryHandle.values();
        let entry = null;

        do {
            entry = await iterator.next();

            if(entry.done) {
                break;
            }

            const resource: FileSystemDirectoryHandle | FileSystemFileHandle = entry.value;

            if(resource.kind === 'directory') {
                yield* this.listFiles(resource);
            }

            if(resource.kind === 'file') {
                yield resource;
            }
        } while (!entry.done);
    }
}
