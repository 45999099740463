import React, { ReactElement, useEffect, useState } from 'react';
import { useSessionActions } from 'redux/actions/session.action';
import { useAppActions } from 'redux/actions/app.action';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Button, Space } from 'antd';

loadStripe.setLoadParameters({advancedFraudSignals: false});

const Form = (): ReactElement => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState<string>();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return null;
        }

        const {error} = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: window.location.href,
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return <form onSubmit={handleSubmit}>
        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <PaymentElement />
            <Button disabled={!stripe} type="primary" onClick={handleSubmit}>Valider</Button>
            {errorMessage && <div>{errorMessage}</div>}
        </Space>
    </form>;
};

const SetupForm = (): ReactElement => {
    const {constants} = useAppActions();
    const stripePromise = loadStripe(constants.stripePublicKey || '');
    const [clientSecret, setClientSecret] = useState<string>();
    const {setupPaymentMethod} = useSessionActions();
    const options: StripeElementsOptions = {
        clientSecret: clientSecret,
        locale: 'fr-FR',
    };

    useEffect(() => {
        if(!clientSecret) {
            setupPaymentMethod().then(setupPayment => {
                setClientSecret(setupPayment.clientSecret);
            }).catch(console.error);
        }

    }, []);


    if(!clientSecret) {
        return <></>;
    }

    return <Elements stripe={stripePromise} options={options}>
        <Form/>
    </Elements>;
};

export default SetupForm;
