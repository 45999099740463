import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/reducers';
import Api from 'services/api';
import { ISetConstants, Type } from './types';

let isConstantsLoaded = false;

// eslint-disable-next-line
export function useAppActions() {
    const dispatch = useDispatch();

    const mapStateToObj = useSelector((state: AppState) => {
        return {
            constants: state.constants,
        };
    });

    const { constants } = mapStateToObj;

    const fetchConstants = async (): Promise<IConstants> => {
        if (isConstantsLoaded) {
            return constants;
        }

        const data = await Api.getConstants().catch(err => {
            throw new Error(err);
        });

        isConstantsLoaded = true;

        dispatch<ISetConstants>({
            payload: data,
            type: Type.SET_CONSTANTS,
        });

        return data;
    };

    return {
        isConstantsLoaded,
        fetchConstants,
        constants,
    };
}
