import { ColumnsType } from 'antd/lib/table';
import { renderBirthDate } from 'services/helpers';
import { SafetyOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

export function getColumns(): ColumnsType<IPatient> {
    return [
        {
            title: '',
            dataIndex: 'fromVitale',
            key: 'fromVitale',
            render: (fromVitale: boolean) => {
                return fromVitale && <Tag color="success"><SafetyOutlined /> Validé</Tag>;
            },
            width: 25,
        },
        {
            title: 'Nom',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Prénom',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Date de naissance',
            dataIndex: 'birthDate',
            key: 'birthDate',
            render: renderBirthDate,
        },
        {
            title: 'INS',
            dataIndex: 'INS',
            key: 'INS',
        },
    ];
}
