import { ActionTypes, Type } from 'redux/actions/types';

const defaultState: IConstants = {
    isE2e: false,
    cguUrl: '',
    googleGtmId: '',
    isProSanteConnectEnabled: false,
    stripePublicKey: '',
    version: 'unknown',
};

const constants = (state: IConstants = defaultState, action: ActionTypes): IConstants => {
    switch (action.type) {
        case Type.SET_CONSTANTS:
            return action.payload;
    }

    return state;
};


export default constants;
