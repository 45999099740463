import { Button, Form, Input } from 'antd';
import React, { memo, ReactElement } from 'react';
import HomeComponent from 'components/home/component';
import { renderMessage } from 'services/helpers';
import './index.less';

interface IProps {
    message?: IMessage;
    goHome: () => void;
    validate: (credentials: IRequestResetParams) => void;
}

const ResetComponent = (props: IProps): ReactElement => {
    const [form] = Form.useForm<IRequestResetParams>();

    const onValidate = (credentials: IRequestResetParams) => {
        props.validate(credentials);
    };

    const goHome = () => {
        props.goHome();
    };

    return <HomeComponent className="reset">
        <Form form={form} onFinish={onValidate}>
            {renderMessage(props.message)}

            <h3>Mot de passe oublié ?</h3>
            <p className="description">Entrez votre adresse mail et nous vous enverrons un lien pour réinitialiser votre mot de passe</p>

            <Form.Item
                name="email"
                rules={[{ required: true, message: 'Vous devez saisir votre adresse mail' }]}
            >
                <Input placeholder="Adresse mail" />
            </Form.Item>

            <Form.Item className="actions">
                <Button onClick={goHome}>Retour</Button>
                <Button type="primary" htmlType="submit">Valider</Button>
            </Form.Item>
        </Form>
    </HomeComponent>;
};

export default memo(ResetComponent);
