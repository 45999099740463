import { EyeInvisibleOutlined } from '@ant-design/icons';
import { Alert, Button, Result } from 'antd';
import { saveAs } from 'file-saver';
import { memo, ReactElement } from 'react';
import { getMessage } from 'services/helpers';
import './index.less';

interface IProps {
    document: IDocument;
    documentMeta?: IDocumentMeta;
    message?: IMessage;
}

const DocumentVisuComponent = (props: IProps): ReactElement => {
    const renderError = (message: IMessage): ReactElement => {
        return <Alert className="big-file" type="error" message={
            <>
                {getMessage(message)}<br />
            </>
        } />;
    };

    const renderPreview = (content: ReactElement): ReactElement => {
        return <>
            <div className="document-visu">
                {props.message ?
                    renderError(props.message) :
                    content}
            </div>
        </>;
    };


    const downloadDocument = async () => {
        try {
            if (!props.documentMeta) {
                throw new Error('Document not defined');
            }

            saveAs(new Blob([props.documentMeta.content], {
                type: 'application/octet-stream',
            }), props.document.name);
        }
        catch (err: any) {
            console.error(err);
        }
    };

    const renderContent = (documentMeta?: IDocumentMeta): ReactElement => {
        if (!documentMeta) {
            return renderPreview(<EyeInvisibleOutlined className="no-content" />);
        }

        const { mimetype } = documentMeta;
        switch (mimetype) {
            case 'image/gif':
            case 'image/jpeg':
            case 'image/png':
            case 'image/svg+xml': {
                const uri = URL.createObjectURL(documentMeta.content);
                return renderPreview(<img className="preview image" src={uri} />);
            }
            case 'application/pdf':
            case 'text/plain':
            case 'text/html':
            case 'audio/wave':
            case 'audio/wav':
            case 'audio/x-wav':
            case 'audio/x-pn-wav':
            case 'audio/webm':
            case 'video/webm':
            case 'audio/ogg':
            case 'video/ogg':
            case 'application/ogg': {
                const uri = URL.createObjectURL(documentMeta.content);
                return renderPreview(<object className="preview object" data={uri} type={mimetype} />);
            }
            default:
                return renderPreview(
                    <>
                        <Result
                            status="warning"
                            title="L'aperçu n'est pas disponible pour cet élément"
                            extra={
                                <Button type="primary" key="console" onClick={() => downloadDocument()}>
                                    Télécharger
                                </Button>
                            }
                        />
                    </>
                );
        }
    };

    return renderContent(props.documentMeta);
};

export default memo(DocumentVisuComponent);
