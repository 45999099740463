import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducers';
import Api from 'services/api';
import { useApiErrorHandling } from 'services/helpers';
import { useSessionActions } from './session.action';

export function useUsersActions() {
    const { refreshSession } = useSessionActions();
    const errorHandling = useApiErrorHandling(refreshSession);
    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const { session } = mapStateToObj;
    const hasEmail = Boolean(session.user?.email);

    return {
        updateProfileStatus: async (userId: IUser['id'], status: IUser['status']): Promise<IUser> => {
            return Api.updateProfileStatus(userId, status, session).catch(errorHandling);
        },
        getNotifications: async (): Promise<INotifications> => {
            return Api.getNotifications(session.user?.id, session).catch(errorHandling);
        },
        getBillingAddress: async () => Api.getBillingAddress(session).catch(errorHandling),
        updateBillingAddress: async (address: IBillingAddress) => Api.updateBillingAddress(address, session).catch(errorHandling),
        hasEmail,
        user: session.user,
        tawkHash: session.tawkHash,
    };
}
