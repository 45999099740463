import { FilePdfOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';

export function getColumns(onDownload: (invoice: IInvoice) => void): ColumnsType<IInvoice> {
    return [
        {
            title: 'N°',
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => (<a href={`/expertises/${id}`}>#{id}</a>),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => type,
        },
        {
            title: 'Validée le',
            dataIndex: 'repliedAt',
            key: 'repliedAt',
            render: (value: Date) => {
                if (!value) return null;
                value = new Date(value);
                return value.toLocaleDateString();
            },
        },
        {
            title: 'Requérant',
            dataIndex: 'requester',
            key: 'requester',
        },
        {
            title: 'Requis',
            dataIndex: 'replier',
            key: 'replier',
        },
        {
            title: 'Patient',
            dataIndex: 'patient',
            key: 'patient',
        },
        {
            title: 'Etat',
            dataIndex: 'status',
            key: 'status',
            render: (value: string) => {
                switch (value) {
                    case 'TODO':
                        return <Alert message="En attente de facturation" type="info" />;
                    case 'PRINTED':
                        return <Alert message="Feuille de soin imprimée" type="success" />;
                }
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '6rem',
            render: (invoice: IInvoice) => (
                <div className='actions'>
                    <Button
                        className="action-button"
                        type="primary"
                        onClick={() => onDownload(invoice)}
                        icon={<FilePdfOutlined />}
                    >
                        Télécharger la feuille de soin
                    </Button>
                </div>
            ),
        },
    ];
}
