import { Button, Modal, Progress } from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import React, { memo, ReactElement, useEffect, useState } from 'react';
import { useDocumentActions } from 'redux/actions/document.action';
import { renderMessage, renderStandaloneElement, useDocumentPreview, useMessage } from 'services/helpers';
import Document from '.';

interface IProps {
    uploadRequestOptions: UploadRequestOption;
    document?: IDocument;
    saveDocument?: (document: IDocument) => void;
    downloadDocument?: () => void;
    deleteDocument?: () => void;
    shareDocument?: () => void;
    updateDocument?: (document: IDocument) => void;
    afterUpload?: (document: IDocument) => void;
    uploadProgress?: (loaded: number, total: number) => void;
    onHide?: () => void;
}

const UploadModal = (props: IProps): ReactElement | null => {
    const [message, setMessage] = useMessage();
    const [uploadProgress, setUploadProgress] = useState<number>();
    const [isShown, setShown] = useState<boolean>(true);
    const [canSave, setCanSave] = useState<boolean>(true);
    const [file, setFile] = useState<File>();
    const [document, setDocument] = useState<IDocument | undefined>(props.document);

    const { updateDocument, uploadDocument } = useDocumentActions();
    const previewDocument = useDocumentPreview();

    useEffect(() => {
        (async () => {
            await previewDocument({
                uploadRequestOptions: props.uploadRequestOptions,
                onBeforeUpload: () => {
                    setUploadProgress(0);
                },
                onAfterUpload: (fileIn: File) => {
                    const { name, type, size } = fileIn;
                    setDocument({
                        name,
                        mimetype: type,
                        size,
                    });
                    setFile(fileIn);
                },
            }).catch((err: Error) => {
                throw err;
            });
        })().catch((err: Error) => {
            console.log(err);
        });
    }, []);

    const onSaveDocument = async () => {
        try {
            if (!file) {
                throw new Error('File undefined');
            }

            setCanSave(false);

            const documentIn = {
                ...await uploadDocument(file, async (progressEvent: ProgressEvent) => {
                    const { loaded, total } = progressEvent;
                    if (loaded < total) {
                        setUploadProgress(loaded / total * 100);
                    }
                    else {
                        setMessage('Votre document a bien été importé', 'success');
                        setUploadProgress(undefined);
                    }
                }).catch((err: Error) => {
                    throw err;
                }),
                name: document?.name ?? '',
            };

            await updateDocument(documentIn).catch((err: Error) => {
                throw err;
            });

            props.saveDocument?.(documentIn);

            setDocument(documentIn);

            hide();
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onUpdateDocument = (documentIn: IDocument) => {
        setDocument(documentIn);
        props.updateDocument?.(documentIn);
    };

    const renderActions = (): ReactElement => {
        return <>
            <Button key="cancel" onClick={hide}>Annuler</Button>
            <Button key="save" type="primary" onClick={onSaveDocument} disabled={!canSave}>Sauver</Button>
        </>;
    };

    const hide = () => {
        setShown(false);
        setDocument(undefined);
        props.onHide?.();
    };

    const render = (): ReactElement => {
        if (uploadProgress) {
            return <div className="upload-progress">
                <Progress type="circle" percent={Math.round(uploadProgress)} />
                <div className="description">Upload du fichier...</div>
            </div>;
        }

        return <>
            {renderMessage(message)}
            <Document
                document={document}
                file={file}
                update={onUpdateDocument}
            />
        </>;
    };

    return <Modal
        className="upload-modal"
        title="Import de document"
        visible={isShown}
        onCancel={hide}
        width="60rem"
        centered
        footer={renderActions()}>
        {render()}
    </Modal>;
};

export function renderDocumentPreviewModal(props: IProps): void {
    renderStandaloneElement(UploadModal, props);
}

export default memo(UploadModal);
