import { useCallback, useContext, useState } from 'react';
import { IVitaleBeneficiary } from 'services/devbox/vitale';
import moment from 'moment/moment';
import { VitaleContext } from './vitale-context';
import { usePatientActions } from 'redux/actions/patient.action';

interface IProps {
    onReadSuccess?: (patients: IPatient[]) => void;
}

export const useVitale = (props: IProps) => {
    const {isAvailable, devBox} = useContext(VitaleContext);
    const {createPatient}  = usePatientActions();
    const [loading, setLoading] = useState<boolean>(false);
    const [patients, setPatients] = useState<IPatient[]>([]);
    const readCard = async (): Promise<IPatient[]> => {
        setLoading(true);

        try {
            const vitale = await devBox.readVitale();
            const tmpPatients = vitale?.cardVitale?.data.listeBenef.map(formatPatient) ?? [];
            // tmpPatients[1] = tmpPatients[0]; // Dev to get multiple rows

            const createPatients = async () => {
                return await Promise.all(tmpPatients.map(createPatient));
            };

            return await createPatients().then((patients: IPatient[]) => {
                setPatients(patients);
                props.onReadSuccess && props.onReadSuccess(patients);

                return patients;
            }).catch(e => {
                console.error(e);

                return [];
            });
        } catch (err) {
            setPatients([]);
            throw new Error('Une erreur est survenue durant la lecture de la carte. Veuillez réessayer.');
        } finally {
            setLoading(false);
        }
    };

    const downloadDevBox = () => {
        if (navigator.appVersion?.indexOf('Mac') != -1) {
            window.location.href = '/DevBoxSante-medz-macos.dmg';
        } else {
            window.location.href = '/DevBoxSante-medz-windows-x64.exe';
        }
    };

    const formatPatient = (patient: IVitaleBeneficiary): IPatientParams => {
        return {
            firstName: patient.ident.prenomUsuel,
            lastName: patient.ident.nomUsuel,
            birthName: patient.ident.nomPatronymique ?? null,
            sex: patient.ident.nir.startsWith('1') ? 'M' : 'F',
            birthRank: parseInt(patient.ident.rangDeNaissance || '1'),
            INS: patient.ident.nir.replace(' ', ''),
            insuranceCode: `${patient.amo.codeRegime}${patient.amo.caisse}${patient.amo.centreCarte}`,
            birthDate: moment(patient.ident.naissance.date, 'DDMMYYYY').toDate(),
            fromVitale: true,
        };
    };

    const clear = useCallback(() => {
        setPatients([]);
    }, []);

    const getPatient = useCallback((index: number) => patients[index], [patients]);

    return {
        isAvailable,
        isLoading: loading,
        patients,
        readCard,
        getPatient,
        downloadSoftware: downloadDevBox,
        clear,
    };
};
