import { Button, Card, notification, Space } from 'antd';
import React, { memo, ReactElement, useCallback, useRef, useState } from 'react';
import './index.less';
import TableComponent, { TableHandle } from './table';
import { CloudSyncOutlined, UserAddOutlined } from '@ant-design/icons';
import { usePatientActions } from 'redux/actions/patient.action';
import { VitaleButton } from '../vitale/vitale-button';
import PatientCreationModal from '../patient-modal/patient-creation-modal';
import { CardioPremierModal } from './cardioPremier';

interface IImportProps {
    onImportFinish?: (countCreatedPatients: number) => void;
}

const ImportCardioPremierButton = (props: IImportProps) => {
    const [modal, setModal] = useState<React.ReactElement | null>(null);

    const openModal = useCallback(() => {
        setModal(<CardioPremierModal hide={() => {
            setModal(null);
        }} onFinish={props.onImportFinish} />);
    }, []);

    return <>
        {modal}
        <Button
            size={'small'}
            icon={<CloudSyncOutlined />}
            onClick={openModal}
        >
            {'Importer depuis cardio premier'}
        </Button>
    </>;
};

const PatientsComponent = (): ReactElement => {
    const [api, contextHolder] = notification.useNotification();
    const tableRef = useRef<TableHandle>(null);
    const [patientModal, setPatientModal] = useState<ReactElement | null>(null);
    const { createPatient } = usePatientActions();
    const reloadTable = useCallback(() => {
        tableRef.current?.reload();
    }, [tableRef]);

    const onCardioPremierImportFinished = useCallback((created: number) => {
        if(created > 0) {
            api.success({
                message: `${created} patients ont été importés`,
                duration: 3,
            });
        }
        setTimeout(() => {
            reloadTable();
        }, 1000);
    }, [reloadTable]);

    const addPatient = async (patient: IPatientParams) => {
        await createPatient(patient).catch((err: Error) => {
            throw err;
        });

        setPatientModal(null);
        reloadTable();
    };

    const showPatientCreationModal = () => {
        setPatientModal(<PatientCreationModal
            onHide={() => {
                setPatientModal(null);
            }}
            validate={addPatient}
            onSelectPatient={addPatient}
        />);
    };

    return <Card
        bordered={false}
        title={'Patients'}
        className="patients"
        extra={
            <Space size={32}>
                <VitaleButton onSelect={() => {
                    api.success({
                        message: 'Patient importé avec succès',
                        duration: 3,
                    });

                    reloadTable();
                }} />
                <Button onClick={showPatientCreationModal} title={'Nouveau patient'} size="small">
                    <UserAddOutlined color={'white'} />
                </Button>
                <ImportCardioPremierButton onImportFinish={onCardioPremierImportFinished} />
            </Space>
        }
    >
        {contextHolder}
        {patientModal}
        <TableComponent ref={tableRef} />
    </Card>;
};

export default memo(PatientsComponent);
