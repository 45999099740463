import { TablePaginationConfig } from 'antd';
import { memo, ReactElement, useEffect, useState } from 'react';
import { useSessionActions } from 'redux/actions/session.action';
import { useUsersActions } from 'redux/actions/users.action';
import { isUploadedDocument, useMessage } from 'services/helpers';
import { renderDocumentVisuModal } from '../../document/visu-modal';
import AccountsAdministrationComponent from './component';

const Limit = 10;

const AccountsAdministration = (): ReactElement | null => {
    const { getUsers, getUser } = useSessionActions();
    const [users, setUsers] = useState<IUser[]>([]);
    const [pagination, setPagination] = useState<IPagination>();
    const [message, setMessage] = useMessage();

    const { updateProfileStatus } = useUsersActions();

    const setUpdatedUsers = async (user: IUser) => {
        const newUsers = [...users];
        const newUser = await getUser(user.id)
            .then((userIn: IUser) => userIn)
            .catch((err: Error) => {
                setMessage(err);
            });
        if (newUser)
            newUsers.splice(newUsers.indexOf(user), 1, newUser);
        setUsers(newUsers);
    };

    const activateUser = async (user: IUser) => {
        await updateProfileStatus(user.id, 'ACTIVE')
            .then(async () => {
                await setUpdatedUsers(user);
            })
            .catch((err: Error) => {
                setMessage(err);
            });
    };

    const deactivateUser = async (user: IUser) => {
        await updateProfileStatus(user.id, 'INACTIVE')
            .then(async () => {
                await setUpdatedUsers(user);
            })
            .catch((err: Error) => {
                setMessage(err);
            });
    };

    const showCPSCard = (document: IDocument) => {
        if (!isUploadedDocument(document)) return;
        renderDocumentVisuModal({ document });
    };

    const searchUsers = (page?: number, pageSize?: number, searchIn?: string) => {
        const limit = pageSize ?? Limit;
        getUsers({ limit, offset: ((page ?? 1) - 1) * limit, search: searchIn })
            .then((data: IPaginatedResults<IUser>) => {
                const { results, count } = data;
                setUsers(results);
                setPagination({
                    current: page,
                    pageSize: pageSize ?? Limit,
                    defaultPageSize: Limit,
                    total: count ?? 0,
                    pageSizeOptions: [Limit, Limit * 2, Limit * 5, Limit * 10],
                });
            })
            .catch((err: Error) => {
                setMessage(err);
            });
    };

    const search = async (searchIn?: string) => {
        searchUsers(undefined, undefined, searchIn);
    };

    const changePagination = (paginationIn: TablePaginationConfig) => {
        const { current, pageSize } = paginationIn;
        searchUsers(current, pageSize);
    };

    useEffect(() => {
        searchUsers(1);
    }, []);

    return <AccountsAdministrationComponent
        users={users}
        activateUser={activateUser}
        deactivateUser={deactivateUser}
        showCPSCard={showCPSCard}
        changePagination={changePagination}
        search={search}
        message={message}
        pagination={pagination}
    />;
};

export default memo(AccountsAdministration);
