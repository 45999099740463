import { Button, Card, Checkbox, Divider, Form, Input, RadioChangeEvent, Select, Table, Typography } from 'antd';
import clone from 'clone';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { ChangeEvent, ReactElement, memo, useEffect, useState } from 'react';
import { CivilityOptions, DefaultCivility, renderMessage } from 'services/helpers';
import { IdentificationComponent } from './identification.component';
import './index.less';

const { Option } = Select;

interface IPostalAddressKeyed extends IPostalAddress {
    key: string;
}

interface IProps {
    message?: IMessage;
    save: (values: IAccountParams) => void;
    uploadPhoto: (options: UploadRequestOption) => void;
    user: IUser;
    uploadProgress?: number;
    cpsPhoto?: IDocumentMeta;
    pscEnabled?: boolean;
}

const AccountComponent = (props: IProps): ReactElement | null => {
    const [form] = Form.useForm<IAccountParams>();
    const [account, setAccount] = useState<IAccountParams>();
    const [addresses, setAddresses] = useState<IPostalAddress[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<number>(0);

    useEffect(() => {
        const user = props.user;
        if (user) {
            setAccountAndForm(user);
        }
    }, [props.user]);

    useEffect(() => {
        document.querySelector('.account .ant-card-body')?.scroll(0, 0);
    }, [props.message]);

    const setAccountAndForm = (user: IUser) => {
        const { rpps, knowledgeLabel, professionLabel, civility } = user;
        setAccount({
            rpps: user.rpps,
            canHandleHospitalization: user.canHandleHospitalization,
            civility: user.civility,
            email: user.email,
            expertPathology: user.expertPathology,
            firstName: user.firstName,
            isExpert: user.isExpert,
            knowledgeLabel: user.knowledgeLabel,
            lastName: user.lastName,
            speciality: user.speciality,
            subSpeciality: user.subSpeciality,
            visible: user.visible,
        });

        const addresses = setPlacesOfPracticeFromUser(user);
        const accountIn: IAccountParams = {
            ...user,
            idCard: user.idCard?.id,
            rpps,
            civility: civility || DefaultCivility,
            speciality: professionLabel,
            subSpeciality: knowledgeLabel,
            addresses,
        };

        form.setFieldsValue(accountIn);
    };

    const onSave = (values: IAccountParams) => {
        if (!account) return;
        props.save({
            ...account,
            ...values,
            addresses: addresses.filter((postalAddress: IPostalAddress) => postalAddress.city || postalAddress.finessSite || postalAddress.address || postalAddress.adeli || postalAddress.postalCode),
        });
    };

    const onSelectAddress = (event: RadioChangeEvent) => {
        const newAddresses = [...addresses];
        newAddresses.forEach((address: IPostalAddress, index: number) => {
            address.selected = index === event.target.value;
        });
        setAddresses(newAddresses);
        setSelectedAddress(event.target.value);
    };

    const onUpdateNewAddress = (value: string, type: keyof IPostalAddress, placeOfPracticeNumber: number) => {
        const newAddresses = [...addresses];
        // @ts-expect-error incompatbile type
        newAddresses[placeOfPracticeNumber][type] = value.trim();

        const address = {
            ...newAddresses[placeOfPracticeNumber],
            selected: account?.addresses?.findIndex((addressIn: IPostalAddress) => addressIn.selected) === placeOfPracticeNumber,
            [type]: value,
        };
        newAddresses[placeOfPracticeNumber] = address;

        setAddresses(newAddresses);

        const clonedAccount = clone(account);
        if (clonedAccount && clonedAccount.addresses && clonedAccount.addresses.length > 0) {
            const addresses = clonedAccount.addresses;
            addresses[addresses.length - 1] = address;
            console.log(addresses);
            setAccount(clonedAccount);
        }
    };

    const getNewAddressFields = (): IPostalAddressKeyed[] => {
        return addresses ? [
            {
                key: 'new-place-of-practice',
                ...addresses,
            },
        ] : [{
            key: 'new-place-of-practice',
            address: undefined,
            postalCode: undefined,
            city: undefined,
            selected: false,
        }];
    };

    const setPlacesOfPracticeFromUser = (user?: IUser): IPostalAddressKeyed[] => {
        const placesOfPracticeIn = [
            ...(user?.addresses || []).map((addressIn: IPostalAddress, index: number): IPostalAddressKeyed => {
                const { address, city, postalCode, selected } = addressIn;
                return {
                    key: 'pop' + index,
                    id: addressIn.id,
                    address,
                    city,
                    selected,
                    adeli: addressIn.adeli,
                    finessSite: addressIn.finessSite,
                    siteName: addressIn.siteName,
                    postalCode: postalCode,
                };
            }).sort((a: IPostalAddressKeyed, b: IPostalAddressKeyed) => (a.address ?? '').localeCompare(b.address ?? '') > 0 ? 1 : -1),
            ...getNewAddressFields(),
        ];

        let selected = placesOfPracticeIn.findIndex((address) => address.selected);
        selected = selected === -1 ? 0 : selected;
        setSelectedAddress(selected);
        setAddresses(placesOfPracticeIn);

        return placesOfPracticeIn;
    };



    const renderCivilities = (): ReactElement => {
        return <Select>{Object.values(CivilityOptions).map((civility: any, index: number) => {
            return <Option key={'civ' + index} value={civility}>{civility}</Option>;
        })}</Select>;
    };

    return <Card className="account" bordered={false} title="Profil">
        <Form
            form={form}
            layout="horizontal"
            labelCol={{ xs: 8, xl: 5 }}
            wrapperCol={{ xs: 16, xl: 18 }}
            onFinish={onSave}
            initialValues={{
                ...props.user,
                civility: DefaultCivility,
            }}
        >
            {renderMessage(props.message)}

            {
                props.user?.status !== 'ACTIVE' ? <IdentificationComponent
                    uploadPhoto={props.uploadPhoto}
                    user={props.user}
                    cpsPhoto={props.cpsPhoto}
                    pscEnabled={props.pscEnabled}
                /> : null
            }

            <Divider plain>Informations personnelles</Divider>
            <Form.Item name="email" label="Adresse mail">
                <Input readOnly />
            </Form.Item>
            <Form.Item name="rpps" label="Numéro RPPS">
                <Input readOnly />
            </Form.Item>

            <Form.Item name="civility" label="Civilité">
                {renderCivilities()}
            </Form.Item>

            <Form.Item name="lastName" label="Nom">
                <Input />
            </Form.Item>
            <Form.Item name="firstName" label="Prénom">
                <Input />
            </Form.Item>

            <Divider plain>Lieux d&apos;exercice</Divider>
            <Typography.Text type="secondary">
                Afin de faciliter la cotation, merci de renseigner :<br />
                <ul>
                    <li>Votre numéro A.M. ( anciennement ADELI ), si vous exercez en libéral</li>
                    <li>Votre FINESS si vous êtes salarié</li>
                </ul>
            </Typography.Text>

            <Form.Item name="selectedAddress" wrapperCol={{ xs: 24, xl: 23 }}>
                <Table dataSource={addresses} columns={[
                    {
                        key: 'action',
                        title: 'Sélection',
                        align: 'center',
                        render: (_, __placeOfPractice: IPostalAddress, index: number) => {
                            return (
                                <Checkbox value={index} onChange={onSelectAddress} checked={index === selectedAddress} />
                            );
                        },
                        width: 100,
                    },
                    {
                        title: 'ADELI',
                        dataIndex: 'adeli',
                        key: 'adeli',
                        render: (_, placeOfPractice: IPostalAddress, index: number) => {
                            return <Input maxLength={9} value={placeOfPractice.adeli} onChange={(event: ChangeEvent<HTMLInputElement>) => onUpdateNewAddress(event.target.value, 'adeli', index)} />;
                        },
                    },
                    {
                        title: 'FINESS',
                        dataIndex: 'finessSite',
                        key: 'finessSite',
                        render: (_, placeOfPractice: IPostalAddress, index: number) => {
                            return <Input value={placeOfPractice.finessSite} onChange={(event: ChangeEvent<HTMLInputElement>) => onUpdateNewAddress(event.target.value, 'finessSite', index)} />;
                        },
                    },
                    {
                        title: 'Site',
                        dataIndex: 'siteName',
                        key: 'siteName',
                        render: (_, placeOfPractice: IPostalAddress, index: number) => {
                            return <Input value={placeOfPractice.siteName} onChange={(event: ChangeEvent<HTMLInputElement>) => onUpdateNewAddress(event.target.value, 'siteName', index)} />;
                        },
                    },
                    {
                        title: 'Adresse',
                        dataIndex: 'address',
                        key: 'address',
                        render: (_, placeOfPractice: IPostalAddress, index: number) => {
                            return <Input value={placeOfPractice.address} onChange={(event: ChangeEvent<HTMLInputElement>) => onUpdateNewAddress(event.target.value, 'address', index)} />;
                        },
                    },
                    {
                        title: 'Code postal',
                        dataIndex: 'postalCode',
                        key: 'postalCode',
                        render: (_, placeOfPractice: IPostalAddress, index: number) => {
                            return <Input value={placeOfPractice.postalCode} maxLength={5} onChange={(event: ChangeEvent<HTMLInputElement>) => onUpdateNewAddress(event.target.value, 'postalCode', index)} />;
                        },
                    },
                    {
                        title: 'Ville',
                        dataIndex: 'city',
                        key: 'city',
                        render: (_, placeOfPractice: IPostalAddress, index: number) => {
                            return <Input value={placeOfPractice.city} onChange={(event: ChangeEvent<HTMLInputElement>) => onUpdateNewAddress(event.target.value, 'city', index)} />;
                        },
                    },
                ]} pagination={false} bordered={true} size="small" locale={{
                    emptyText: 'Aucune adresse',
                }} />
            </Form.Item>

            <Form.Item className="actions">
                <Button type="primary" htmlType="submit">Sauver</Button>
            </Form.Item>
        </Form>
    </Card >;
};

export default memo(AccountComponent);
