import { memo, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSessionActions } from 'redux/actions/session.action';
import { AppState } from 'redux/reducers';
import { useMessage } from 'services/helpers';
import EmailComponent from './component';

const Email = (): ReactElement => {
    const [message, setMessage] = useMessage();
    const [isValidatingModalShown, setValidatingModalShown] = useState<boolean>(false);
    const { requestEmailCode, validateCode, updateSessionUser, getAccount } = useSessionActions();

    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const { session } = mapStateToObj;

    const onSave = async (params: IRequestResetParams) => {
        try {
            await requestEmailCode(params);

            setMessage('Un code de validation vous a été envoyé', 'success');
            setValidatingModalShown(true);
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onValidateCode = async (params: IValidationCodeParams) => {
        try {
            await validateCode(params, session);

            updateSessionUser(await getAccount());
            setMessage('Votre adresse mail a été modifiée avec succès', 'success');
            setValidatingModalShown(false);
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onHideValidatingModal = () => {
        setValidatingModalShown(false);
    };

    return <EmailComponent
        save={onSave}
        validateCode={onValidateCode}
        message={message}
        user={session.user}
        isValidatingModalShown={isValidatingModalShown}
        hideDocumentsModal={onHideValidatingModal}
    />;
};

export default memo(Email);
