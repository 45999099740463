import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Collapse, List, Tag } from 'antd';
import { ReactElement, ReactNode, memo } from 'react';

const { Panel } = Collapse;

interface IProps {
    loadingExpertises: boolean;
    expertisesInProgress: IExpertise[];
    expertisesEnded: IExpertise[];
    expertise?: IExpertise;
    previousExpertise?: IExpertise;
    isExpertisesSent: boolean;
    changeExpertise: (expertise: IExpertise) => void;

}

const ExpertiseList = (props: IProps): ReactElement => {
    const onChangeExpertise = (expertise: IExpertise) => {
        props.changeExpertise(expertise);
    };

    const getPersonName = (person?: IPerson): string => {
        if (!person) {
            return '';
        }

        if (person.type === 'UserEntity') {
            return person.firstName && person.lastName ? `${person.civility} ${person.firstName} ${person.lastName}` : (person.email ?? '');
        }

        return person.name ?? person.email ?? '';
    };

    const getPersonTypeIcon = (person?: IPerson): ReactNode => {
        if (!person) {
            return '';
        }
        return person.type === 'UserEntity' ?
            <>
                <UserOutlined />
                <span className="expertise-item-title">{getPersonName(person)}</span>
            </> :
            <>
                <TeamOutlined />
                <span className="expertise-item-title">{getPersonName(person)}</span>
            </>;
    };

    const renderExpertisePatient = (patientIn?: IPatient): ReactNode => {
        return patientIn ?
            <>
                <div>{`${patientIn.firstName ?? ''} ${patientIn.lastName ?? ''}`}</div>
                {patientIn.birthDate ? <div>{`${patientIn.birthDate?.toLocaleDateString('fr-FR')}`}</div> : undefined}
            </> :
            <div>Patient non défini</div>;
    };

    const renderStatus = (statusIn: IExpertise['status']): ReactNode => {
        switch (statusIn) {
            case 'DRAFT':
                return <Tag color="blue">Brouillon</Tag>;
            case 'SENT':
                return <Tag color="yellow">En cours</Tag>;
            case 'REPLIED':
                return <Tag color="orange">A facturer</Tag>;
            case 'PRINTED':
                return <Tag color="green">Facturée</Tag>;
            case 'ARCHIVED':
                return <Tag color="red">Archivée</Tag>;
            default:
                return undefined;
        }
    };

    return <>
        <Collapse accordion className="expertises-view" defaultActiveKey={1}>
            <Panel header={<>Expertises en cours <Badge status={'warning'} count={props.expertisesInProgress.length} showZero={true} /></>} key="1">
                <List
                    itemLayout="horizontal"
                    dataSource={props.expertisesInProgress}
                    loading={props.loadingExpertises}
                    renderItem={(item: IExpertise) => {
                        const { patient: patientIn, id, content, updatedAt, requester: requesterIn, recipient: recipientIn, seen, status: statusIn } = item;

                        return <List.Item
                            key={'e' + id}
                            onClick={() => onChangeExpertise(item)}
                            className={id === props.expertise?.id ? 'selected' : undefined}
                        >
                            <List.Item.Meta
                                title={<div className="list-item-main-info">
                                    <div> {props.isExpertisesSent ? getPersonTypeIcon(recipientIn) : getPersonTypeIcon(requesterIn)}{
                                        !seen &&
                                            id !== props.expertise?.id &&
                                            id !== props.previousExpertise?.id ? <Badge dot={true} /> :
                                            undefined}</div>
                                    <div>{updatedAt ? updatedAt.toLocaleDateString('fr-FR') : undefined}</div>
                                </div>}
                                description={<div className="list-item-secondary-info">
                                    <div className='list-item-secondary-info-patient'>
                                        {renderExpertisePatient(patientIn)}
                                    </div>
                                    <div>
                                        <div>{renderStatus(statusIn)}</div>
                                    </div>
                                </div>}
                            />
                            {<pre>{content}</pre>}
                        </List.Item>;
                    }}
                    size="small"
                    locale={{ emptyText: 'Aucune expertise' }}
                />
            </Panel>
            <Panel header={<>Expertises terminées <Badge status={'success'} count={props.expertisesEnded.length} showZero={true} /></>} key="2">
                <List
                    itemLayout="horizontal"
                    dataSource={props.expertisesEnded}
                    loading={props.loadingExpertises}
                    renderItem={(item: IExpertise) => {
                        const { patient: patientIn, id, content, updatedAt, requester: requesterIn, recipient: recipientIn, seen, status: statusIn } = item;

                        return <List.Item
                            key={'e' + id}
                            onClick={() => onChangeExpertise(item)}
                            className={id === props.expertise?.id ? 'selected' : undefined}
                        >
                            <List.Item.Meta
                                title={<div className="list-item-main-info">
                                    <div> {props.isExpertisesSent ? getPersonTypeIcon(recipientIn) : getPersonTypeIcon(requesterIn)}{
                                        !seen &&
                                            id !== props.expertise?.id &&
                                            id !== props.previousExpertise?.id ? <Badge dot={true} /> :
                                            undefined}</div>
                                    <div>{updatedAt ? updatedAt.toLocaleDateString('fr-FR') : undefined}</div>
                                </div>}
                                description={<div className="list-item-secondary-info">
                                    <div className='list-item-secondary-info-patient'>
                                        {renderExpertisePatient(patientIn)}
                                    </div>
                                    <div>
                                        <div>{renderStatus(statusIn)}</div>
                                    </div>
                                </div>}
                            />
                            {<pre>{content}</pre>}
                        </List.Item>;
                    }}
                    size="small"
                    locale={{ emptyText: 'Aucune expertise' }}
                />
            </Panel>
        </Collapse>
    </>;
};

export default memo(ExpertiseList);
