import Loader from 'components/loader';
import React, { memo, ReactElement, ReactNode } from 'react';
import './index.less';

interface IProps {
    message?: IMessage;
}

const PscComponent = (props: IProps): ReactElement => {
    const renderMessage = (message?: IMessage): ReactNode => {
        return message ? <span className="error">{message.message}</span> : 'Connexion en cours...';
    };
    return <Loader shown={true} message={renderMessage(props.message)} />;
};

export default memo(PscComponent);
