import { Modal } from 'antd';
import { saveAs } from 'file-saver';
import { memo, ReactElement, useState } from 'react';
import { useExpertiseActions } from 'redux/actions/expertise.action';
import { renderStandaloneElement, useMessage } from 'services/helpers';
import Viewer from '../../document/viewer';
import './index.less';
import { useDocumentVisuActions as useActions } from './visu/component';

interface IProps {
    document: IUploadedDocument;
    onHide?: () => void;
    expertise?: IExpertise;
    downloadDocument?: () => void;
    deleteDocument?: () => void;
    isOwner?: boolean;
}

const VisuModal = (props: IProps): ReactElement => {
    const {getDocumentFromExpertise} = useExpertiseActions();
    const [message, setMessage] = useMessage();
    const [isShown, setShown] = useState<boolean>(true);
    const { getDocumentFromExpertiseContent, deleteDocumentFromExpertise } = useExpertiseActions();

    const onDownloadDocument = async () => {
        try {
            const document = props.document;
            const expertise = props.expertise;

            if (!document) {
                throw new Error('Document not defined');
            }
            if (!expertise) {
                throw new Error('Expertise not defined');
            }

            saveAs(new Blob([await getDocumentFromExpertiseContent(expertise.id, document.id).catch((err: Error) => {
                throw err;
            })], {
                type: 'application/octet-stream',
            }), document.name);

            props.downloadDocument?.();
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onDeleteDocument = async () => {
        try {
            const document = props.document;
            const expertise = props.expertise;

            if (!document) {
                throw new Error('Document not defined');
            }
            if (!expertise) {
                throw new Error('Expertise not defined');
            }

            await deleteDocumentFromExpertise(expertise.id, document.id).catch((err: Error) => {
                throw err;
            });

            props.deleteDocument?.();

            hide();
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const { renderDocumentVisuActions } = props.isOwner ? useActions(onDownloadDocument, onDeleteDocument) : useActions(onDownloadDocument);

    const hide = () => {
        setShown(false);
        props.onHide?.();
    };

    const render = (): ReactElement => {
        const expertise = props.expertise;

        if (!expertise) {
            throw new Error('Expertise not defined');
        }

        return <Viewer
            message={message}
            document={props.document}
            expertise={props.expertise}
            getDocument={() => getDocumentFromExpertise(expertise.id, props.document.id)}
        />;
    };

    return <Modal
        className="visu-modal"
        title="Document"
        visible={isShown}
        onCancel={hide}
        width="60rem"
        footer={renderDocumentVisuActions()}
        centered>
        {render()}
    </Modal>;
};

export function renderDocumentVisuModal(props: IProps): void {
    renderStandaloneElement(VisuModal, props);
}

export default memo(VisuModal);
