import { Form, Radio, RadioChangeEvent } from 'antd';

export type BillingStatusFilter = IInvoice['status'] | 'ALL';

interface IProps {
    filterState: BillingStatusFilter;
    setFilterState: (state: BillingStatusFilter) => void;
}

export const Filters = (props: IProps) => {

    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        props.setFilterState(e.target.value);
    };

    return <>
        <Form.Item label="Etat">
            <Radio.Group onChange={onChange} value={props.filterState} optionType="button" buttonStyle="solid">
                <Radio value={'ALL'}>Toutes</Radio>
                <Radio value={'TODO'}>En attente</Radio>
                <Radio value={'PRINTED'}>Facturées</Radio>
            </Radio.Group>
        </Form.Item>
    </>;
};