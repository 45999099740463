import React, { memo, ReactElement, useEffect, useState } from 'react';
import { useSessionActions } from '../../redux/actions/session.action';
import { Card, Col, List, Row, Space, Tag } from 'antd';
import SetupForm from './setupForm';

const CreatePaymentMethodComponent = (): ReactElement => {
    return <Card bordered={false} title="Ajouter un moyen de paiement">
        <SetupForm />
    </Card>;
};

const PaymentMethodsComponent = (): ReactElement => {
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const {getPaymentMethods} = useSessionActions();

    useEffect(() => {
        getPaymentMethods().then(setPaymentMethods).catch(console.error);
    }, []);


    return <Card bordered={false} title="Vos moyens de paiements">
        <List
            itemLayout="horizontal"
            dataSource={paymentMethods}
            renderItem={(item) => (
                <List.Item key={item.id}>
                    <List.Item.Meta
                        title={item.title}
                        description={
                            <Space>
                                {item.description}
                                {item.selected && <Tag color="processing">Utilisé par défaut</Tag>}
                            </Space>
                        }
                    />
                </List.Item>
            )}
        />
    </Card>;
};


const PaymentMethods = (): ReactElement => {
    return <Row gutter={[16, 16]} style={{width: '100%'}}>
        <Col span={12}>
            <PaymentMethodsComponent/>
        </Col>
        <Col span={12}>
            <CreatePaymentMethodComponent/>
        </Col>
    </Row>
    ;
};

export default memo(PaymentMethods);
