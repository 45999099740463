import { Button, Modal, notification } from 'antd';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { renderMessage, useMessage } from 'services/helpers';
import './index.less';
import { PatientForm } from './patient-form';
import { VitaleButton } from '../vitale/vitale-button';
import { VitaleInstallButton } from '../vitale/install-button';
import { SearchOutlined } from '@ant-design/icons';
import { usePatientActions } from '../../redux/actions/patient.action';
import { VitalePatientSelectModal } from '../vitale/patients-modal';

interface IProps {
    validate: (patient: IPatientParams) => void;
    onHide: () => void;
    show: boolean;
    values?: IPatientParams | IPatient;
    readonly?: boolean;
    isAuthor?: boolean;
}

const PatientModal = ({ readonly = false, isAuthor = false, ...props }: IProps): ReactElement | null => {
    const [message, setMessage] = useMessage();
    const [patient, setPatient] = useState<IPatientParams | IPatient | undefined>(props.values);
    const [api, contextHolder] = notification.useNotification();
    const { searchPatients } = usePatientActions();
    const [completingLoading, setCompletingLoading] = useState<boolean>(false);
    const [modal, setModal] = useState<ReactElement | null>(null);

    const isPatientComplete =
        props.values !== undefined &&
        (props.values.fromVitale
            || Boolean(
                props.values.firstName &&
                props.values.lastName &&
                props.values.sex &&
                props.values.INS &&
                props.values.insuranceCode &&
                props.values.birthRank,
            ))
    ;

    useEffect(() => {
        if (isAuthor || isPatientComplete) {
            return;
        }
    }, [isAuthor, isPatientComplete]);

    const onValidate$ = new Subject<void>();

    const onValidate = async () => {
        onValidate$.next();
    };

    const validate = (patient: IPatientParams) => {
        props.validate(patient);
        hide();
    };

    const hide = () => {
        setPatient(props.values);
        props.onHide();
    };

    const isPatient = useCallback((patient: IPatientParams | IPatient | undefined): patient is IPatient => {
        return Boolean((patient as IPatient).id);
    }, []);


    const completePatientFromDatabase = useCallback(async () => {
        setCompletingLoading(true);
        const excludeId = isPatient(props.values) ? props.values.id : null;
        const patients = await searchPatients({
            params: {
                firstName: props.values?.firstName,
                lastName: props.values?.lastName,
                birthDate: props.values?.birthDate ?? undefined,
            },
        });

        const choices = patients.results.filter(p => p.id !== excludeId);

        if (choices.length) {
            setModal(<VitalePatientSelectModal showINS patients={choices} onValidate={(p) => {
                setPatient({
                    ...choices[p],
                    fromVitale: false,
                });
                setModal(null);
            }} cancel={() => setModal(null)}/>);
        } else {
            api.info({
                message: 'Aucun patient ne correspond dans votre base',
            });
        }

        setCompletingLoading(false);

    }, [props.values, isPatient]);

    return <>
        <Modal
            className="patient-modal"
            title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Patient</span>
                {!readonly && <VitaleButton onSelect={setPatient}/>}
                {!isAuthor && !isPatientComplete && <Button
                    onClick={completePatientFromDatabase}
                    loading={completingLoading}
                    icon={<SearchOutlined/>}
                >
                    Compléter le patient depuis votre base
                </Button>}
            </div>}
            open={props.show}
            onOk={onValidate}
            onCancel={hide}
            width="60rem"
            footer={[
                !readonly && <Button key="back" onClick={hide}>
                    Annuler
                </Button>,
                !readonly && <Button key="submit" type="primary" onClick={onValidate}>
                    Valider
                </Button>,
            ]}
            closable={readonly}
        >
            {renderMessage(message)}
            {!readonly && <VitaleInstallButton/>}
            <PatientForm
                setMessage={setMessage}
                patient={patient}
                onValidate$={onValidate$}
                validate={validate}
                setPatient={setPatient}
                disabled={readonly || !isAuthor}
            />
        </Modal>
        {modal}
        {contextHolder}
    </>;
};

export default PatientModal;
