import React, { memo, ReactElement } from 'react';
import DocumentComponent from './component';

interface IProps {
    message?: IMessage;
    document?: IDocument;
    file?: File;
    update?: (document: IDocument) => void;
}

const Document = (props: IProps): ReactElement => {

    const onUpdate = (documentIn: IDocument) => {
        if (props.document) {
            props.update?.({
                ...props.document,
                ...documentIn,
            });
        }
    };

    const { message } = props;
    return <DocumentComponent
        message={message}
        document={props.document}
        file={props.file}
        update={onUpdate}
    />;
};

export default memo(Document);
