import clone from 'clone';
import { saveAs } from 'file-saver';
import pages from 'pages';
import { memo, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDocumentActions } from 'redux/actions/document.action';
import { useMessage } from 'services/helpers';
import DocumentsComponent from './component';

export const Limit = 0;

const Documents = (): ReactElement => {
    const [message, setMessage] = useMessage();
    const [data, setData] = useState<IGroupDocumentKeyed>();
    const [groupDocument, setGroupDocument] = useState<IGroupDocument>();
    const [persons, setPersons] = useState<IPerson[]>([]);
    const { pathname, state } = useLocation();
    const [offset, setOffset] = useState<number>(0);
    const { getOwnDocuments, getSharedDocuments, shareDocumentWithPerson, updateDocument, getDocumentContent, deleteDocument } = useDocumentActions();

    const onShareDocument = async () => {
        try {
            if (!groupDocument) {
                throw new Error('No document selected');
            }

            await Promise.all(persons.map((person: IPerson) => shareDocumentWithPerson(groupDocument.document, person)
                .catch((err: Error) => {
                    throw err;
                })
            )).catch((err: Error) => {
                throw err;
            });

            setMessage('Votre document a bien été partagé', 'success');
        }
        catch (err: any) {
            if (err.message === 'Bad Request') {
                setMessage('Une erreur est survenue, veuillez vérifier les informations que vous avez entrées.', 'error');
            } else {
                setMessage('Votre document a bien été partagé (quelques utilisateurs disposaient déjà du document)', 'warning');
            }
        }
    };

    const onSelectGroupDocument = async (groupDocumentIn: IGroupDocument) => {
        try {
            setGroupDocument(groupDocumentIn);
            setMessage(undefined);
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onSaveDocument = async () => {
        try {
            if (!groupDocument) {
                throw new Error('Document not defined');
            }
            await updateDocument(groupDocument.document).catch((err: Error) => {
                throw err;
            });

            await refreshCurrentDocuments();

            setMessage('Votre document a bien été sauvegardé', 'success');
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onUpdateDocument = (documentIn: IDocument, personsIn: IPerson[]) => {
        if (!groupDocument) {
            throw new Error('Document not defined');
        }

        setGroupDocument({
            ...groupDocument,
            ...{
                document: documentIn,
            },
        });
        setPersons(personsIn);
    };

    const onDownloadDocument = async () => {
        try {
            if (!groupDocument) {
                throw new Error('Document not defined');
            }

            const { id, name } = groupDocument.document;

            saveAs(new Blob([await getDocumentContent(id).catch((err: Error) => {
                throw err;
            })], {
                type: 'application/octet-stream',
            }), name);

            await refreshCurrentDocuments();
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onDeleteDocument = async () => {
        try {
            if (!groupDocument) {
                throw new Error('Document not defined');
            }

            const { document, group } = groupDocument;

            await deleteDocument(document.id, group.id).catch((err: Error) => {
                throw err;
            });

            setGroupDocument(undefined);
            await refreshCurrentDocuments();
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const getGroupDocumentsKeyed = (documents: IGroupDocument[], initialData?: IGroupDocumentKeyed): IGroupDocumentKeyed => {
        const documentsGroupsKeyed = clone(initialData || {});
        for (const groupDocumentIn of documents) {
            const { id } = groupDocumentIn.group;
            if (id) {
                if (!(id in documentsGroupsKeyed)) {
                    documentsGroupsKeyed[id] = [];
                }

                documentsGroupsKeyed[id].push(groupDocumentIn);
            }
        }
        return documentsGroupsKeyed;
    };

    const refreshDocuments = async (offsetIn: number = offset, limit = Limit): Promise<IGroupDocument[]> => {
        return pathname === pages.myDocuments.path ? getOwnDocuments(offsetIn, limit).catch((err: Error) => {
            throw err;
        }) : getSharedDocuments(offsetIn, Limit).catch((err: Error) => {
            throw err;
        });
    };

    const getNextDocuments = async (offsetIn?: number, limit = Limit) => {
        const newOffset = offsetIn !== undefined ? offsetIn : offset + limit;
        setOffset(newOffset);
        setData(getGroupDocumentsKeyed(await refreshDocuments(newOffset), newOffset > 0 ? data : undefined));
    };

    const refreshCurrentDocuments = async () => {
        await getNextDocuments(0, Object.values(data || {}).length).catch((err: Error) => {
            setMessage(err);
        });
    };

    useEffect(() => {
        (async () => {
            setMessage(undefined);
            setGroupDocument(undefined);
            await getNextDocuments(0).catch((err: Error) => {
                throw err;
            });
        })().catch((err: any) => {
            setMessage(err);
        });
    }, [pathname]);

    useEffect(() => {
        (async () => {
            setMessage(undefined);
            await refreshCurrentDocuments().catch((err: Error) => {
                throw err;
            });
        })().catch((err: any) => {
            setMessage(err);
        });
    }, [state]);

    useEffect(() => {
        (async () => {
            if (data) {
                const dataIn = Object.values(data || {});
                const groupDocumentIn = dataIn[0];
                await onSelectGroupDocument(groupDocumentIn[0]);
            }
        })().catch((err: any) => {
            setMessage(err);
        });
    }, [data]);

    return <DocumentsComponent
        message={message}
        document={groupDocument?.document}
        data={data}
        getNextDocuments={getNextDocuments}
        shareDocument={onShareDocument}
        selectGroupDocument={onSelectGroupDocument}
        saveDocument={onSaveDocument}
        updateDocument={onUpdateDocument}
        downloadDocument={onDownloadDocument}
        deleteDocument={onDeleteDocument}
    />;
};

export default memo(Documents);
