import { memo, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSessionActions } from 'redux/actions/session.action';
import { AppState } from 'redux/reducers';
import PersonsSearchComponent from './component';

interface IProps {
    placeholder?: string;
    onChange?: (person?: IPerson) => void;
    clearPerson?: () => void;
    value?: IPerson;
}

const PersonsSearch = (props: IProps): ReactElement | null => {
    const [isSearching, setIsSearching] = useState(false);
    const { getPersons } = useSessionActions();

    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const { session } = mapStateToObj;

    const onSearchPersons = async (search: string): Promise<IPerson[] | undefined> => {
        if (!search || search.trimEnd().length < 3) {
            setIsSearching(false);
            return;
        }
        try {
            setIsSearching(true);
            return (await getPersons({
                search,
            }).then((persons: IPerson[]) => {
                setIsSearching(false);
                return persons;
            }).catch((err: Error) => {
                throw err;
            }));
        }
        catch (err: any) {
            setIsSearching(false);
            console.log(err);
        }
    };

    const onSelectPerson = async (person?: IPerson) => {
        try {
            if (!person) {
                return props.onChange?.(undefined);
            }
            else {
                props.onChange?.(person);
            }
        }
        catch (err: any) {
            console.log(err);
        }
    };

    const clearPerson = () => {
        props.clearPerson?.();
    };

    return session.user ? <PersonsSearchComponent
        getPersons={onSearchPersons}
        selectPerson={onSelectPerson}
        onClearPerson={clearPerson}
        isSearching={isSearching}
        placeholder={props.placeholder}
        value={props.value}
    /> : null;
};

export default memo(PersonsSearch);
