import {Table, TablePaginationConfig} from 'antd';
import {memo, ReactElement, useEffect, useState} from 'react';
import {useSessionActions} from '../../redux/actions/session.action';
import {getColumns} from './columns';
import './index.less';

const Limit = 12;


const TableComponent = (): ReactElement => {
    const { getUserInvoices } = useSessionActions();
    const [invoices, setInvoices] = useState<IUserInvoice[]>([]);
    const [pagination, setPagination] = useState<IPagination>();

    const searchInvoices = (page?: number, pageSize?: number) => {
        const limit = pageSize ?? Limit;
        getUserInvoices({ limit, offset: ((page ?? 1) - 1) * limit})
            .then((data: IPaginatedResults<IUserInvoice>) => {
                const { results, count } = data;
                setInvoices(results);
                setPagination({
                    current: page,
                    pageSize: pageSize ?? Limit,
                    defaultPageSize: Limit,
                    total: count ?? 0,
                    pageSizeOptions: [Limit, Limit * 2, Limit * 5, Limit * 10],
                });
            })
            .catch((err: Error) => {
                console.error(err.message);
            });
    };

    const changePagination = (paginationIn: TablePaginationConfig) => {
        const { current, pageSize } = paginationIn;
        searchInvoices(current, pageSize);
    };

    const onChangePagination = (pagination: TablePaginationConfig) => {
        changePagination(pagination);
    };

    useEffect(() => {
        searchInvoices(1);
    }, []);

    return <Table
        columns={getColumns()}
        dataSource={invoices.map((invoice) => { return { key: invoice.id, ...invoice }; })}
        pagination={pagination}
        onChange={onChangePagination}
    />;
};

export default memo(TableComponent);
