import { Button, Card, Empty } from 'antd';
import { memo, ReactElement } from 'react';
import './index.less';

interface IProps {
    goToRequest: () => void;
}

const component = (props: IProps): ReactElement => {
    const onGoToRequest = () => {
        props.goToRequest();
    };

    return <Card className="expertises-empty" bordered={false} title="Expertises">
        <div className="empty-content">
            <Empty description="Aucune expertise" />
            <Button type="primary" onClick={onGoToRequest}>Demander un avis d&apos;expertise</Button>
        </div>
    </Card>;
};

export const ExpertiseEmptyComponent = memo(component);
