export enum Type {
    INIT_SESSION,
    RESET_SESSION,
    SET_REDIRECT,
    SET_CONSTANTS = 'SET_CONSTANTS',
}

export interface IInitSessionAction {
    payload: Partial<ISession>;
    type: Type.INIT_SESSION;
}

export interface IResetSessionAction {
    type: Type.RESET_SESSION;
}

export interface ISetRedirectAction {
    type: Type.SET_REDIRECT;
    payload: string | undefined;
}

export interface ISetConstants {
    type: Type.SET_CONSTANTS;
    payload: IConstants;
}

export type ActionTypes = IInitSessionAction | IResetSessionAction | ISetRedirectAction | ISetConstants;

