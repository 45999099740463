import { Modal } from 'antd';
import { memo, ReactElement, useState } from 'react';
import { useDocumentActions } from 'redux/actions/document.action';
import { renderStandaloneElement, useMessage } from 'services/helpers';
import './index.less';
import Viewer from './viewer';

interface IProps {
    document: IUploadedDocument;
    onHide?: () => void;
    expertise?: IExpertise;
    downloadDocument?: () => void;
}

const VisuModal = (props: IProps): ReactElement => {
    const { getDocument } = useDocumentActions();
    const [message] = useMessage();
    const [isShown, setShown] = useState<boolean>(true);

    const hide = () => {
        setShown(false);
        props.onHide?.();
    };

    const render = (): ReactElement => {
        return <Viewer
            message={message}
            document={props.document}
            getDocument={() => getDocument(props.document.id)}
        />;
    };

    return <Modal
        className="visu-modal"
        title="Document"
        visible={isShown}
        onCancel={hide}
        width="60rem"
        centered>
        {render()}
    </Modal>;
};

export function renderDocumentVisuModal(props: IProps): void {
    renderStandaloneElement(VisuModal, props);
}

export default memo(VisuModal);
