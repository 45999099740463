import { BulbOutlined, EnvironmentOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Input } from 'antd';
import pages from 'pages';
import React, { memo, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import './index.less';

const { Meta } = Card;

const ExpertsComponent = (): ReactElement => {
    const users = [
        {
            civility: 'Pr',
            firstName: 'Christophe',
            lastName: 'Tartempion',
            professionLabel: 'Radiologue',
            addresses: [
                {
                    city: 'Strasbourg',
                },
            ],
        },
        {
            civility: 'Dr',
            firstName: 'Jean',
            lastName: 'Neymar',
            professionLabel: 'Généraliste',
            addresses: [
                {
                    city: 'Haguenau',
                },
            ],
        },
        {
            civility: 'Pr',
            firstName: 'Sylvie',
            lastName: 'Ilépamor',
            professionLabel: 'Dentiste',
            addresses: [
                {
                    city: 'Strasbourg',
                },
            ],
        },
    ] as IUser[];

    const renderMeta = (user: IUser): ReactElement => {
        const { professionLabel, addresses, civility, firstName, lastName } = user;
        return <Meta title={`${civility} ${firstName} ${lastName}`} description={
            <div className="meta">
                <div>
                    <BulbOutlined />{professionLabel}
                </div>
                <div>
                    <EnvironmentOutlined />{addresses && addresses.length > 0 ? addresses[0].city : undefined}
                </div>
            </div>
        } />;
    };

    const renderUsers = (usersIn: IUser[]): ReactElement[] => {
        return usersIn.map((user: IUser, index: number) => <Card key={'p' + index}>
            {renderMeta(user)}
            <Button className="action" type="primary" size="small" danger>
                <Link to={pages.expertiseRequest.path}>Demander un avis</Link>
            </Button>
        </Card>);
    };

    return <Card className="experts" bordered={false} title="Experts">
        <Input prefix={<SearchOutlined />} className="search" placeholder="Recherche..." allowClear />
        <div className="users">
            {renderUsers(users)}
        </div>
    </Card>;
};

export default memo(ExpertsComponent);
