const en: Record<string, string> = {
    'Connection refused': 'Connection refused',
    'Unauthorized': 'Unauthorized',
    'Invalid code': 'Invalid code',
    'Another request has been made in the last minute': 'Another request has been made in the last minute',
    'No authentication method available': 'No authentication method available',
    'Not Found': 'Not Found',
    'User can not access document': 'User can not access document',
    'Document not found': 'Document not found',
    'Group not found': 'Group not found',
    'You have no access to this document': 'You have no access to this document',
    'Group already have access to document': 'Group already have access to document',
    'User not in group': 'User not in group',
    'Expertise document not found': 'Expertise document not found',
    'The user is not activated': 'The user is not activated',
    'Expertise not found': 'Expertise not found',
    'You do not have access to this expertise': 'You do not have access to this expertise',
    'Recipient not found': 'Recipient not found',
    'Patient not found': 'Patient not found',
    'Expertise already closed': 'Expertise already closed',
    'You are not the requester': 'You are not the requester',
    'Expertise has no patient. A patient is required to close an expertise': 'Expertise has no patient. A patient is required to close an expertise',
    'Patient info is not complete. Please complete it before closing an expertise. First name, last name, birth date and INS are required': 'Patient info is not complete. Please complete it before closing an expertise. First name, last name, birth date and INS are required',
    'Replier not found': 'Replier not found',
    'Replier is not a member of the group': 'Replier is not a member of the group',
    'Message not found': 'Message not found',
    'Message already seen': 'Message already seen',
    'You are not the document owner': 'You are not the document owner',
    'You are not allowed to access this document': 'You are not allowed to access this document',
    'Expertise data not found': 'Expertise data not found',
    'Sender is not a user': 'Sender is not a user',
    'You are not part of this group': 'You are not part of this group',
    'Person not found': 'Person not found',
    'Invite not found': 'Invite not found',
    'You are not the recipient of this invite': 'You are not the recipient of this invite',
    'Invite does not exist': 'Invite does not exist',
    'You are not the sender of this invite': 'You are not the sender of this invite',
    'Invite already responded': 'Invite already responded',
    'Requester not found': 'Requester not found',
    'Requester is not a user': 'Requester is not a user',
    'You are already part of this group': 'You are already part of this group',
    'Request not found': 'Request not found',
    'You are not part of this group.': 'You are not part of this group.',
    'City is required': 'City is required',
    'Group name is required': 'Group name is required',
    'Member not found': 'Member not found',
    'Patient is not linked to an expertise': 'Patient is not linked to an expertise',
    'You are not the requester of the expertise linked to the patient': 'You are not the requester of the expertise linked to the patient',
    'Patient didn\'t update': 'Patient didn\'t update',
    'Search must be at least 3 characters long': 'Search must be at least 3 characters long',
    'No one found with this search': 'No one found with this search',
    'User not verified yet': 'User not verified yet',
    'User not found': 'User not found',
    'This email has not been verified yet.': 'This email has not been verified yet.',
    'User already exists': 'User already exists',
    'status must be a string': 'Status must be a string',
    'Forbidden resource': 'Acces denied',
};

export default en;
