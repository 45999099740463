import { CheckOutlined, CloseOutlined, IdcardOutlined } from '@ant-design/icons';
import { Button, Card, Input, Table, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { memo, ReactElement, useEffect, useState } from 'react';
import { renderMessage } from 'services/helpers';
import { useDebouncedCallback } from 'use-debounce';
import './index.less';

interface IProps {
    users: IUser[];
    message?: IMessage;
    activateUser: (user: IUser) => void;
    deactivateUser: (user: IUser) => void;
    showCPSCard: (document: IDocument) => void;
    changePagination: (pageination: TablePaginationConfig) => void;
    search: (search?: string) => Promise<void>;
    pagination?: IPagination;
}

const AccountsAdministrationComponent = (props: IProps): ReactElement | null => {
    const [filteredData, setFilteredData] = useState<IUser[]>();

    const columns: ColumnsType<IUser> = [
        {
            title: 'Crée le',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '12rem',
            render: (text: string) => <span>{new Date(text).toLocaleString()}</span>,
        },
        {
            title: 'RPPS',
            dataIndex: 'rpps',
            key: 'rpps',
        },
        {
            title: 'Nom',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Prénom',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '17rem',
        },
        {
            title: 'Statut',
            dataIndex: 'status',
            key: 'status',
            width: '9rem',
            filters: [
                {
                    text: 'Actif',
                    value: 'ACTIVE',
                },
                {
                    text: 'Inactif',
                    value: 'INACTIVE',
                },
                {
                    text: 'Vérifié',
                    value: 'VERIFIED',
                },
                {
                    text: 'Non vérifié',
                    value: 'UNVERIFIED',
                },
            ],
            filterMode: 'tree',
            onFilter: (value, record: IUser) => (
                record.status === value
            ),
        },
        {
            title: 'Dernière connexion',
            dataIndex: 'lastConnection',
            key: 'lastConnection',
            width: '12rem',
            render: (text: string) => text === null ? null : <span>{new Date(text).toLocaleString()}</span>,
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '6rem',
            render: (user: IUser) => (
                <div className='actions'>
                    <Tooltip title="Voir la carte CPS">
                        <Button
                            key="cps"
                            type="primary"
                            onClick={() => onShowCPSCard(user.idCard)}
                            disabled={!user.idCard}
                            icon={<IdcardOutlined />}
                        />
                    </Tooltip>

                    {user.status !== 'ACTIVE' ?
                        <Tooltip title="Activer">
                            <Button
                                className="action-button"
                                type="primary"
                                onClick={() => onActivateUser(user)}
                                icon={<CheckOutlined />}
                            />
                        </Tooltip>
                        :
                        <Tooltip title="Désactiver">
                            <Button
                                className="action-button"
                                type="primary"
                                onClick={() => onDeactivateUser(user)}
                                danger
                                icon={<CloseOutlined />}
                            />
                        </Tooltip>
                    }
                </div>
            ),

        },
    ];

    const onActivateUser = (user: IUser) => {
        props.activateUser(user);
    };

    const onDeactivateUser = (user: IUser) => {
        props.deactivateUser(user);
    };

    const onShowCPSCard = (document?: IDocument) => {
        if (document) {
            props.showCPSCard(document);
        }
    };


    const onChangePagination = (pagination: TablePaginationConfig) => {
        props.changePagination(pagination);
    };

    const debounced = useDebouncedCallback(
        async (value?: string) => {
            await props.search(value);
        }, 400,
    );

    const handleSearch = async (value?: string) => {
        debounced(value)?.catch((err: Error) => {
            console.log(err);
        });
    };

    useEffect(() => {
        setFilteredData(props.users);
    }, [props.users]);

    return <Card title='Gestion des comptes' className='accounts-administration' >
        <Input
            placeholder="Rechercher un utilisateur"
            onChange={(e) => handleSearch(e.target.value)}
        />
        {renderMessage(props.message)}
        <Table
            columns={columns}
            dataSource={filteredData}
            pagination={props.pagination}
            onChange={onChangePagination}
        />
    </Card>;
};

export default memo(AccountsAdministrationComponent);
