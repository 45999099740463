import { Divider, Form, Input } from 'antd';
import { memo, ReactElement, useEffect, useState } from 'react';
import './index.less';
import DocumentVisu from './visu';

interface IProps {
    message?: IMessage;
    document?: IDocument;
    file?: File;
    update: (document: IDocument) => void;
}

const DocumentComponent = (props: IProps): ReactElement => {
    const [form] = Form.useForm<IDocument>();
    const [formValues, setFormValues] = useState<IDocument>();

    const onUpdateMeta = () => {
        setFormValues(form.getFieldsValue());
    };

    useEffect(() => {
        props.update(form.getFieldsValue());
    }, [formValues]);

    useEffect(() => {
        if (props.document) {
            form.setFieldsValue(props.document);
        }
    }, [props.document]);

    return <div className="expertise-document">
        <Form
            className="infos"
            form={form}
            labelCol={{ span: 2 }}
            onFieldsChange={onUpdateMeta}
            initialValues={{
                name: props.document?.name,
            }}
        >
            <Divider plain>Document</Divider>

            <Form.Item name="name" label="Titre">
                <Input maxLength={255} />
            </Form.Item>
        </Form>
        <DocumentVisu file={props.file} />
    </div>;
};

export default memo(DocumentComponent);
