import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducers';
import Api from 'services/api';
import { useApiErrorHandling } from 'services/helpers';
import { useSessionActions } from './session.action';

// eslint-disable-next-line
export function useGroupActions() {
    const { refreshSession } = useSessionActions();
    const errorHandling = useApiErrorHandling(refreshSession);
    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const { session } = mapStateToObj;

    const getGroups = async (payload?: ISearchParams): Promise<IGroup[]> => {
        return Api.getGroups({
            ...payload,
            ...{
                visibility: payload?.visibility ?? 'OWN',
            },
        }, session).catch(errorHandling);
    };

    return {
        createGroup: async (group: IGroupParams): Promise<IGroup> => {
            return Api.createGroup(group, session).catch(errorHandling);
        },
        updateGroup: async (group: IGroupParams): Promise<void> => {
            return Api.updateGroup(group, session).catch(errorHandling);
        },
        leaveGroup: async (groupId: IGroup['id']): Promise<void> => {
            return Api.leaveGroup(groupId, session).catch(errorHandling);
        },
        removeUserFromGroup: async (userId: IUser['id'], groupId: IGroup['id']): Promise<void> => {
            return Api.removeUserFromGroup(userId, groupId, session).catch(errorHandling);
        },
        getGroup: async (groupId: IGroup['id']): Promise<IGroup> => {
            return Api.getGroup(groupId, session).catch(errorHandling);
        },
        getGroups,
        deleteGroupRequest: async (groupRequest: IGroupRequestParams): Promise<void> => {
            return Api.deleteGroupRequest(groupRequest, session).catch(errorHandling);
        },
        getGroupRequests: async (): Promise<IGroupRequestGroup[]> => {
            return Promise.all((await getGroups()).map(async (group: IGroup) => {
                const { name, id } = group;
                return {
                    name: name || '',
                    id,
                    requests: await Api.getGroupRequests(group.id, session).catch(errorHandling),
                };
            }));
        },
        invitePersonsInGroup: async (persons: IPerson[], groupId: IGroup['id']): Promise<void[]> => {
            const promises = [];
            for (const person of persons) {
                promises.push(Api.invitePersonInGroup(person.id, groupId, session).catch(errorHandling));
            }

            return Promise.all(promises).catch(errorHandling);
        },
        declineGroupInvitation: async (groupInvitation: IGroupInvitationParams): Promise<void> => {
            return Api.declineGroupInvitation(groupInvitation, session).catch(errorHandling);
        },
        acceptGroupInvitation: async (groupInvitation: IGroupInvitationParams): Promise<void> => {
            return Api.acceptGroupInvitation(groupInvitation, session).catch(errorHandling);
        },
        getGroupInvitations: async (): Promise<IGroupInvitation[]> => {
            return Api.getGroupInvitations(session).catch(errorHandling);
        },
    };
}
