import { memo, ReactElement, useEffect, useState } from 'react';
import { useMessage } from 'services/helpers';
import DocumentVisuComponent from './component';

const DisplayableMimeTypes = [
    'application/pdf',
    'text/plain',
    'text/html',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'audio/wave',
    'audio/wav',
    'audio/x-wav',
    'audio/x-pn-wav',
    'audio/webm',
    'video/webm',
    'audio/ogg',
    'video/ogg',
    'application/ogg',
];

interface IProps {
    file?: File;
    expertise?: IExpertise;
}

const DocumentVisu = (props: IProps): ReactElement => {
    const [file, setFile] = useState<File>();
    const [message, setMessage] = useMessage();

    const onForceDisplay = async () => {
        if (props.file) {
            setMessage(undefined);
            setFile(props.file);
        }
    };

    useEffect(() => {
        (async () => {
            setMessage(undefined);
            const fileIn = props.file;
            if (fileIn) {
                setFile(undefined);

                if (DisplayableMimeTypes.includes(fileIn.type)) {
                    setFile(fileIn);
                }
            }
        })().catch((err: Error) => {
            setMessage(err);
        });
    }, [props.file]);

    return <DocumentVisuComponent
        file={file}
        message={message}
        forceDisplay={onForceDisplay}
    />;
};

export default memo(DocumentVisu);
