import axios, { AxiosResponse } from 'axios';

export class DevBoxVitale {
    public async isAvailable(): Promise<boolean> {
        const response = await axios.get<never, AxiosResponse<unknown>>(DevBoxVitale.getRootUrl());
        return response.status === 200;
    }

    public async readVitale(): Promise<IVitale> {
        const response = await axios.get<never, AxiosResponse<IVitale>>(`${DevBoxVitale.getRootUrl()}/vitale/readVitale`);
        return response.data;
    }

    private static readonly protocol: string = 'http';
    private static readonly host: string = 'localhost';
    private static readonly port: number = 10024;

    private static getRootUrl(): string {
        return `${DevBoxVitale.protocol}://${DevBoxVitale.host}:${DevBoxVitale.port}`;
    }
}

export interface IVitaleBeneficiary {
    'ident': {
        'nomUsuel': string,
        'nomPatronymique': string,
        'prenomUsuel': string,
        'naissance': {
            'date': string,
            'dateEnCarte': string;
        },
        'nir': string,
        'adresse': {
            'ligne1': string,
            'ligne2': string,
            'ligne3': string,
            'ligne4': string,
            'ligne5': string;
        },
        'rangDeNaissance': string,
        'nirCertifie': string,
        'dateCertification': string,
        'dateNaissance': string;
    },
    'amo': {
        'qualBenef': string,
        'qualBenefLibelle': string,
        'codeRegime': string,
        'codeRegimeLibelle': string,
        'caisse': string,
        'caisseLibelle': string,
        'centreGestion': string,
        'codeGestion': string,
        'codeGestionLibelle': string,
        'exoFamille': {
            'codeService': string,
            'codeServiceLibelle': string,
            'periodeService': {
                'debut': string,
                'periodeLibelle': string,
                'fin': string;
            };
        },
        'exoIndividuelle1': {
            'codeService': string,
            'codeServiceLibelle': string,
            'periodeService': {
                'debut': string,
                'periodeLibelle': string,
                'fin': string;
            };
        },
        'exoIndividuelle2': {
            'codeService': string,
            'codeServiceLibelle': string,
            'periodeService': {
                'debut': string,
                'periodeLibelle': string,
                'fin': string;
            };
        },
        'libelleExo': string,
        'infoCompl': string,
        'infoComplLibelle': string,
        'centreCarte': string,
        'centreCarteLibelle': string,
        'listePeriodesDroits': [
            {
                'debut': string,
                'periodeLibelle': string,
                'fin': string;
            }
        ],
        'service': {
            'codeService': string,
            'codeServiceLibelle': string,
            'periodeService': {
                'debut': string,
                'periodeLibelle': string,
                'fin': string;
            };
        },
        'medecinTraitant': string,
        'nir': string,
        'codeCaisse': string,
        'codeCaisseLibelle': string,
        'codeCentre': string,
        'codeCentreLibelle': string;
    },
    'mutuelle': {
        'listeTypeContrat': [
            string
        ],
        'numIdent': string,
        'services': {
            'typeService': string,
            'typeServiceLibelle': string,
            'servicesAssocies': string;
        },
        'listePeriodes': [
            {
                'debut': string,
                'periodeLibelle': string,
                'fin': string;
            }
        ],
        'indicTraitement': string,
        'indicTraitementLibelle': string,
        'codeSTS': string,
        'codeSTSLibelle': string,
        'donneesCompl': string;
    },
    'amc': {
        'numComplB2': string,
        'numComplEDI': string,
        'numAdherent': string,
        'indicTraitement': string,
        'indicTraitementLibelle': string,
        'validiteDonnees': {
            'debut': string,
            'periodeLibelle': string,
            'fin': string;
        },
        'codeRoutageFlux': string,
        'codeRoutageFluxLibelle': string,
        'identHote': string,
        'nomDomaine': string,
        'codeSTS': string,
        'codeSTSLibelle': string,
        'services': {
            'typeService': string,
            'typeServiceLibelle': string,
            'servicesAssocies': string;
        },
        'donneesCompl': string;
    },
    'cmu': {
        'typeCMU': string,
        'typeCMULibelle': string,
        'periode': {
            'debut': string,
            'periodeLibelle': string,
            'fin': string;
        };
    },
    'listeAt': {
        'at1': {
            'orgGestion': string,
            'codeBudget': string,
            'identifiant': string;
        },
        'at2': {
            'orgGestion': string,
            'codeBudget': string,
            'identifiant': string;
        },
        'at3': {
            'orgGestion': string,
            'codeBudget': string,
            'identifiant': string;
        };
    },
    'e112': {
        'formulaire': string,
        'typeExp': string,
        'article': string,
        'activite': string,
        'activiteLibelle': string,
        'date': string,
        'validite': {
            'debut': string,
            'periodeLibelle': string,
            'fin': string;
        };
    };
}

export interface IVitale {
    'codeWarnings': [
        'API_NON_ERR'
    ],
    'error': {
        'codeErreur': 'API_NON_ERR',
        'message': string,
        'trace': [
            {
                'classLoaderName': string,
                'moduleName': string,
                'moduleVersion': string,
                'methodName': string,
                'fileName': string,
                'lineNumber': 0,
                'className': string,
                'nativeMethod': true;
            }
        ];
    },
    'status': 'OK',
    cardVitale?: {
        'data': {
            'listeBenef': IVitaleBeneficiary[],
            'e112': {
                'formulaire': string,
                'typeExp': string,
                'article': string,
                'activite': string,
                'activiteLibelle': string,
                'date': string,
                'validite': {
                    'debut': string,
                    'periodeLibelle': string,
                    'fin': string;
                };
            },
            'tech': {
                'finValidite': string,
                'numSerie': string,
                'accesCompl': string,
                'carteDeTest': true;
            };
        };
    },
    'beneficiaireSelected': 0,
    'cardCps': {
        'codeCivilite': string,
        'codeCiviliteLibelle': string,
        'nomPatronymique': string,
        'etatsCarte': [
            'CPS_ABSENTE'
        ],
        'typeCarte': 'VITALE',
        'nbCodeIncorrect': 0,
        'finValidite': string;
    },
    'cardReaders': [
        {
            'name': string,
            'cardPresent': true,
            'readerType': 'PAS_DE_CARTE',
            'modeAcces': 'Full_PCSC',
            'numLecteur': 0;
        }
    ];
}
