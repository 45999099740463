import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducers';
import Api from '../../services/api';
import { useApiErrorHandling } from '../../services/helpers';
import { useSessionActions } from './session.action';

// eslint-disable-next-line
export function usePatientActions() {
    const { refreshSession } = useSessionActions();
    const errorHandling = useApiErrorHandling(refreshSession);
    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const { session } = mapStateToObj;

    const formatPatient = (patient: IPatientParams): IPatientParams => {
        if (patient.birthDate) patient.birthDate = moment(patient.birthDate).format('YYYY-MM-DD') as any;

        return patient;
    };

    return {
        createPatient: async (patient: IPatientParams): Promise<IPatient> => {
            return Api.createPatient(formatPatient(patient), session).catch(errorHandling);
        },
        createPatients: async (patients: IPatientParams[]): Promise<void> => {
            return Api.createPatients(patients.map(formatPatient), session).catch(errorHandling);
        },
        updatePatient: async (patientId: IPatient['id'], patient: IPatientParams): Promise<IPatient> => {
            return Api.updatePatient(patientId, formatPatient(patient), session).catch(errorHandling);
        },
        searchPatients: async (payload: ISearchPatientParams): Promise<IPaginatedResults<IPatient>> => {
            return Api.getPatients(payload, session).catch(errorHandling);
        },
    };
}
