import { Button, Form, Input } from 'antd';
import React, { memo, ReactElement } from 'react';
import HomeComponent from 'components/home/component';
import { renderMessage } from 'services/helpers';
import './index.less';

interface IProps {
    message?: IMessage;
    savePassword: (credentials: IPasswordResetParams) => void;
}

const ResetComponent = (props: IProps): ReactElement => {
    const [form] = Form.useForm<IPasswordResetParams>();

    const onSavePassword = (values: IPasswordResetParams) => {
        props.savePassword(values);
    };

    return <HomeComponent className="password-reset">
        <Form
            form={form}
            onFinish={onSavePassword}
        >
            {renderMessage(props.message)}

            <p>Rentrez votre nouveau mot de passe</p>

            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Vous devez saisir un mot de passe' }]}
            >
                <Input.Password placeholder="Mot de passe" />
            </Form.Item>

            <Form.Item
                name="passwordConfirmation"
                rules={[{ required: true, message: 'Vous devez confirmer votre mot de passe' }]}
            >
                <Input.Password placeholder="Confirmation du mot de passe" />
            </Form.Item>

            <Form.Item className="actions">
                <Button type="primary" htmlType="submit">Sauver</Button>
            </Form.Item>
        </Form>
    </HomeComponent>;
};

export default memo(ResetComponent);
