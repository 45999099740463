import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from 'redux/reducers';


const middlewareEnhancer = applyMiddleware(thunk);

const composeWithDevTools = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;
const composedEnhancers = composeWithDevTools(middlewareEnhancer);

export default createStore(rootReducer, composedEnhancers);
