import { Button } from 'antd';
import { memo, ReactElement, useEffect, useState } from 'react';
import { isUploadedDocument } from '../../services/helpers';
import DocumentComponent from './component';

interface IProps {
    message?: IMessage;
    document: IDocument;
    update?: (document: IDocument, persons: IPerson[]) => void;
}

const Document = (props: IProps): ReactElement => {
    const [document, setDocument] = useState<IDocument>(props.document);

    const onUpdate = (documentIn: IDocument, persons: IPerson[]) => {
        if (props.document) {
            props.update?.({
                ...props.document,
                ...documentIn,
            }, persons);
        }
    };

    useEffect(() => {
        setDocument(props.document);
    }, [props.document]);

    const { message } = props;
    if (!isUploadedDocument(document)) return <></>;
    return <DocumentComponent
        message={message}
        document={document}
        update={onUpdate}
    />;
};

interface IUseDocumentActions {
    renderDocumentActions: () => ReactElement[];
    setCanShareDocument: (canShareDocument: boolean) => void;
}

export function useDocumentActions(onSave: () => void, onDownload: () => void, onDelete: () => void, onShare: () => void): IUseDocumentActions {
    const [canShareDocument, setCanShareDocument] = useState<boolean>(false);
    const renderDocumentActions = (): ReactElement[] => [
        <Button key="delete" type="primary" danger onClick={onDelete}>Supprimer</Button>,
        <Button key="download" onClick={onDownload}>Télécharger</Button>,
        <Button key="expertise" disabled>Expertise</Button>,
        <Button key="share" disabled={!canShareDocument} onClick={onShare} type="primary">Partager</Button>,
        <Button key="save" type="primary" onClick={onSave}>Sauver</Button>,
    ];

    return {
        renderDocumentActions,
        setCanShareDocument,
    };
}

export default memo(Document);
