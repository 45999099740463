import { Button, Card, Form, Input, notification } from 'antd';
import { useUsersActions } from 'redux/actions/users.action';
import { useEffect, useState } from 'react';
import './index.less';

const BillingAddressComponent = () => {
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm<IBillingAddress>();
    const [address, setAddress] = useState<IBillingAddress | null>(null);
    const { getBillingAddress, updateBillingAddress } = useUsersActions();
    const [loading, setLoading] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    useEffect(() => {
        getBillingAddress()
            .then(setAddress)
            .catch(console.error)
            .finally(() => {
                setFirstLoad(false);
            })
        ;
    }, []);

    if (firstLoad) {
        return null;
    }

    const  onSave = async (values: IBillingAddress) => {
        try {
            setLoading(true);

            await updateBillingAddress(values);

            api.success({
                message: 'Adresse de facturation mise à jour avec succès',
                duration: 8,
            });

        } catch (error) {
            api.error({
                message: error as string,
                duration: 8,
            });
        }

        setLoading(false);
    };

    return <Card className="billingAddress" bordered={false} title="Adresse de facturation">
        {contextHolder}
        <Form
            form={form}
            layout="horizontal"
            labelCol={{ xs: 8, xl: 5 }}
            wrapperCol={{ xs: 16, xl: 18 }}
            onFinish={onSave}
            initialValues={address ?? {
                line1: '',
                line2: null,
                postal_code: '',
                city: '',
            }}
            disabled={loading}
        >
            <Form.Item name="line1" label="Adresse" rules={[{ required: true, message: 'Ce champ est obligatoire'}]}>
                <Input maxLength={100}/>
            </Form.Item>
            <Form.Item name="line2" label="Suite de l'adresse">
                <Input maxLength={100}/>
            </Form.Item>
            <Form.Item name="postal_code" label="Code postal" rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="city" label="Ville" rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                <Input/>
            </Form.Item>
            <Form.Item className="actions">
                <Button loading={loading} type="primary" htmlType="submit">Valider</Button>
            </Form.Item>
        </Form>
    </Card>;
};

export default BillingAddressComponent;
