import pages from 'pages';
import { memo, ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSessionActions } from 'redux/actions/session.action';
import { useMessage } from 'services/helpers';
import PscComponent from './component';

const Psc = (): ReactElement => {
    const navigate = useNavigate();
    const { pscToken } = useParams();
    const [message, setMessage] = useMessage();
    const { proSanteConnectLogin, getRedirectUrl } = useSessionActions();

    useEffect(() => {
        (async () => {
            if (pscToken) {
                await proSanteConnectLogin(pscToken);
                navigate(getRedirectUrl() ?? pages.expertisesSent.path);
            }
        })().catch((err: Error) => {
            setMessage(err);
        });
    }, [pscToken]);

    return <PscComponent message={message} />;
};

export default memo(Psc);
