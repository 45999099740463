import { FileImageOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Form, Input, Upload } from 'antd';
import pscImage from 'assets/images/psc.png';
import RppsSearch from 'components/search/rpps';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { memo, ReactElement, useEffect, useState } from 'react';
import { renderMessage, useDocumentUpload } from 'services/helpers';
import { ICodes, Step } from '.';
import './index.less';

const { Dragger } = Upload;

interface IProps {
    message?: IMessage;
    user?: IUser;
    users?: IUser[];
    step: Step;
    cpsPhoto?: IDocumentMeta;
    pscEnabled?: boolean;
    uploadPhoto: (document: IDocument) => void;
    fillInformation: (information: IRegisterParams) => void;
    validateCodes: (codes: ICodes) => void;
    searchRpps: (user: IUser) => void;
    complete: () => void;
    goPreviousStep: () => void;
}

const getBase64 = (img: Blob, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

const RegisterComponent = (props: IProps): ReactElement | null => {
    const [informationFillingForm] = Form.useForm<IRegisterParams>();
    const [validationCodesform] = Form.useForm<ICodes>();
    const [rppsSearchForm] = Form.useForm<IUser>();
    const [user, setUser] = useState<IUser>();
    const uploadDocument = useDocumentUpload();
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {
        switch (props.step) {
            case Step.RppsSearch:
                rppsSearchForm.setFieldsValue({
                    rpps: undefined,
                });
                break;
        }
    }, [props.step]);

    useEffect(() => {
        if (props.cpsPhoto)
            getBase64(props.cpsPhoto?.content, url => {
                setImageUrl(url);
            });
    }, [props.cpsPhoto]);

    const onFillInformation = (credentials: IRegisterParams) => {
        props.fillInformation(credentials);
    };

    const onValidateCodes = (codes: ICodes) => {
        props.validateCodes(codes);
    };

    const onSelectUser = (userIn?: IUser) => {
        console.log(userIn);
        if (userIn) {
            setUser(userIn);
            rppsSearchForm.setFieldsValue(userIn);
        }
    };

    const onSearchRpps = async () => {
        if (user) {
            props.searchRpps(user);
        }
    };

    const onComplete = () => {
        props.complete();
    };

    const onUploadPhoto = async (uploadRequestOptions: UploadRequestOption) => {
        setImageUrl(undefined);
        await uploadDocument({
            uploadRequestOptions,
            onAfterUpload: (documentIn: IDocument) => {
                onAfterUpload(documentIn);
            },
        }).catch((err: Error) => {
            throw err;
        });
    };

    const onAfterUpload = (documentIn: IDocument) => {
        props.uploadPhoto(documentIn);
    };

    const goPreviousStep = () => {
        props.goPreviousStep();
    };

    const renderInformationFillingForm = (): ReactElement => {
        const { email } = props.user ?? {};

        return <Form
            form={informationFillingForm}
            onFinish={onFillInformation}
            initialValues={{
                email,
            }}
            className="register information"
        >
            {renderMessage(props.message)}
            <p>
                Veuillez entrer votre email et choisir un mot de passe.
            </p>
            <Form.Item
                name="email"
                rules={[{ required: true, message: 'Vous devez saisir une adresse mail' }]}
            >
                <Input placeholder="Email" prefix={<UserOutlined />} autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Vous devez saisir un mot de passe' }]}
            >
                <Input.Password placeholder="Mot de passe" prefix={<LockOutlined />} autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="passwordConfirmation"
                rules={[{ required: true, message: 'Vous devez confirmer votre mot de passe' }]}
            >
                <Input.Password placeholder="Confirmation" prefix={<LockOutlined />} autoComplete="off" />
            </Form.Item>

            <Form.Item className="actions">
                <Button onClick={goPreviousStep}>Retour</Button>
                <Button type="primary" htmlType="submit">Continuer</Button>
            </Form.Item>
        </Form>;
    };

    const renderCodesValidationForm = (): ReactElement => {
        const { email } = props.user ?? {};

        return <Form
            form={validationCodesform}
            onFinish={onValidateCodes}
            className="register validation"
        >
            {renderMessage(props.message)}
            <Alert type="info" message={<>
                Nous venons d&apos;envoyer des codes de validation à <b>{email}</b><br />
                Veuiller les saisir ci-dessous
            </>} />

            <Form.Item
                name="emailCode"
                rules={[{ required: true, message: 'Vous devez saisir le code envoyé sur votre adresse mail' }]}
            >
                <Input placeholder="Code email" />
            </Form.Item>

            <Form.Item className="actions">
                <Button onClick={goPreviousStep}>Retour</Button>
                <Button type="primary" htmlType="submit">Continuer</Button>
            </Form.Item>
        </Form>;
    };

    const renderRppsSearchForm = (): ReactElement => {
        return <Form
            form={rppsSearchForm}
            onFinish={onSearchRpps}
            layout="vertical"
            className="register"
        >
            {renderMessage(props.message)}
            <Form.Item
                name="rpps"
                label="Recherche dans l'annuaire RPPS"
                rules={[{ required: true, message: 'Vous devez rechercher un professionnel de santé' }]}>
                <RppsSearch selectUser={onSelectUser} />
            </Form.Item>

            <Form.Item className="actions">
                <Button type="primary" htmlType="submit">Continuer</Button>
            </Form.Item>

            {props.pscEnabled ? <>
                <Divider className="connection-separator" plain>ou</Divider>

                <Form.Item>
                    <a href="/api/pro-sante-connect/login">
                        <img className="psc-action" src={pscImage} />
                    </a>
                </Form.Item>
            </> : undefined}
        </Form>;
    };

    const renderCpsUploadForm = (): ReactElement => {
        return <Form
            onFinish={onComplete}
            layout="vertical"
            className="register"
        >
            {renderMessage(props.message)}
            {!props.cpsPhoto ? <Alert type="info" message={<>
                Vous pouvez importer une photo de votre carte CPS.<br />
                Cette étape est optionnelle pour la création de votre compte, mais est requise pour son activation.
            </>} /> : null}
            <Form.Item className="cps" valuePropName="fileList[]">
                <Dragger
                    accept='image/*,.pdf'
                    className="upload"
                    showUploadList={false}
                    customRequest={onUploadPhoto}
                >
                    <p className="ant-upload-drag-icon">
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : <FileImageOutlined />}
                    </p>
                    {!props.cpsPhoto ? <><p className="ant-upload-text">Importer une photo <br /> (gif / jpeg / png / svg)</p>
                        <p className="ant-upload-hint">Par glisser/déposer ou en cliquant dans la zone</p></> : null}
                </Dragger>
            </Form.Item>

            <Form.Item className="actions">
                <Button type="primary" htmlType="submit">{props.cpsPhoto ? 'Finaliser mon inscription' : 'Continuer sans carte CPS'}</Button>
            </Form.Item>
        </Form>;
    };

    switch (props.step) {
        case Step.RppsSearch:
            return renderRppsSearchForm();
        case Step.InformationFilling:
            return renderInformationFillingForm();
        case Step.CodesValidation:
            return renderCodesValidationForm();
        case Step.CpsUpload:
            return renderCpsUploadForm();
        default:
            return null;
    }
};

export default memo(RegisterComponent);
