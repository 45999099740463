import { Alert, Button, Form, Input, Modal } from 'antd';
import React, { memo, ReactElement } from 'react';
import { renderMessage } from 'services/helpers';

interface IProps {
    message?: IMessage;
    validate: (address: IValidationCodeParams) => void;
    onHide: () => void;
    show: boolean;
}

const TokenValidatingModal = (props: IProps): ReactElement => {
    const [form] = Form.useForm<IValidationCodeParams>();

    const onValidate = () => {
        props.validate(form.getFieldsValue());
        hide();
    };

    const hide = () => {
        props.onHide();
    };

    return <Modal
        title="Validation de code"
        centered
        visible={props.show}
        onOk={onValidate}
        onCancel={hide}
        footer={[
            <Button key="back" onClick={hide}>
                Annuler
            </Button>,
            <Button key="submit" type="primary" onClick={onValidate}>
                Valider
            </Button>,
        ]}>
        <Form
            form={form}
            layout="vertical"
            onFinish={onValidate}
        >
            {renderMessage(props.message)}

            <Alert type="info" message="Veuillez saisir le code qui vous a été envoyé par mail" />

            <Form.Item name="code">
                <Input placeholder="Code" />
            </Form.Item>
        </Form>
    </Modal>;
};

export default memo(TokenValidatingModal);
