import App from 'components/app';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'redux/store';
import reportWebVitals from './reportWebVitals';

createRoot(document.getElementById('root') || document.createElement('div'))
    .render(<Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>);

reportWebVitals();
