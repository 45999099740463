import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Select, Upload } from 'antd';
import React, { memo, ReactElement } from 'react';
import './index.less';

const { TextArea } = Input;

interface IProps {
    save: () => void;
}

const TasksComponent = (props: IProps): ReactElement => {
    const save = () => {
        props.save();
    };

    return <Card className="groups" bordered={false} title="Tâches">
        <Form layout="vertical">
            <div className="inline">
                <Form.Item label="Destinataire">
                    <Select
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        notFoundContent={null}
                        allowClear
                    >

                    </Select>
                </Form.Item>
                <Form.Item label="Patient">
                    <Select
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        notFoundContent={null}
                        allowClear
                    >
                    </Select>
                </Form.Item>
            </div>

            <Form.Item label="Objet">
                <Input />
            </Form.Item>

            <Form.Item>
                <TextArea rows={15} placeholder="Ecrivez votre demande..." />
            </Form.Item>

            <Form.Item valuePropName="fileList" label="Joindre un fichier..." className="attachments">
                <Upload
                    listType="text"
                    maxCount={1}
                >
                    <Button icon={<UploadOutlined />}>Depuis mon poste</Button>
                </Upload>
                <Upload
                    listType="text"
                    maxCount={1}
                >
                    <Button icon={<UploadOutlined />}>Depuis mes documents</Button>
                </Upload>
            </Form.Item>

            <Form.Item className="actions">
                <Button type="primary" onClick={save}>Envoyer</Button>
            </Form.Item>
        </Form>
    </Card>;
};

export default memo(TasksComponent);
