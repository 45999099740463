import { DatePicker, DatePickerProps, Form, Input, InputNumber, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/fr_FR';
import moment from 'moment';
import { ReactElement, ReactNode, useEffect } from 'react';
import { Subject } from 'rxjs';
import { Sex } from 'services/helpers';

interface IProps {
    setMessage: (value: any, type?: 'warning' | 'error' | 'info' | 'success' | undefined) => void;
    patient: IPatientParams | undefined;
    onValidate$: Subject<void>;
    validate: (patient: IPatientParams) => void;
    setPatient: (patient: IPatientParams | undefined) => void;
    disabled: boolean
}

const { Option } = Select;

export function PatientForm({ disabled = false, ...props }: IProps): ReactElement | null {
    const [form] = Form.useForm();

    useEffect(() => {
        props.onValidate$.subscribe(() => {
            form.validateFields()
                .then(async (values) => {
                    props.setMessage(undefined);
                    props.validate({
                        ...values,
                    });

                }).catch((err: Error) => {
                    console.log(err);
                });
        });

        return () => {
            props.onValidate$.unsubscribe();
        };
    }, [props.onValidate$]);


    useEffect(() => {
        form.setFieldsValue({
            ...props.patient,
            birthDate: props.patient?.birthDate ? moment(props.patient.birthDate) : null,
        });
    }, [props.patient]);

    const onDatePickerChangeBirthDate: DatePickerProps['onChange'] = (date: moment.Moment | null) => {
        const birthDateIn = moment(date, 'DD/MM/YYYY', true);
        if (birthDateIn.isValid()) {
            form.setFieldsValue({
                birthDate: birthDateIn,
            });
            if (props.patient) {
                props.setPatient({
                    ...props.patient,
                    birthDate: birthDateIn.toDate(),
                });
            }
        } else {
            if (props.patient) {
                props.setPatient({
                    ...props.patient,
                    birthDate: null,
                });
            }
        }
    };

    const onBirthRankChange = (birthRank: number | null) => {
        form.setFieldsValue({
            birthRank: birthRank,
        });
        if (props.patient) {
            props.setPatient({
                ...props.patient,
                birthRank: birthRank || 1,
            });
        }
    };

    const renderSexes = (): ReactNode => {
        return Object.keys(Sex).map((sex: string) => {
            return <Option key={sex} value={sex}>{Sex[sex as keyof typeof Sex]}</Option>;
        });
    };

    return <Form
        layout="vertical"
        form={form}
        disabled={disabled}
        initialValues={{
            ...props.patient,
            birthDate: props.patient?.birthDate ? moment(props.patient.birthDate) : null,
        }}
    >
        <div className="inline">
            <Form.Item
                label="Nom" name="lastName"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Prénom" name="firstName"
            >
                <Input />
            </Form.Item>
        </div>
        <div className="inline">
            <Form.Item
                label="Date de naissance" name="birthDate" className="patient-modal-date"
            >
                <DatePicker
                    allowClear={true}
                    locale={locale}
                    size="small"
                    format="DD/MM/YYYY"
                    onChange={onDatePickerChangeBirthDate}
                />
            </Form.Item>
            <Form.Item
                label="Rang de naissance" name="birthRank" className="patient-modal-date"
            >
                <InputNumber
                    min={1}
                    onChange={onBirthRankChange}
                />
            </Form.Item>
            <Form.Item
                label="Sexe" name='sex'
            >
                <Select>
                    {renderSexes()}
                </Select>
            </Form.Item>
        </div>
        <div className="inline">
            <Form.Item
                label="Numéro de sécurité sociale" name='INS' className="patient-modal-ins"
                validateTrigger={['onBlur']}
                rules={[{
                    pattern: /^\d{15}$/,
                    message: 'Le numéro INS doit contenir 15 chiffres.',
                }]}
            >
                <Input
                    maxLength={15}
                />
            </Form.Item>
            <Form.Item
                label="Code de l'organisme de rattachement" name='insuranceCode' className="patient-modal-ins"
                validateTrigger={['onBlur']}
                rules={[{
                    pattern: /^\d{9}$/,
                    message: 'Le code de l\'organisme de rattachement doit contenir 9 chiffres.',
                }]}
            >
                <Input
                    maxLength={9}
                />
            </Form.Item>
        </div>
    </Form>;
}
