import ResetComponent from './component';
import React, { memo, ReactElement } from 'react';
import { useSessionActions } from 'redux/actions/session.action';
import { useMessage } from 'services/helpers';
import { useNavigate } from 'react-router';
import pages from 'pages';

const Reset = (): ReactElement | null => {
    const navigate = useNavigate();
    const { requestPasswordReset } = useSessionActions();

    const [message, setMessage] = useMessage();

    const onValidate = async (credentials: IRequestResetParams) => {
        try {
            await requestPasswordReset(credentials).catch((err: Error) => {
                throw err;
            });

            setMessage('Email envoyé', 'success');
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const goHome = () => {
        setMessage(undefined);
        navigate(pages.login.path);
    };

    return <ResetComponent
        message={message}
        goHome={goHome}
        validate={onValidate}
    />;
};

export default memo(Reset);
