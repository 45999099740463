import { ClockCircleOutlined, ShareAltOutlined, WarningOutlined } from '@ant-design/icons';
import { Badge, Card, Empty, List, Modal, Skeleton, Tooltip } from 'antd';
import Document, { useDocumentActions } from 'components/document';
import { memo, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { renderFileIcon, renderMessage } from 'services/helpers';
import { Limit } from '.';
import './index.less';

const { confirm } = Modal;

interface IProps {
    message?: IMessage;
    document?: IDocument;
    data?: IGroupDocumentKeyed;
    getNextDocuments: (offset?: number) => Promise<void>;
    shareDocument: () => void;
    selectGroupDocument: (groupDocument: IGroupDocument) => void;
    updateDocument: (document: IDocument, persons: IPerson[]) => void;
    saveDocument: () => void;
    downloadDocument: () => void;
    deleteDocument: () => void;
}

const DocumentsComponent = (props: IProps): ReactElement => {
    const onSelectGroupDocument = (groupDocument: IGroupDocument) => {
        props.selectGroupDocument(groupDocument);
    };

    const onSaveDocument = () => {
        props.saveDocument();
    };

    const onDownloadDocument = () => {
        props.downloadDocument();
    };

    const onShareDocument = () => {
        props.shareDocument();
    };

    const onUpdateDocument = (document: IDocument, persons: IPerson[]) => {
        setCanShareDocument(persons.length > 0);
        props.updateDocument(document, persons);
    };

    const onDeleteDocument = () => {
        confirm({
            title: 'Voulez vraiment supprimer ce document ?',
            icon: <WarningOutlined />,
            onOk: () => {
                props.deleteDocument();
            },
            cancelText: 'Annuler',
            centered: true,
        });
    };

    const { setCanShareDocument, renderDocumentActions } = useDocumentActions(onSaveDocument, onDownloadDocument, onDeleteDocument, onShareDocument);

    const getNextDocuments = async () => {
        await props.getNextDocuments();
    };

    const renderDocument = (document: IDocument): ReactElement | undefined => {
        return <Document
            document={document}
            update={onUpdateDocument}
        />;
    };

    const renderDocuments = (): ReactElement => {
        const dataIn = Object.values(props.data || {});

        const documentsCount = dataIn.reduce((total: number, groupDocuments: IGroupDocument[]) => total + groupDocuments.length, 0);

        return <div className="files" id="files">
            <InfiniteScroll
                dataLength={documentsCount}
                next={getNextDocuments}
                hasMore={documentsCount % Limit === 0}
                loader={<Skeleton paragraph={{ rows: 0 }} active />}
                scrollableTarget="files"
            >
                {dataIn.map((groupDocuments: IGroupDocument[], index: number) => {
                    const group = groupDocuments[0].group;
                    return groupDocuments.length > 0 ? <List
                        key={'fg' + index}
                        className="files-group"
                        itemLayout="horizontal"
                        dataSource={groupDocuments}
                        header={group.name ? `De ${group.name}` : `${group.civility} ${group.firstName} ${group.lastName}`}
                        size="small"
                        renderItem={(groupDocumentIn: IGroupDocument) => {
                            const { sharedBy, document, unseen } = groupDocumentIn;
                            const { name, mimetype } = document;

                            const shareElement = sharedBy ? <div>
                                <div><ShareAltOutlined /> </div>
                                <div>{sharedBy.civility} {sharedBy.firstName} {sharedBy.lastName}</div>
                            </div> : undefined;

                            return <List.Item onClick={() => onSelectGroupDocument(groupDocumentIn)} className={groupDocumentIn.document.id === props.document?.id ? 'selected' : undefined}>
                                <Tooltip title={name}>
                                    <List.Item.Meta
                                        avatar={renderFileIcon(mimetype)}
                                        title={<div className="list-item-main-info">
                                            <div>
                                                <div className="document-item-title">{name}</div>
                                                {unseen ? <Badge dot={true} offset={[0, 2]} /> : undefined}</div>
                                            <div className="file-share-date">{groupDocumentIn.createdAt?.toLocaleDateString('fr-FR')}</div>
                                        </div >}
                                        description={< div className="list-item-secondary-info" >
                                            {shareElement}
                                            < div >
                                                <div><ClockCircleOutlined /></div>
                                                <div>{groupDocumentIn.expiresAt?.toLocaleDateString('fr-FR')}</div>
                                            </div >
                                        </div >}
                                    />
                                </Tooltip >
                            </List.Item >;
                        }}
                        locale={{
                            emptyText: 'Aucun document',
                        }}
                    /> : undefined;
                })}
            </InfiniteScroll >
        </div >;
    };

    const renderActions = (document?: IDocument): ReactElement | undefined => {
        return document ? <div className="actions">
            {renderDocumentActions()}
        </div> : undefined;
    };

    const render = (): ReactElement => {
        return props.data && Object.values(props.data).length > 0 ? <>
            {renderMessage(props.message)}

            <div className="list">
                {renderDocuments()}
                {props.document ? renderDocument(props.document) : null}
            </div>

            {renderActions(props.document)}
        </> : <Empty description="Aucun document" />;
    };

    return <Card className="documents" bordered={false} title="Documents">
        {render()}
    </Card>;
};

export default memo(DocumentsComponent);
