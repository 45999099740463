import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { Modal, Progress, Spin } from 'antd';
import Document, { useDocumentActions as useActions } from 'components/document';
import { saveAs } from 'file-saver';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { memo, ReactElement, useEffect, useState } from 'react';
import { useDocumentActions } from 'redux/actions/document.action';
import { renderMessage, renderStandaloneElement, useDocumentUpload, useMessage } from 'services/helpers';
import './index.less';

const { confirm } = Modal;

interface IProps {
    uploadRequestOptions: UploadRequestOption;
    document?: IDocument;
    saveDocument?: () => void;
    downloadDocument?: () => void;
    deleteDocument?: () => void;
    shareDocument?: () => void;
    updateDocument?: (document: IDocument) => void;
    afterUpload?: (document: IDocument) => void;
    uploadProgress?: (loaded: number, total: number) => void;
    onHide?: () => void;
}

const UploadModal = (props: IProps): ReactElement | null => {
    const [message, setMessage] = useMessage();
    const [persons, setPersons] = useState<IPerson[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number>();
    const [isShown, setShown] = useState<boolean>(true);
    const [document, setDocument] = useState<IDocument | undefined>(props.document);

    const { updateDocument, getDocumentContent, deleteDocument, shareDocumentWithPerson } = useDocumentActions();
    const uploadDocument = useDocumentUpload();

    useEffect(() => {
        (async () => {
            await uploadDocument({
                uploadRequestOptions: props.uploadRequestOptions,
                onBeforeUpload: () => {
                    setUploadProgress(0);
                },
                onUploadProgress: (loaded: number, total: number) => {
                    if (loaded < total) {
                        setUploadProgress(loaded / total * 100);
                    }
                    else {
                        setMessage('Votre document a bien été importé', 'success');
                        setUploadProgress(undefined);
                    }

                    props.uploadProgress?.(loaded, total);
                },
                onAfterUpload: (documentIn: IDocument) => {
                    setDocument(documentIn);
                    props.afterUpload?.(documentIn);
                },
            }).catch((err: Error) => {
                throw err;
            });
        })().catch((err: Error) => {
            console.log(err);
        });
    }, []);

    const onDeleteDocument = () => {
        confirm({
            title: 'Voulez vraiment supprimer ce document ?',
            icon: <WarningOutlined />,
            onOk: async () => {
                try {
                    if (!document) {
                        throw new Error('Document not defined');
                    }

                    await deleteDocument(document.id).catch((err: Error) => {
                        throw err;
                    });

                    props.deleteDocument?.();

                    hide();
                }
                catch (err: any) {
                    setMessage(err);
                }
            },
            cancelText: 'Annuler',
            centered: true,
        });
    };

    const onSaveDocument = async () => {
        try {

            if (!document) {
                throw new Error('Document not defined');
            }
            await updateDocument(document).catch((err: Error) => {
                throw err;
            });

            props.saveDocument?.();

            hide();
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onUpdateDocument = (documentIn: IDocument, personsIn: IPerson[]) => {
        setCanShareDocument(personsIn.length > 0);
        setPersons(personsIn);
        setDocument(documentIn);
        props.updateDocument?.(documentIn);
    };

    const onDownloadDocument = async () => {
        try {
            if (!document) {
                throw new Error('Document not defined');
            }

            saveAs(new Blob([await getDocumentContent(document.id).catch((err: Error) => {
                throw err;
            })], {
                type: 'application/octet-stream',
            }), document.name);

            props.downloadDocument?.();
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onShareDocument = async () => {
        try {
            if (!document) {
                throw new Error('No document selected');
            }

            await Promise.all(persons.map((person: IPerson) => shareDocumentWithPerson(document, person).catch((err: Error) => {
                throw err;
            }))).catch((err: Error) => {
                throw err;
            });

            props.shareDocument?.();

            setMessage('Votre document a bien été partagé', 'success');
        }
        catch (err: any) {
            if (err.message === 'Bad Request') {
                setMessage('Une erreur est survenue, veuillez vérifier les informations que vous avez entrées.', 'error');
            } else {
                setMessage('Votre document a bien été partagé (quelques utilisateurs disposaient déjà du document)', 'warning');
            }
        }
    };

    const { setCanShareDocument, renderDocumentActions } = useActions(onSaveDocument, onDownloadDocument, onDeleteDocument, onShareDocument);

    const hide = () => {
        setShown(false);
        setDocument(undefined);
        props.onHide?.();
    };

    const render = (): ReactElement => {
        if (uploadProgress) {
            return <div className="upload-progress">
                <Progress type="circle" percent={Math.round(uploadProgress)} />
                <div className="description">Import du fichier...</div>
            </div>;
        }

        if (!document) {
            return <Spin className="upload-progress" tip="Traitement en cours..." indicator={<LoadingOutlined spin />} />;
        }

        return <>
            {renderMessage(message)}
            <Document
                document={document}
                update={onUpdateDocument}
            />
        </>;
    };

    return <Modal
        className="upload-modal"
        title="Import de document"
        open={isShown}
        onCancel={hide}
        width="60rem"
        centered
        footer={renderDocumentActions()}>
        {render()}
    </Modal>;
};

export function renderDocumentUploadModal(props: IProps): void {
    renderStandaloneElement(UploadModal, props);
}

export default memo(UploadModal);
