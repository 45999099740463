import { Card, Empty } from 'antd';
import { ReactElement, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import pages from '../../../pages';

interface ILocationState {
    isUserActive: boolean;
    hasAdeli: boolean;
}

const ExpertisesPlaceholderComponent = (): ReactElement => {
    const state = useLocation().state as ILocationState;

    if (!state.isUserActive) {
        return <Card bordered={false} title="Expertises">
            <div className="empty-content">
                <Empty description={<>Votre compte n&apos;est malheureusement pas encore activé,<br />
                    ce qui vous empêche d&apos;avoir accès aux expertises.<br />
                    Pour activer votre compte veuillez ajouter une photo de votre CPS depuis votre <Link to={pages.account.path}>profil</Link>.</>} />
            </div>
        </Card>;
    }
    return <Card bordered={false} title="Expertises">
        <div className="empty-content">
            <Empty description={<>Vous devez renseigner votre ADELI dans la situation que vous avez sélectionnée.<br />
                Pour se faire rendez-vous dans votre <Link to={pages.account.path}>profil</Link>.</>} />
        </div>
    </Card>;
};

export default memo(ExpertisesPlaceholderComponent);
