import { saveAs } from 'file-saver';
import pages from 'pages';
import { memo, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useExpertiseActions } from 'redux/actions/expertise.action';
import { usePatientActions } from 'redux/actions/patient.action';
import { AppState } from 'redux/reducers';
import { useMessage, withExpertisesAccess } from 'services/helpers';
import { ExpertiseChatComponent } from './component';
import { ExpertiseEmptyComponent } from './empty';


const ExpertiseChat = (): ReactElement | null => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isLoadingMessages, setIsLoadingMessages] = useState(false);
    const [isLoadingExpertises, setIsLoadingExpertises] = useState(false);
    const [expertise, setExpertise] = useState<IExpertise>();
    const [previousExpertise, setPreviousExpertise] = useState<IExpertise>();
    const [isExpertisesSent, setIsExpertisesSent] = useState<boolean>(true);
    const [documents, setDocuments] = useState<IDocument[]>();
    const [expertisesInProgress, setExpertisesInProgress] = useState<IExpertise[]>([]);
    const [expertisesEnded, setExpertisesEnded] = useState<IExpertise[]>([]);
    const [messages, setMessages] = useState<IExpertiseMessage[]>([]);
    const [message, setMessage] = useMessage();
    const { getExpertise, getRecipientExpertises, getRequesterExpertises,
        getMessagesFromExpertise, sendMessageToExpertise, updateExpertise,
        linkDocumentToExpertise, finalizeExpertise, archiveExpertise, exportPdf } = useExpertiseActions();
    const { createPatient, updatePatient } = usePatientActions();

    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const { user } = mapStateToObj.session;

    const onSendMessage = async (messageIn: IExpertiseMessageParams) => {
        await sendMessageToExpertise(expertise?.id, messageIn)
            .catch(err => {
                console.log(err);
            });
        await getLastMessages();
        refreshExpertises(isExpertisesSent);
    };

    const getLastMessages = async (withFullMessages = false) => {
        const messagesIn = withFullMessages ? [] : messages;
        const lastMessageId = messagesIn[messagesIn.length - 1]?.id;
        if (!expertise) return;
        await getMessagesFromExpertise(expertise?.id, lastMessageId)
            .then((res: IExpertiseMessage[]) => {
                setIsLoadingMessages(false);
                setMessages([
                    ...messagesIn,
                    ...res,
                ]);
                let hasFetched = false;
                res.forEach(async item => {
                    if (item.document && !hasFetched) {
                        await refreshExpertise();
                        hasFetched = true;
                    }
                });

            })
            .catch(err => {
                throw err;
            });
    };


    const onChangeExpertise = async (expertiseIn: IExpertise) => {
        const { id } = expertiseIn;
        if (expertiseIn.id !== expertise?.id)
            setIsLoadingMessages(true);
        setPreviousExpertise(expertise);
        navigate(isExpertisesSent ?
            pages.expertisesSentChat.path.replace(':id', String(id)) :
            pages.expertisesReceivedChat.path.replace(':id', String(id)),
        );
    };

    const onSaveDocument = async (document: IDocument) => {
        try {
            await linkDocumentToExpertise(document.id, expertise?.id).catch(err => {
                throw err;
            });

            await getLastMessages().catch(err => {
                throw err;
            });

            await refreshExpertise().catch(err => {
                throw err;
            });
        }
        catch (err) {
            setMessage(err);
        }
    };

    const onAddPatient = async (infoPatient: IPatientParams) => {
        if (!expertise)
            return;

        if (!expertise.patient) {
            const patient = await createPatient(infoPatient).catch(err => {
                throw err;
            });
            await updateExpertise(expertise.id, patient.id).catch(err => {
                throw err;
            });
        } else
            await updatePatient(expertise.patient.id, infoPatient).catch(err => {
                throw err;
            });

        await refreshExpertise();
    };

    const onAssignPatient = async (patient: IPatient | null) => {
        if (!expertise)
            return;

        await updateExpertise(expertise.id, patient?.id ?? null).catch(err => {
            throw err;
        });

        await refreshExpertise();
    };

    const onFinalizeExpertise = async (person: IPerson) => {
        try {
            await finalizeExpertise(expertise?.id, person.id).catch((err: Error) => {
                throw err;
            });

            await refreshExpertise().catch((err: Error) => {
                throw err;
            });

            refreshExpertises(isExpertisesSent);
        }
        catch (err) {
            setMessage(err);
        }
    };

    const onArchiveExpertise = async (person: IPerson) => {
        try {
            await archiveExpertise(expertise?.id, person.id).catch((err: Error) => {
                throw err;
            });

            await refreshExpertise().catch((err: Error) => {
                throw err;
            });

            refreshExpertises(isExpertisesSent);
        }
        catch (err) {
            setMessage(err);
        }
    };

    const onExportPdf = async () => {
        try {
            saveAs(new Blob([await exportPdf(expertise?.id).catch((err: Error) => {
                throw err;
            })], {
                type: 'application/pdf',
            }), getExportFileName());
        }
        catch (err) {
            setMessage(err);
        }
    };

    const onGoToRequest = () => {
        navigate(pages.expertisesRequest.path);
    };

    const getExportFileName = (): string => {
        const patient = expertise?.patient;
        const expert = isExpertisesSent ? expertise?.recipient : expertise?.requester;
        if (!patient) {
            return 'export.pdf';
        }

        const { lastName } = patient;

        if (expert?.type === 'GroupEntity') {
            return getExportFileNameFromName(expertise, lastName, expert?.name);
        }

        return getExportFileNameFromName(expertise, lastName, expert?.lastName);
    };

    const getExportFileNameFromName = (expertiseIn: IExpertise, lastName?: string, name?: string): string => {
        return (`expertise_${lastName}_${name}_${expertiseIn.createdAt.toISOString().replace(/T.*/, '')}_${expertiseIn.id}`).normalize('NFKD');
    };

    const setExpertiseWithDocuments = (expertiseIn: IExpertise) => {
        setExpertise(expertiseIn);
        setDocuments(expertiseIn.documents);
        setIsLoadingExpertises(false);
    };

    const onShareDocument = async (documentsToShare: IDocument[]) => {
        for (const document of documentsToShare)
            await linkDocumentToExpertise(document.id, expertise?.id).catch(err => {
                throw err;
            });
        await getLastMessages();
        await refreshExpertise();
    };

    const refreshExpertise = async () => {
        const expertiseId = pathname.split('/')[3] ?? expertise?.id;
        if (expertiseId) {
            const expertiseIn = await getExpertise(expertiseId);
            setExpertiseWithDocuments(expertiseIn);
        }
    };

    const refreshExpertises = (isExpertisesSentIn?: boolean): void => {
        if (isExpertisesSentIn) {
            Promise.all([
                getRequesterExpertises(['DRAFT', 'SENT']),
                getRequesterExpertises(['REPLIED', 'ARCHIVED', 'PRINTED']),
            ])
                .then(([expertisesInProgress, expertisesEnded]) => {
                    setExpertisesInProgress(expertisesInProgress);
                    setExpertisesEnded(expertisesEnded);
                })
                .catch((err) => console.error(err));
        } else {
            Promise.all([
                getRecipientExpertises(['DRAFT', 'SENT']),
                getRecipientExpertises(['REPLIED', 'ARCHIVED', 'PRINTED']),
            ])
                .then(([expertisesInProgress, expertisesEnded]) => {
                    setExpertisesInProgress(expertisesInProgress);
                    setExpertisesEnded(expertisesEnded);
                })
                .catch((err) => console.error(err));
        }
    };

    const onRefreshExpertise = async (withFullMessagesRefresh = false) => {
        if (withFullMessagesRefresh) {
            setMessages([]);
        }

        await refreshExpertise().catch((err: Error) => {
            throw err;
        });
        await getLastMessages(withFullMessagesRefresh);
    };

    const removeErrorMessage = () => {
        setMessage(undefined);
    };

    const loadExpertise = async (expertiseIn: IExpertise) => {
        setExpertiseWithDocuments(expertiseIn);

        setMessages(await getMessagesFromExpertise(expertiseIn.id)
            .then((res: IExpertiseMessage[]) => {
                setIsLoadingMessages(false);
                return res;
            })
            .catch((err: Error) => {
                throw err;
            }));
    };

    useEffect(() => {
        if ([pages.expertisesSent.path, pages.expertisesReceived.path].includes(pathname)) {
            setIsLoadingExpertises(true);
        }
    }, [pathname]);

    useEffect(() => {
        (async () => {
            const isExpertisesSentIn = pathname.includes('sent');
            setIsExpertisesSent(isExpertisesSentIn);
            setMessage(undefined);
            setIsLoadingMessages(true);

            refreshExpertises(isExpertisesSentIn);

        })().catch((err: Error) => {
            setMessage(err);
        });
    }, [pathname]);

    useEffect(() => {
        if ([pages.expertisesSent.path, pages.expertisesReceived.path].includes(pathname)) {
            if (expertisesInProgress.length > 0) {
                loadExpertise(expertisesInProgress[0]).catch(err => console.error(err));
            } else if (expertisesEnded.length > 0) {
                loadExpertise(expertisesEnded[0]).catch(err => console.error(err));
            }
        }
        else {
            getExpertise(pathname.split('/')[3])
                .then(expertise => {
                    return loadExpertise(expertise);
                })
                .catch((err: Error) => {
                    throw err;
                });
        }
    }, [expertisesInProgress, expertisesEnded]);

    useEffect(() => {
        const pollingInterval = setInterval(async () => {
            setPreviousExpertise(undefined);
            await getLastMessages().catch((err: Error) => {
                console.log(err);
            });
            refreshExpertises(isExpertisesSent);
        }, 15000);
        return () => {
            clearInterval(pollingInterval);
        };
    }, [messages]);

    return <>
        {(expertisesInProgress.length === 0 && expertisesEnded.length === 0) ?
            <ExpertiseEmptyComponent goToRequest={onGoToRequest} /> :
            <ExpertiseChatComponent
                expertise={expertise}
                previousExpertise={previousExpertise}
                documents={documents}
                expertisesInProgress={expertisesInProgress}
                expertisesEnded={expertisesEnded}
                messages={messages}
                user={user}
                message={message}
                loadingMessages={isLoadingMessages}
                loadingExpertises={isLoadingExpertises}
                isExpertisesSent={isExpertisesSent}
                sendMessage={onSendMessage}
                changeExpertise={onChangeExpertise}
                finalizeExpertise={onFinalizeExpertise}
                archiveExpertise={onArchiveExpertise}
                saveDocument={onSaveDocument}
                exportPdf={onExportPdf}
                refreshExpertise={onRefreshExpertise}
                shareDocument={onShareDocument}
                addPatient={onAddPatient}
                assignPatient={onAssignPatient}
                removeErrorMessage={removeErrorMessage}
            />
        }
    </>;
};

export default memo(withExpertisesAccess(ExpertiseChat));
