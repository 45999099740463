import { EyeInvisibleOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import { memo, ReactElement } from 'react';
import { getMessage } from 'services/helpers';
import './index.less';

interface IProps {
    file?: File;
    message?: IMessage;
    forceDisplay: () => void;
}

const DocumentVisuComponent = (props: IProps): ReactElement => {
    const onForceDisplay = () => {
        props.forceDisplay();
    };

    const renderError = (message: IMessage): ReactElement => {
        return <Alert className="big-file" type="error" message={<>
            {getMessage(message)}<br />
            <Button type="primary" onClick={onForceDisplay}>Afficher quand meme</Button>
        </>} />;
    };

    const renderPreview = (content: ReactElement): ReactElement => {
        return <div className="document-visu">
            {props.message ?
                renderError(props.message) :
                content}
        </div>;
    };

    const renderContent = (file?: File): ReactElement => {
        if (!file) {
            return renderPreview(<EyeInvisibleOutlined className="no-content" />);
        }

        const { type } = file;
        const src = URL.createObjectURL(file);

        switch (type) {
            case 'image/gif':
            case 'image/jpeg':
            case 'image/png':
            case 'image/svg+xml':
                return renderPreview(<img className="preview image" src={src} />);
            default:
                return renderPreview(<object className="preview object" data={src} />);
        }
    };

    return renderContent(props.file);
};

interface IUseDocumentVisuActions {
    renderDocumentVisuActions: () => ReactElement[];
}

export function useDocumentVisuActions(onDownload?: () => void, onDelete?: () => void): IUseDocumentVisuActions {
    const element: ReactElement[] = [];
    if (onDelete)
        element.push(<Button key="delete" type="primary" danger onClick={onDelete}>Supprimer</Button>);
    if (onDownload)
        element.push(<Button key="download" onClick={onDownload}>Télécharger</Button>);
    const renderDocumentVisuActions = (): ReactElement[] => [
        ...element,
    ];

    return {
        renderDocumentVisuActions,
    };
}

export default memo(DocumentVisuComponent);
