import { useState } from 'react';
import { Button, Modal, Select, Space, Typography } from 'antd';
import { renderBirthDate } from '../../services/helpers';

interface IPatientSelectModalProps {
    patients: IPatientParams[],
    cancel?: () => void,
    onValidate: (index: number) => void,
    showINS?: boolean
}

export const VitalePatientSelectModal = ({patients, cancel, onValidate, showINS = false}: IPatientSelectModalProps) => {
    const [value, setValue] = useState(0);

    return <Modal
        className="patients-modal"
        title="Choix du patient"
        open={true}
        centered
        footer={[
            cancel && <Button key="back" onClick={() => {
                cancel();
            }}>
                Annuler
            </Button>,
            <Button key="submit" type="primary" onClick={() => {
                onValidate(value);
            }}>
                Valider
            </Button>,
        ]}
        closable={false}
    >
        <Select
            size="large"
            value={value}
            style={{width: '100%'}}
            autoFocus
            onChange={setValue}
        >
            {patients.map((patient, value) =>
                <Select.Option value={value} key={value}>
                    <Space direction="vertical" size={5}>
                        <Typography.Text>{patient.firstName} {patient.lastName}</Typography.Text>
                        <Typography.Text type="secondary">né{patient.sex === 'F' && 'e'} le {renderBirthDate(patient.birthDate)}</Typography.Text>
                        {showINS && <Typography.Text type="secondary">{patient.INS}</Typography.Text>}
                    </Space>
                </Select.Option>
            )}
        </Select>
    </Modal >;
};
