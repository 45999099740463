import { memo, ReactElement, useEffect, useState } from 'react';
import { useSessionActions } from 'redux/actions/session.action';
import { useMessage } from 'services/helpers';
import SecurityComponent from './component';

const Security = (): ReactElement | null => {
    const [securityMessage, setSecurityMessage] = useMessage();
    const { updateAccountSecurity, getAccount } = useSessionActions();
    const [user, setUser] = useState<IUser>();

    const onSave = async (params: IAccountSecurityParams) => {
        try {
            const { password, passwordConfirmation } = params;

            if (password !== passwordConfirmation) {
                throw new Error('Les mots de passe ne correspondent pas');
            }

            await updateAccountSecurity(params);

            setSecurityMessage('Paramètres de sécurité mis à jour', 'success');
        }
        catch (err: any) {
            setSecurityMessage(err);
        }
    };

    useEffect(() => {
        getAccount()
            .then((userIn: IUser) => {
                setUser(userIn);
            })
            .catch((err: Error) => {
                setSecurityMessage(err);
            });
    }, []);


    return <SecurityComponent
        securityMessage={securityMessage}
        save={onSave}
        user={user}
    />;
};

export default memo(Security);
