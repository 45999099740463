import { Input, Space, Table, TablePaginationConfig } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { getColumns } from './columns';
import './index.less';
import { SearchOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import { usePatientActions } from '../../redux/actions/patient.action';

const Limit = 12;

export type TableHandle = {
    reload: () => void;
}

type Props = object;

const TableComponent = forwardRef<TableHandle, Props>((_, ref) => {
    const { searchPatients } = usePatientActions();
    const [items, setItems] = useState<IPatient[]>([]);
    const [pagination, setPagination] = useState<IPagination>();
    const [term, setTerm] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    useImperativeHandle(ref, () => {
        return {
            reload: () => search(1),
        };
    }, []);

    const search = (page?: number, pageSize?: number, search?: string) => {
        const limit = pageSize ?? Limit;
        setLoading(true);
        searchPatients({
            limit,
            offset: ((page ?? 1) - 1) * limit,
            ...(search ? {param: search} : {}),
        })
            .then(data => {
                const { results, count } = data;
                setItems(results);
                setPagination({
                    current: page,
                    pageSize: pageSize ?? Limit,
                    defaultPageSize: Limit,
                    total: count ?? 0,
                    pageSizeOptions: [Limit, Limit * 2, Limit * 5, Limit * 10],
                });
            })
            .catch((err: Error) => {
                console.error(err.message);
            })
            .finally(() => {
                setLoading(false);
            })
        ;
    };

    const changePagination = (paginationIn: TablePaginationConfig) => {
        const { current, pageSize } = paginationIn;
        search(current, pageSize, term);
    };

    const onChangePagination = (pagination: TablePaginationConfig) => {
        changePagination(pagination);
    };

    useEffect(() => {
        search(1, undefined, term);
    }, [term]);

    const debounced = useDebouncedCallback(
        (value?: string) => {
            setTerm(value);
        }, 400,
    );

    return <Space direction="vertical" size={10} style={{width: '100%'}}>
        <Space.Compact block>
            <Input allowClear addonBefore={<SearchOutlined />} placeholder="Recherche de patient" onChange={(e) => {
                debounced(e.target.value);
            }} />
        </Space.Compact>
        <Table
            loading={loading}
            columns={getColumns()}
            dataSource={items.map((item) => { return { key: item.id, ...item }; })}
            pagination={pagination}
            onChange={onChangePagination}
        /></Space>;
});

export default TableComponent;
