import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Progress, Row, Tag, Upload } from 'antd';
import pscImage from 'assets/images/psc.png';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { ReactElement } from 'react';

interface IProps {
    uploadPhoto: (options: UploadRequestOption) => void;
    user?: IUser;
    uploadProgress?: number;
    cpsPhoto?: IDocumentMeta;
    pscEnabled?: boolean;
}

export function IdentificationComponent(props: IProps): ReactElement {
    const onBeforeUploadPhoto = (file: File): string | boolean => {
        return ![
            'image/gif',
            'image/jpeg',
            'image/png',
            'image/svg+xml',
        ].includes(file.type) ? Upload.LIST_IGNORE : true;
    };

    const onUploadPhoto = (options: UploadRequestOption) => {
        props.uploadPhoto(options);
    };

    const renderCpsField = (uploadProgress?: number, cpsPhoto?: IDocumentMeta): ReactElement => {
        if (uploadProgress) return renderCpsLoading(uploadProgress);
        return renderCpsButton(cpsPhoto);
    };

    const renderCpsLoading = (uploadProgress: number): ReactElement => {
        return <Upload className="upload-progress" showUploadList={false} disabled>
            <p className="ant-upload-text">Importation en cours...</p>
            <Progress percent={Math.round(uploadProgress)} />
        </Upload>;
    };

    const renderCpsButton = (cpsPhoto?: IDocumentMeta): ReactElement => {
        return <Upload className="upload" showUploadList={false} beforeUpload={onBeforeUploadPhoto} customRequest={onUploadPhoto}>
            <Button >
                <UploadOutlined /> {cpsPhoto ? 'Modifier votre carte CPS' : 'Photo de votre carte CPS'}
            </Button>
        </Upload>;
    };

    const renderTagCPSCard = (cpsPhoto: IDocumentMeta | undefined, user?: IUser): ReactElement => {
        if (!cpsPhoto)
            return <Tag className="cps-card-tag" color="error" icon={<CloseCircleOutlined style={{ color: 'inherit' }} />} >Vous n&apos;avez pas de carte CPS renseignée</Tag>;
        if (user && user.status != 'ACTIVE')
            return <Tag className="cps-card-tag" color="warning" style={{ color: '#a14300' }} icon={<InfoCircleFilled style={{ color: 'inherit' }} />} >Votre carte CPS est en cours de vérification <br /> Cela peut prendre jusqu&apos;à 48 heures</Tag>;
        return <Tag className="cps-card-tag" color="success" icon={<CheckCircleOutlined style={{ color: 'inherit' }} />} >Votre carte a été vérifiée</Tag>;
    };

    const renderCpsIdentification = (): ReactElement => {
        return <>
            <Form.Item valuePropName="fileList[]" label="">
                {renderCpsField(props.uploadProgress, props.cpsPhoto)}
                <br />
                {renderTagCPSCard(props.cpsPhoto, props.user)}
            </Form.Item>
        </>;
    };


    const cpsIdentification = renderCpsIdentification();
    return <>
        <Divider plain>Identification</Divider>
        {props.pscEnabled ?
            <Row>
                <Col md={11} className="psc-choice">
                    <a href="/api/pro-sante-connect/login">
                        <img className="psc-action" src={pscImage} />
                    </a>
                </Col>
                <Col md={2} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>ou</Col>
                <Col md={11} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    {cpsIdentification}
                </Col>
            </Row> : cpsIdentification}
    </>;
}
