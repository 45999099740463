import { memo, ReactElement } from 'react';
import TasksComponent from './component';

const Tasks = (): ReactElement => {
    const onSave = () => {
        console.log('r');
    };

    return <TasksComponent save={onSave} />;
};

export default memo(Tasks);
