import axios, { AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders } from 'axios';
import ApiError from 'exceptions/api.exception';
import ApiSessionError from 'exceptions/api.session.exception';
import FormData from 'form-data';
import { IRoute } from 'routes';

export interface IApi {
    login: (credentials: ILoginParams) => Promise<ISession>;
    proSanteConnectLogin: (pscToken: string) => Promise<ISession>;
    register: (credentials: IRegisterParams) => Promise<IUser>;
    requestPasswordReset: (credentials: IRequestResetParams) => Promise<void>;
    resetPassword: (credentials: IResetParams, token?: string) => Promise<void>;
    searchRpps: (filters: ISearchParams, session: ISession) => Promise<IRpps[]>;
    getRpps: (filters: IRppsGetParams, session: ISession) => Promise<IUser>;
    completeAccount: (values: IAccountParams, session: ISession) => Promise<void>;
    getAccount: (session: ISession) => Promise<IUser>;
    updateAccountSecurity: (payload: IAccountSecurityParams, session: ISession) => Promise<void>;
    requestEmailCode: (payload: IRequestResetParams, session: ISession) => Promise<void>;
    verifyCode: (code: IValidationCodeParams, session: ISession) => Promise<void>;
    validateCode: (code: IValidationCodeParams, session: ISession) => Promise<void>;
    verifyAccessToken: (session: ISession) => Promise<void>;
    refreshAccessToken: (session: ISession) => Promise<ISession>;
    sendToken: (session: ISession) => Promise<void>;
    createGroup: (group: IGroupParams, session: ISession) => Promise<IGroup>;
    updateGroup: (group: IGroupParams, session: ISession) => Promise<void>;
    removeUserFromGroup: (userId: IUser['id'], groupId: IGroup['id'], session: ISession) => Promise<void>;
    leaveGroup: (groupId: IGroup['id'], session: ISession) => Promise<void>;
    requestGroupJoining: (groupId: IGroup['id'], session: ISession) => Promise<void>;
    invitePersonInGroup: (personId: IPerson['id'], groupId: IGroup['id'], session: ISession) => Promise<void>;
    getGroupRequests: (groupId: IGroup['id'], session: ISession) => Promise<IGroupRequest[]>;
    getGroups: (payload: ISearchParams, session: ISession) => Promise<IGroup[]>;
    getGroup: (groupId: IGroup['id'], session: ISession) => Promise<IGroup>;
    getUser: (userId: IUser['id'], session: ISession) => Promise<IUser>;
    deleteGroupRequest: (groupRequest: IGroupRequestParams, session: ISession) => Promise<void>;
    declineGroupInvitation: (groupInvitation: IGroupInvitationParams, session: ISession) => Promise<void>;
    acceptGroupInvitation: (groupInvitation: IGroupInvitationParams, session: ISession) => Promise<void>;
    getGroupInvitations: (session: ISession) => Promise<IGroupInvitation[]>;
    getUsers: (payload: ISearchParams, session: ISession) => Promise<IPaginatedResults<IUser>>;
    getContacts: (payload: ISearchParams, session: ISession) => Promise<IPaginatedResults<IContact>>;
    getInvoices: (payload: IQuoteSearchParams, session: ISession) => Promise<IPaginatedResults<IInvoice>>;
    getUserInvoices: (payload: ISearchParams, session: ISession) => Promise<IPaginatedResults<IUserInvoice>>;
    refreshUserInvoice: (id: number, session: ISession) => Promise<void>;
    generateUserInvoices: (session: ISession) => Promise<void>;
    setupPayment: (session: ISession) => Promise<ISetupPayment>;
    getPaymentMethods: (session: ISession) => Promise<IPaymentMethod[]>;
    getBillingAddress: (session: ISession) => Promise<IBillingAddress>;
    updateBillingAddress: (address: IBillingAddress, session: ISession) => Promise<void>;
    getInvoicesCsv: (payload: IQuoteSearchParams, session: ISession) => Promise<Buffer>;
    printInvoice: (payload: number, session: ISession) => Promise<Buffer>;
    deleteContact: (contactId: number, session: ISession) => Promise<void>;
    getPersons: (payload: ISearchParams, session: ISession) => Promise<IPerson[]>;
    getDocuments: (documentRole: DocumentRole, offset: number, limit: number, session: ISession) => Promise<IGroupDocument[]>;
    getDocument: (documentId: IDocument['id'], session: ISession) => Promise<[Blob, AxiosResponseHeaders | undefined]>;
    getDocumentMetadata: (documentId: IDocument['id'], session: ISession) => Promise<IDocument>;
    uploadDocument: (document: File, session: ISession, onUploadProgress?: (progressEvent: ProgressEvent) => void) => Promise<IDocument>;
    shareDocumentWithGroup: (documentId: IDocument['id'], groupId: IGroup['id'], session: ISession) => Promise<IGroupDocument>;
    shareDocumentWithEmail: (documentId: IDocument['id'], email: string, session: ISession) => Promise<IGroupDocument>;
    updateDocument: (document: IDocument, session: ISession) => Promise<void>;
    deleteDocument: (documentId: IDocument['id'], groupId: IGroup['id'], session: ISession) => Promise<void>;
    getExpertises: (role: ExpertiseRole, statuses: IExpertise['status'][], session: ISession, offset?: number) => Promise<IExpertise[]>;
    createExpertiseFromPerson: (payload: IExpertiseParams, session: ISession) => Promise<IExpertise>;
    createExpertiseFromEmail: (payload: IExpertiseParams, session: ISession) => Promise<IExpertise>;
    getExpertise: (expertiseId: IExpertise['id'], session: ISession) => Promise<IExpertise>;
    updateExpertise: (expertiseId: IExpertise['id'], session: ISession, patientId?: IPatient['id'] | null) => Promise<IExpertise>;
    getMessagesFromExpertise: (expertiseId: IExpertise['id'], session: ISession, fromLastMessage?: IExpertiseMessage['id']) => Promise<IExpertiseMessage[]>;
    sendMessageToExpertise: (expertiseId: IExpertise['id'], payload: IExpertiseMessageParams, session: ISession, fromLastMessage?: IExpertiseMessage['id']) => Promise<IExpertiseMessage>;
    setMessageAsRead: (expertiseId: IExpertise['id'], expertiseMessageId: IExpertiseMessage['id'], session: ISession) => Promise<ISeenMessage>;
    createPatient: (patient: IPatientParams, session: ISession) => Promise<IPatient>;
    createPatients: (patients: IPatientParams[], session: ISession) => Promise<void>;
    updatePatient: (patientId: IPatient['id'], patient: IPatientParams, session: ISession) => Promise<IPatient>;
    getPatients: (payload: ISearchPatientParams, session: ISession) => Promise<IPaginatedResults<IPatient>>;
    linkDocumentToExpertise: (documentId: IDocument['id'], expertiseId: IExpertise['id'], session: ISession) => Promise<void>;
    finalizeExpertise: (expertiseId: IExpertise['id'], replierId: IPerson['id'], session: ISession) => Promise<void>;
    archiveExpertise: (expertiseId: IExpertise['id'], replierId: IPerson['id'], session: ISession) => Promise<void>;
    exportPdf: (expertiseId: IExpertise['id'], session: ISession) => Promise<Buffer>;
    getDocumentFromExpertise: (expertiseId: IExpertise['id'], documentId: IDocument['id'], session: ISession) => Promise<[Blob, AxiosResponseHeaders | undefined]>;
    deleteDocumentFromExpertise: (expertiseId: IExpertise['id'], documentId: IDocument['id'], session: ISession) => Promise<void>;
    getDocumentFromExpertiseMetadata: (expertiseId: IExpertise['id'], documentId: IDocument['id'], session: ISession) => Promise<IDocument>;
    updateProfileStatus: (userId: IUser['id'], status: IUser['status'], session: ISession) => Promise<IUser>;
    getNotifications: (userId: IUser['id'], session: ISession) => Promise<INotifications>;
    getNotification: (notificationId: string, token: string, force: boolean, session: ISession) => Promise<INotification>;
    getConstants: () => Promise<IConstants>;
}

export async function request(
    route: IRoute,
    params?: Record<string, any>,
    // eslint-disable-next-line
    data?: any,
    optionsSupp?: AxiosRequestConfig,
    file?: File,
    accessToken?: string,
    onUploadProgress?: (progressEvent: ProgressEvent) => void,
): Promise<AxiosResponse<any, any> | undefined> {

    const options: AxiosRequestConfig = {
        data,
        headers: accessToken ? {
            Authorization: 'Bearer ' + accessToken,
        } : undefined,
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        method: route.method,
        proxy: false,
        ...optionsSupp,
    };

    if (file) {
        const form = new FormData();
        form.append('file', file);
        options.data = form;
    }

    try {
        return await axios({
            url: getRequestUrl(route, params),
            onUploadProgress,
            ...options,
        });
    } catch (error: any) {
        handleResponseError(error, error.response);
    }
}

export function getRequestUrl(route: IRoute, params: any = {}): string {
    const routeReplaced = {
        ...route,
        path: replaceInPath(route.path, params),
    };
    return getApiHost() + routeReplaced.path;
}

export function getApiHost(): string {
    return document.location.origin + '/api';
}

// eslint-disable-next-line
export function replaceInPath(path: string, params: any): string {
    let pathReplaced = path;
    let queryParams = '';
    Object.keys(params).forEach((key) => {
        if (pathReplaced.indexOf('{' + key + '}') !== -1) {
            pathReplaced = pathReplaced.replace(
                '{' + key + '}',
                params[key],
            );
        } else {
            if (!queryParams) {
                queryParams = '?';
                queryParams += key + '=' + params[key];
            } else {
                queryParams += '&' + key + '=' + params[key];
            }
        }
    });
    return pathReplaced + queryParams;
}

function handleResponseError(error: any, response?: AxiosResponse) {
    if (!response) {
        throw new Error(error);
    }

    const { status } = response;

    if ([401].includes(status)) {
        throw new ApiSessionError(error);
    }

    if (status < 200 || status >= 300) {
        throw new ApiError(response.request.responseType === 'arraybuffer' ? JSON.parse(new TextDecoder('utf-8').decode(error.response.data)) : response.data);
    }
}
