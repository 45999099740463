import AuthenticationComponent from './component';
import React, { memo, ReactElement } from 'react';
import pages from 'pages';
import { useTabNavigation } from 'services/helpers';

export enum Tab {
    Login,
    Register,
}

const AuthPages = {
    [pages.registrationWithValidation.path]: Tab.Register,
    [pages.registration.path]: Tab.Register,
    [pages.login.path]: Tab.Login,
};

const AuthPagesReversed: Record<number, string> = {
    [Tab.Register]: pages.registration.path,
    [Tab.Login]: pages.login.path,
};

const Authentication = (): ReactElement => {
    const { onNavigate, tab } = useTabNavigation(AuthPages, AuthPagesReversed, pages.login);
    return <AuthenticationComponent navigate={onNavigate} tab={tab} />;
};

export default memo(Authentication);
