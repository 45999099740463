import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducers';
import { routes } from 'routes';
import { useApiErrorHandling } from 'services/helpers';
import Api from '../../services/api';
import { getRequestUrl } from '../../services/api.interface';
import { useSessionActions } from './session.action';

// eslint-disable-next-line
export function useDocumentActions() {
    const { refreshSession } = useSessionActions();
    const errorHandling = useApiErrorHandling(refreshSession);
    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const { session } = mapStateToObj;

    return {
        getOwnDocuments: async (offset: number, limit: number): Promise<IGroupDocument[]> => {
            return Api.getDocuments('OWNER', offset, limit, session).catch(errorHandling);
        },
        getSharedDocuments: async (offset: number, limit: number): Promise<IGroupDocument[]> => {
            return Api.getDocuments('RECIPIENT', offset, limit, session).catch(errorHandling);
        },
        getDocument: async (documentId: number): Promise<IDocumentMeta> => {
            const [content, headers] = await Api.getDocument(documentId, session).catch(errorHandling);
            return {
                content: content,
                mimetype: headers ? headers['content-type'] : '',
            };
        },
        getDocumentContent: async (documentId: IDocument['id']): Promise<Blob> => {
            const [content] = await Api.getDocument(documentId, session).catch(errorHandling);
            return content;
        },
        getDocumentMetadata: async (documentId: IDocument['id']): Promise<IDocument> => {
            return Api.getDocumentMetadata(documentId, session).catch(errorHandling);
        },
        uploadDocument: async (document: File, onUploadProgress?: (progressEvent: ProgressEvent) => void): Promise<IDocument> => {
            return Api.uploadDocument(document, session, onUploadProgress).catch(errorHandling);
        },
        shareDocumentWithPerson: async (document: IDocument, person: IPerson): Promise<IGroupDocument> => {
            if (['UserEntity', 'GroupEntity'].includes(person.type)) {
                return Api.shareDocumentWithGroup(document.id, person.id, session).catch(errorHandling);
            } else {
                if (!person.email) throw new Error('Person must have an email');
                return Api.shareDocumentWithEmail(document.id, person.email, session).catch(errorHandling);
            }
        },
        getUploadUrl: (): string => {
            return getRequestUrl(routes.uploadDocument);
        },
        updateDocument: async (document: IDocument): Promise<void> => {
            return Api.updateDocument(document, session).catch(errorHandling);
        },
        deleteDocument: async (documentId: IDocument['id'], personId?: IPerson['id']): Promise<void> => {
            return Api.deleteDocument(documentId, personId || session.user?.id, session).catch(errorHandling);
        },
    };
}
