import { ColumnsType } from 'antd/lib/table';
import Money from './money';
import { Button, Space, Tag } from 'antd';
import { DownloadOutlined, RedoOutlined } from '@ant-design/icons';
import { useSessionActions } from '../../redux/actions/session.action';
import { useState } from 'react';

interface IStatusProps {
    status: string
}
const InvoiceStatus = ({status}: IStatusProps) => {
    const map = new Map();
    map.set('open', {color: 'processing', text: 'En attente'});
    map.set('draft', {color: 'processing', text: 'Brouillon'});
    map.set('paid', {color: 'success', text: 'Payée'});

    const data = map.get(status);

    return <Tag color={data.color}>{data.text}</Tag>;
};

export function getColumns(): ColumnsType<IUserInvoice> {
    const {isAdmin, refreshUserInvoice} = useSessionActions();
    const [refreshLoadings, setRefreshLoadings] = useState<number[]>([]);

    const isRefreshLoading = (id: number) => refreshLoadings.includes(id);
    const setRefreshLoading = (id: number, value: boolean) => {
        if(value) {
            setRefreshLoadings([...refreshLoadings, id]);
        } else {
            setRefreshLoadings(refreshLoadings.filter(i => i !== id));
        }
    };
    return [
        {
            title: 'Numéro',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (value: Date) => {
                if (!value) return null;
                value = new Date(value);
                return value.toLocaleDateString();
            },
        },
        {
            title: 'Statut',
            dataIndex: 'status',
            key: 'status',
            render: (value: string) => {
                return <InvoiceStatus status={value}/>;
            },
        },
        {
            title: 'Montant HT',
            align: 'right',
            dataIndex: 'amount',
            key: 'amount',
            render: (value: number) => {
                return <Money amount={value}/>;
            },
        },
        {
            title: 'TVA',
            dataIndex: 'taxes',
            align: 'right',
            key: 'taxes',
            render: (value: number) => {
                return <Money amount={value}/>;
            },
        },
        {
            title: 'Total',
            align: 'right',
            key: 'total',
            render: (invoice: IUserInvoice) => {
                return <strong><Money amount={invoice.amount + invoice.taxes}/></strong>;
            },
        },
        {
            title: 'Actions',
            align: 'right',
            render: (invoice: IUserInvoice) => {
                return <Space>
                    {invoice.isPaid ?
                        invoice.pdfUrl && <Button
                            icon={<DownloadOutlined />}
                            shape="round"
                            type={'primary'}
                            size={'small'}
                            href={invoice.pdfUrl}
                            title={'Télécharger la facture'}
                        >
                            Télécharger
                        </Button>
                        :
                        invoice.url &&
                        <Button
                            shape="round"
                            type={'primary'}
                            size={'small'}
                            target={'_blank'}
                            href={invoice.url}
                            title={'Aller à la page de paiement'}>Payer</Button>
                    }
                    {isAdmin() && <Button
                        icon={<RedoOutlined />}
                        onClick={async () => {
                            setRefreshLoading(invoice.id, true);
                            await refreshUserInvoice(invoice.id);
                            setTimeout(() => {
                                setRefreshLoading(invoice.id, false);
                            }, 1200);
                        }}
                        shape="circle"
                        loading={isRefreshLoading(invoice.id)}
                        type={'primary'}
                        size={'small'}
                        title={'Mettre à jour depuis stripe'}/>}
                </Space>;
            },
        },

    ];
}
