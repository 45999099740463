import { memo, ReactElement, useEffect, useState } from 'react';
import { dataLayer, useMessage } from 'services/helpers';
import DocumentVisuComponent from './component';

interface IProps {
    message?: IMessage;
    document?: IDocument;
    expertise?: IExpertise;
    getDocument: () => Promise<IDocumentMeta>;
}

const Viewer = (props: IProps): ReactElement => {
    const [documentMeta, setDocumentMeta] = useState<IDocumentMeta>();
    const [message, setMessage] = useMessage();

    const documentId = props.document?.id;

    useEffect(() => {
        if(!documentId) {
            return;
        }

        dataLayer({
            'event': 'document-view',
            'documentId': documentId,
        });
    }, [documentId]);

    useEffect(() => {
        setMessage(props.message);
    }, [props.message]);

    useEffect(() => {
        (async () => {
            setMessage(undefined);
            setDocumentMeta(await props.getDocument());
        })().catch((err: Error) => {
            setMessage(err);
        });
    }, [props.document]);

    if (!props.document || !props.document.id) {
        return <></>;
    }

    return <>
        <DocumentVisuComponent
            document={props.document}
            documentMeta={documentMeta}
            message={message}
        />
    </>;
};

export default memo(Viewer);
