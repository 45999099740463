import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Input, Row, Table, Tooltip, Upload } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { ReactElement, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { renderMessage } from 'services/helpers';
import { useDebouncedCallback } from 'use-debounce';
import { AppState } from '../../redux/reducers';
import { routes } from '../../routes';
import './index.less';

interface IProps {
    contacts: IContact[];
    message?: IMessage;
    deleteContact: (contact: IContact) => void;
    changePagination: (pageination: TablePaginationConfig) => void;
    search: (search?: string) => Promise<void>;
    pagination?: IPagination;
}

const ContactsComponent = (props: IProps): ReactElement | null => {
    const [filteredData, setFilteredData] = useState<IContact[]>();
    const session = useSelector((state: AppState) => {
        return {
            accessToken: state.session.accessToken,
        };
    });

    const columns: ColumnsType<IContact> = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Prénom',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Nom',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '6rem',
            render: (contact: IContact) => (
                <div className='actions'>
                    <Tooltip title="Désactiver">
                        <Button
                            className="action-button"
                            type="primary"
                            onClick={() => onDeleteContact(contact)}
                            danger
                            icon={<CloseOutlined />}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const onDeleteContact = (contact: IContact) => {
        props.deleteContact(contact);
    };

    const onChangePagination = (pagination: TablePaginationConfig) => {
        props.changePagination(pagination);
    };

    const debounced = useDebouncedCallback(
        async (value?: string) => {
            await props.search(value);
        }, 400,
    );

    const handleSearch = async (value?: string) => {
        debounced(value)?.catch((err: Error) => {
            console.log(err);
        });
    };

    useEffect(() => {
        setFilteredData(props.contacts);
    }, [props.contacts]);

    const onBeforeUploadPhoto = (file: File): string | boolean => {
        return ![
            'text/csv',
        ].includes(file.type) ? Upload.LIST_IGNORE : true;
    };

    const renderCSVButton = (): ReactElement => {
        return <Upload
            className="upload"
            showUploadList={false}
            beforeUpload={onBeforeUploadPhoto}
            action={'/api' + routes.uploadContact.path}
            headers={{
                Authorization: 'Bearer ' + session.accessToken,
            }}
        >
            <Button >
                <UploadOutlined /> {'Importer un fichier CSV'}
            </Button>
        </Upload>;
    };


    return <Card title='Contacts' className='accounts-administration' >
        <Row>
            {renderCSVButton()}
            &nbsp;
            <Alert
                message={'Le fichier doit être composé de 3 colonnes : email, prénom et nom. Il ne doit pas avoir d\'entête et doit être séparé par des points-virgules.'}
                type={'warning'}
            />
        </Row>

        <Input
            placeholder="Rechercher un contact"
            onChange={(e) => handleSearch(e.target.value)}
        />
        {renderMessage(props.message)}
        <Table
            columns={columns}
            dataSource={filteredData}
            pagination={props.pagination}
            onChange={onChangePagination}
        />
    </Card>;
};

export default memo(ContactsComponent);
