import pages from 'pages';
import { memo, ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { useSessionActions } from 'redux/actions/session.action';
import { useMessage } from 'services/helpers';
import LoginComponent from './component';
import { useAppActions } from 'redux/actions/app.action';

const Login = (): ReactElement | null => {
    const navigate = useNavigate();
    const { login, sendToken, updateSession, getRedirectUrl } = useSessionActions();
    const { constants } = useAppActions();

    const [message, setMessage] = useMessage();

    const logUser = async (credentials: ILoginParams) => {
        try {
            const session = await login(credentials).catch((err: Error) => {
                throw err;
            });

            updateSession(session);

            const { user } = session;

            if (!user) {
                throw new Error('User undefined');
            }

            const { status } = user;

            if (['INACTIVE', undefined].includes(status)) {
                throw new Error('Account not active');
            }

            const redirectUrl = getRedirectUrl();

            if (status === 'NOT_VERIFIED') {
                await sendToken(session).catch((err: Error) => {
                    throw err;
                });
                navigate(pages.registrationWithValidation.path);
            }
            else if (status === 'VERIFIED') {
                navigate(redirectUrl ?? pages.account.path);
            }
            else {
                navigate(redirectUrl ?? pages.expertisesSent.path);
            }
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    return <LoginComponent
        login={logUser}
        message={message}
        pscEnabled={constants.isProSanteConnectEnabled}
    />;
};

export default memo(Login);
