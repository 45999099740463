import React, { memo, ReactElement, useEffect, useState } from 'react';
import HomeComponent from 'components/home/component';
import './index.less';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers';
import { useSessionActions } from '../../../redux/actions/session.action';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Space, Spin } from 'antd';

interface IProps {
    id: string,
    token: string
}
function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

enum State {
    DEFAULT,
    FORCING,
    ACCESS_DENIED
}

const NotificationComponent = ({token, id}: IProps): ReactElement => {
    const location = useLocation();
    const user = useSelector((state: AppState) => {
        return state.session.user;
    });

    const [message, setMessage] = useState('Vérification en cours');
    const [forcing, shouldForce] = useState(false);
    const [force, setForce] = useState(false);
    const [state, setState] = useState(State.DEFAULT);

    const navigate = useNavigate();
    const {setRedirectUrl, getNotification, logout} = useSessionActions();

    useEffect(() => {
        if(!user) { // user is not authenticated, so save this url and redirect to login / register
            setRedirectUrl([
                location.pathname,
                location.hash,
            ].join(''));

            void delay(850).then(() => {
                setMessage('Vous allez être redirigé pour vous authentifier');

                return delay(1500).then(() => {
                    navigate('/');
                });
            });

            return;
        }

        getNotification(id, token, force).then(notification => {
            setRedirectUrl(undefined);
            window.location.replace(notification.url);
        }).catch(({error}) => {
            if(error.statusCode === 303) {
                setMessage(`Vous essayez d'accéder à une resource qui a été envoyée à ${error.to}, êtes-vous sûr de vouloir l'associer à votre compte ?`);
                shouldForce(true);
            } else if(error.statusCode === 403) {
                setMessage('Accès refusé, votre compte n\'a pas accès à ce document. Merci de vous connecter avec le compte correspondant.');
                setState(State.ACCESS_DENIED);
            } else {
                setMessage('Une erreur est survenue');
            }
        });
    }, [id, token, user, force]);

    return <HomeComponent className="notification">
        <Space direction={'vertical'}>
            <Spin tip={message} />
            {forcing &&
            <div className={'actions'}>
                <Button onClick={() => {
                    setRedirectUrl(undefined);
                    navigate('/');
                }}>Non</Button>
                <Button type='primary' onClick={() => {
                    setForce(true);
                }}>Oui</Button>
            </div>}

            {state === State.ACCESS_DENIED && <div className={'actions'}>
                <Button onClick={() => {
                    setRedirectUrl(undefined);
                    navigate('/expertises/sent');
                }}>Retour</Button>
                <Button type="primary" onClick={() => {
                    logout();
                    navigate('/');
                }}>Changer de compte</Button>
            </div>}
        </Space>
    </HomeComponent>;
};

export default memo(NotificationComponent);
