import { useVitale } from './vitale-hook';
import carteVitale from 'assets/images/carte-vitale.png';
import './vitale-button.less';
import { notification } from 'antd';
import { ReactElement, useState } from 'react';
import { VitalePatientSelectModal } from './patients-modal';
import clsx from 'clsx';

interface IProps {
    onSelect?: (patient: IPatientParams) => void;
}

export const VitaleButton = ({onSelect}: IProps) => {
    const {isLoading, isAvailable, readCard} = useVitale({});
    const [modal, setModal] = useState<ReactElement | null>(null);

    if(!isAvailable) {
        return null;
    }

    return <>
        {modal}
        <button type="button" title="Lecture de carte vitale" onClick={() => {
            if (isLoading) {
                return;
            }

            readCard().then((patients) => {
                if(patients.length === 1) {
                    onSelect && onSelect(patients[0]);
                } else {
                    setModal(<VitalePatientSelectModal patients={patients} cancel={() => setModal(null)} onValidate={(index) => {
                        onSelect && onSelect(patients[index]);
                        setModal(null);
                    }}/>);
                }
            }).catch(e => {
                notification.error({
                    message: e.message,
                });
            });
        }}
        className={clsx('ant-btn ant-btn-default carte-vitale', {'ant-btn-loading': isLoading})}>
            {isLoading && <span className="ant-btn-loading-icon">
                <span role="img" aria-label="loading"
                    className="anticon anticon-loading anticon-spin">
                    <svg
                        viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path
                            d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                </span>
            </span>}
            <img src={carteVitale} alt={'Carte vitale'}/>
        </button>
    </>
    ;
};
