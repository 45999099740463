import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducers';
import Api from 'services/api';
import { useApiErrorHandling } from 'services/helpers';
import { useSessionActions } from './session.action';

// eslint-disable-next-line
export function useExpertiseActions() {
    const { refreshSession } = useSessionActions();
    const errorHandling = useApiErrorHandling(refreshSession);
    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const { session } = mapStateToObj;

    return {
        getRequesterExpertises: async (statuses: IExpertise['status'][], offset?: number): Promise<IExpertise[]> => {
            return Api.getExpertises('REQUESTER', statuses, session, offset).catch(errorHandling);
        },
        getRecipientExpertises: async (statuses: IExpertise['status'][], offset?: number): Promise<IExpertise[]> => {
            return Api.getExpertises('RECIPIENT', statuses, session, offset).catch(errorHandling);
        },
        createExpertiseFromPerson: async (payload: IExpertiseParams): Promise<IExpertise> => {
            return Api.createExpertiseFromPerson(payload, session).catch(errorHandling);
        },
        createExpertiseFromEmail: async (payload: IExpertiseParams): Promise<IExpertise> => {
            return Api.createExpertiseFromEmail(payload, session).catch(errorHandling);
        },
        getExpertise: async (expertiseId: IExpertise['id']): Promise<IExpertise> => {
            return Api.getExpertise(expertiseId, session).catch(errorHandling);
        },
        updateExpertise: async (expertiseId: IExpertise['id'], patientId?: IPatient['id'] | null): Promise<IExpertise> => {
            return Api.updateExpertise(expertiseId, session, patientId).catch(errorHandling);
        },
        getMessagesFromExpertise: async (expertiseId: IExpertise['id'], fromLastMessage?: IExpertiseMessage['id']): Promise<IExpertiseMessage[]> => {
            return Api.getMessagesFromExpertise(expertiseId, session, fromLastMessage).catch(errorHandling);
        },
        sendMessageToExpertise: async (expertiseId: IExpertise['id'], payload: IExpertiseMessageParams): Promise<IExpertiseMessage> => {
            return Api.sendMessageToExpertise(expertiseId, payload, session).catch(errorHandling);
        },
        setMessageAsRead: async (expertiseId: IExpertise['id'], messageId: IExpertiseMessage['id']): Promise<ISeenMessage> => {
            return Api.setMessageAsRead(expertiseId, messageId, session).catch(errorHandling);
        },
        linkDocumentToExpertise: async (documentId: IDocument['id'], expertiseId: IExpertise['id']): Promise<void> => {
            return Api.linkDocumentToExpertise(documentId, expertiseId, session).catch(errorHandling);
        },
        finalizeExpertise: async (expertiseId: IExpertise['id'], replierId: IPerson['id']): Promise<void> => {
            return Api.finalizeExpertise(expertiseId, replierId, session).catch(errorHandling);
        },
        archiveExpertise: async (expertiseId: IExpertise['id'], replierId: IPerson['id']): Promise<void> => {
            return Api.archiveExpertise(expertiseId, replierId, session).catch(errorHandling);
        },
        exportPdf: async (expertiseId: IExpertise['id']): Promise<Buffer> => {
            return Api.exportPdf(expertiseId, session).catch(errorHandling);
        },
        getDocumentFromExpertise: async (expertiseId: IExpertise['id'], documentId: IDocument['id']): Promise<IDocumentMeta> => {
            const [content, headers] = await Api.getDocumentFromExpertise(expertiseId, documentId, session).catch(errorHandling);
            return {
                content: content,
                mimetype: headers ? headers['content-type'] : '',
            };
        },
        deleteDocumentFromExpertise: async (expertiseId: IExpertise['id'], documentId: IDocument['id']): Promise<void> => {
            return Api.deleteDocumentFromExpertise(expertiseId, documentId, session).catch(errorHandling);
        },
        getDocumentFromExpertiseContent: async (expertiseId: IExpertise['id'], documentId: IDocument['id']): Promise<Blob> => {
            const [content] = await Api.getDocumentFromExpertise(expertiseId, documentId, session).catch(errorHandling);
            return content;
        },
        getDocumentFromExpertiseMetadata: async (expertiseId: IExpertise['id'], documentId: IDocument['id']): Promise<IDocument> => {
            return Api.getDocumentFromExpertiseMetadata(expertiseId, documentId, session).catch(errorHandling);
        },
    };
}
