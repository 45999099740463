import { renderDocumentUploadModal } from 'components/document/upload-modal';
import pages from 'pages';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { memo, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocumentActions } from 'redux/actions/document.action';
import { useSessionActions } from 'redux/actions/session.action';
import { useMessage } from 'services/helpers';
import AccountComponent from './component';
import { useAppActions } from '../../redux/actions/app.action';

const Account = (): ReactElement | null => {
    const navigate = useNavigate();
    const { completeAccount, refreshSession, updateSessionUser } = useSessionActions();
    const { getDocument } = useDocumentActions();
    const [message, setMessage] = useMessage();
    const [uploadProgress, setUploadProgress] = useState<number>();
    const [cpsPhoto, setCpsPhoto] = useState<IDocumentMeta>();
    const [cps, setCps] = useState<IDocument>();
    const [user, setUser] = useState<IUser>();
    const {constants} = useAppActions();
    const pscEnabled = constants.isProSanteConnectEnabled;
    const { getAccount } = useSessionActions();

    useEffect(() => {
        Promise.all([
            getAccount(),
        ])
            .then(([account]) => {
                setUser(account);
                return account;
            })
            .then((account) => {
                return account.idCard;
            })
            .then(idCard => {
                if (idCard && idCard.id) return getDocument(idCard.id);
            })
            .then((document) => {
                return setCpsPhoto(document);
            })
            .catch((err: Error) => {
                console.error(err);
                setMessage(err);
            });
    }, []);

    const onSave = async (values: IAccountParams) => {
        console.log(values);
        await completeAccount({
            ...values,
            idCard: cps?.id,
        })
            .then(() => {
                return getAccount();
            })
            .then((profile) => {
                updateSessionUser(profile);
            })
            .catch((err: Error) => {
                setMessage(err);
                return;
            });

        if (user?.status !== 'ACTIVE' && !user?.idCard && !cps?.id) {
            setMessage('Vous devez importer la photo de votre carte CPS pour accéder aux expertises', 'warning');
            return;
        }

        navigate(pages.expertisesSent.path);
    };

    const onUploadPhoto = async (uploadRequestOptions: UploadRequestOption) => {
        renderDocumentUploadModal({
            uploadRequestOptions,
            afterUpload: onAfterUpload,
            uploadProgress: onUploadProgress,
        });
    };

    const onAfterUpload = async (documentIn: IDocument) => {
        if (!documentIn.id) return;
        try {
            setCps(documentIn);
            setCpsPhoto(await getDocument(documentIn.id).catch((err: Error) => {
                throw err;
            }));
            setUploadProgress(undefined);
            if (!user) return;
            await completeAccount({
                rpps: user.rpps,
                idCard: Number(documentIn.id),
            }).catch((err: Error) => {
                throw err;
            });

            setUser(await getAccount());

            await refreshSession();
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onUploadProgress = (loaded: number, total: number) => {
        setUploadProgress(loaded / total * 100);
    };

    if (!user) return null;
    return <AccountComponent
        save={onSave}
        message={message}
        cpsPhoto={cpsPhoto}
        user={user}
        uploadPhoto={onUploadPhoto}
        uploadProgress={uploadProgress}
        pscEnabled={pscEnabled}
    />;
};

export default memo(Account);
