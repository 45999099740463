import PasswordResetComponent from './component';
import React, { memo, ReactElement } from 'react';
import { useSessionActions } from 'redux/actions/session.action';
import { useMessage, useRequestParams } from 'services/helpers';
import { useNavigate } from 'react-router';
import pages from 'pages';

interface IResetTokenParams {
    token: string;
}

const PasswordReset = (): ReactElement | null => {
    const navigate = useNavigate();
    const { resetPassword } = useSessionActions();
    const requestParams = useRequestParams<IResetTokenParams>();

    const [message, setMessage] = useMessage();

    const onSavePassword = async (credentials: IPasswordResetParams) => {
        try {
            const { password, passwordConfirmation } = credentials;

            if (password !== passwordConfirmation) {
                throw new Error('Les mots de passe ne correspondent pas');
            }

            await resetPassword(credentials, requestParams.token).catch((err: Error) => {
                throw err;
            });

            navigate(pages.login.path);
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    return <PasswordResetComponent
        message={message}
        savePassword={onSavePassword}
    />;
};

export default memo(PasswordReset);
