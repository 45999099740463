import { useVitale } from './vitale-hook';
import { Button } from 'antd';

export const VitaleInstallButton = () => {
    const {isAvailable: isVitaleAvailable, downloadSoftware} = useVitale({});

    if(isVitaleAvailable) {
        return null;
    }

    return <Button onClick={downloadSoftware}>Télécharger notre logiciel de lecture de carte vitale</Button>;
};
