import {
    BulbOutlined,
    ContactsOutlined,
    EuroOutlined,
    FileOutlined,
    InboxOutlined,
    LockOutlined,
    LogoutOutlined,
    MenuOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Badge, Button, Card, Dropdown, Layout, Menu, Upload } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import logo from 'assets/images/logo.svg';
import { renderDocumentUploadModal } from 'components/document/upload-modal';
import pages from 'pages';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import React, { memo, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSessionActions } from 'redux/actions/session.action';
import { useUsersActions } from 'redux/actions/users.action';
import { AppState } from 'redux/reducers';
import { Services } from 'services/services';
import './index.less';
import { dataLayer, tawkLogin } from 'services/helpers';
import { useAppActions } from 'redux/actions/app.action';
import { VitaleContextProvider } from 'components/vitale/vitale-context';
import moment from 'moment';

const { Header, Content, Sider } = Layout;
const { Dragger } = Upload;

moment.locale('fr', {
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    calendar: {
        sameDay: '[Aujourd’hui à] LT',
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans',
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4,  // Used to determine first week of the year.
    },
});
moment.locale('fr');

interface IProps {
    component: any;
}

const RouteServicesMap = {
    [pages.myDocuments.path]: Services.MyDocuments,
    [pages.documentsSharedWithMe.path]: Services.DocumentsSharedWithMe,
    [pages.experts.path]: Services.Experts,
    [pages.patients.path]: Services.Patients,
    [pages.billing.path]: Services.Billing,
    [pages.expertisesSent.path]: Services.ExpertisesSent,
    [pages.expertisesReceived.path]: Services.ExpertisesReceived,
};

const sortedPathesByLength = Object.keys(RouteServicesMap).sort((a: string, b: string) => b.length - a.length);

const ServicesLayout = (props: IProps): ReactElement | null => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { verifyAccessToken, logout } = useSessionActions();
    const { getNotifications, hasEmail } = useUsersActions();
    const [isTokenValid, setTokenValid] = useState<boolean>(false);
    const { constants, fetchConstants } = useAppActions();
    const [notifications, setNotifications] = useState<INotifications>();
    const { version, cguUrl } = constants;
    const isUserActive = useSelector((state: AppState) => {
        return state.session.user?.status === 'ACTIVE';
    });
    const {user, tawkHash} = useUsersActions();
    const [isMenuShown, setMenuShown] = useState<boolean>(false);

    const isAdmin = useSelector((state: AppState) => {
        return state.session.user?.roles?.includes('ADMIN');
    });

    const userId = user?.id;

    const getMenu = useCallback(() => {
        const route = sortedPathesByLength.find((path: string) => {
            return pathname.match(new RegExp(path));
        });
        return route && route in RouteServicesMap ? RouteServicesMap[route] : undefined;
    }, [pathname]);

    const [menu, setMenu] = useState<Services | undefined>(getMenu());

    useEffect(() => {
        (async () => {
            await verifyAccessToken().catch((err: Error) => {
                throw err;
            });

            setTokenValid(true);
            await fetchConstants();
            dataLayer({
                userId,
            });

        })().catch((err: Error) => {
            console.log(err);
            onLogout();
        });
    }, []);

    useEffect(() => {
        user && tawkHash && tawkLogin({
            hash: tawkHash,    // required
            userId: `${userId}`,            // required
            name: {
                first: user.firstName,
                last: user.lastName,
            },
            email: user.email,
        }, console.error);
    }, [userId, tawkHash, user]);

    useEffect(() => {
        if(hasEmail) {
            return;
        }

        navigate('/email');
    }, [hasEmail]);

    const refreshNotifications = async () => {
        setNotifications(await getNotifications()
            .catch((err: Error) => {
                throw err;
            }));
    };

    useEffect(() => {
        let pollingInterval: NodeJS.Timer;

        (async () => {
            pollingInterval = setInterval(async () => {
                await refreshNotifications();
            }, 10000);

            await refreshNotifications();
        })().catch((err: Error) => {
            console.log(err);
            onLogout();
        });

        return () => {
            clearInterval(pollingInterval);
        };
    }, []);

    useEffect(() => {
        setMenu(getMenu());
    }, [location, getMenu]);

    const onLogout = () => {
        logout();
        navigate(pages.login.path);
    };

    const showMenu = () => {
        setMenuShown(!isMenuShown);
    };

    const onUploadDocument = async (uploadRequestOptions: UploadRequestOption) => {
        onClickMenu();
        renderDocumentUploadModal({
            uploadRequestOptions,
            onHide: hideDocumentUploadModal,
        });
    };

    const hideDocumentUploadModal = () => {
        const { myDocuments, documentsSharedWithMe } = pages;
        if ([myDocuments.path, documentsSharedWithMe.path].includes(pathname)) {
            navigate(pages.myDocuments.path, {
                state: {
                    fromUpload: true,
                },
            });
        }
    };

    const onClickMenu = () => {
        setMenuShown(false);
    };

    const renderCountItems = (nbItems?: number): ReactNode => {
        return nbItems && nbItems > 0 ? <Badge count={nbItems} size="small" offset={[5, 0]}/> : undefined;
    };

    const renderMenu = (): ReactElement | undefined => {
        const menuItems: ItemType[] = [{
            key: 'expertise',
            type: 'group',
            label: 'Expertise',
            children: [
                {
                    key: 'request',
                    className: 'item-free',
                    label: <Button type="primary" className="action" onClick={onClickMenu}>
                        <Link to={isUserActive ? pages.expertisesRequest.path : pages.expertisesPlaceholder.path}>Demander
                            un avis</Link>
                    </Button>,
                    onClick: onClickMenu,
                },
                {
                    key: Services.ExpertisesSent,
                    icon: <BulbOutlined/>,
                    label: <Link to={pages.expertisesSent.path} className="link">
                        Expertises demandées
                        {renderCountItems(notifications?.expertisesRequested)}
                    </Link>,
                    onClick: onClickMenu,
                },
                {
                    key: Services.ExpertisesReceived,
                    icon: <BulbOutlined/>,
                    label: <Link to={pages.expertisesReceived.path} className="link">
                        Expertises reçues
                        {renderCountItems(notifications?.expertisesReceived)}
                    </Link>,
                    onClick: onClickMenu,
                },
                {
                    key: Services.Patients,
                    icon: <ContactsOutlined />,
                    label: <Link to={pages.patients.path} className="link">
                        Patients
                    </Link>,
                    onClick: onClickMenu,
                },
            ],
        }, {
            key: 'documents',
            type: 'group',
            label: 'Documents',
            children: [
                {
                    key: 'upload',
                    className: 'item-free',
                    label: <Dragger className="upload" showUploadList={false} customRequest={onUploadDocument}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Importer un document</p>
                        <p className="ant-upload-hint">
                            Par glisser/déposer ou en cliquant dans la zone
                        </p>
                    </Dragger>,
                    onClick: onClickMenu,
                },
                {
                    key: Services.MyDocuments,
                    icon: <FileOutlined/>,
                    label: <Link to={pages.myDocuments.path} className="link">
                        Mes documents
                        {renderCountItems(notifications?.documents)}
                    </Link>,
                    onClick: onClickMenu,
                },
                {
                    key: Services.DocumentsSharedWithMe,
                    icon: <FileOutlined/>,
                    label: <Link to={pages.documentsSharedWithMe.path} className="link">
                        Documents reçus
                        {renderCountItems(notifications?.documents)}
                    </Link>,
                    onClick: onClickMenu,
                },
            ],
        }];

        menuItems.push(
            {
                key: 'billing',
                type: 'group',
                label: 'Facturation',
                children: [
                    {
                        key: Services.Billing,
                        icon: <EuroOutlined/>,
                        label: <Link to={pages.billing.path} className="link">Cotations</Link>,
                        onClick: onClickMenu,
                    },
                ],
            },
        );

        if (isAdmin) {
            menuItems.push(
                {
                    key: 'administration',
                    type: 'group',
                    label: 'Administration',
                    children: [
                        {
                            key: Services.AccountsAdministration,
                            icon: <UserOutlined/>,
                            label: <Link to={pages.accountsAdministration.path} className="link">Gestion des
                                comptes</Link>,
                            onClick: onClickMenu,
                        },
                        {
                            key: Services.Invoices,
                            icon: <EuroOutlined/>,
                            label: <Link to={pages.invoices.path} className="link">Factures</Link>,
                            onClick: onClickMenu,
                        },
                    ],
                },
            );
        }

        return <Sider className={'menu' + (isMenuShown ? ' shown' : ' hidden')} theme="light">
            <Card>
                <Menu mode="inline" selectedKeys={[String(menu)]} items={menuItems}/>
                <div className="footer">
                    <span className="cgu"><a href={cguUrl} target="_blank">CGU</a></span>
                    <span>|</span>
                    <span className="version">{version}</span>
                </div>
            </Card>
        </Sider>;
    };

    const { component: MyComponent } = props;

    return isTokenValid ? <Layout className="services-layout">
        <Header className="header">
            <MenuOutlined className="reducer" onClick={showMenu}/>

            <div className="logo">
                <Link className="logo-link" to={pages.login.path}>
                    <img src={logo} alt=""/>
                </Link>
            </div>

            <div className="menu">
                <a>
                    <Dropdown arrow placement="bottomRight" trigger={['click']} overlay={
                        <Menu items={[
                            {
                                key: 'settings',
                                icon: <SettingOutlined/>,
                                label: <Link to={pages.account.path}>
                                    Profil
                                </Link>,
                            },
                            {
                                key: 'billingAddress',
                                icon: <SettingOutlined />,
                                label: <Link to={pages.billingAddress.path}>
                                    Adresse de facturation
                                </Link>,
                            },
                            {
                                key: 'email',
                                icon: <LockOutlined/>,
                                label: <Link to={pages.email.path}>
                                    Email
                                </Link>,
                            },
                            {
                                key: 'security',
                                icon: <LockOutlined/>,
                                label: <Link to={pages.security.path}>
                                    Sécurité
                                </Link>,
                            },
                            {
                                key: 'contacts',
                                icon: <TeamOutlined/>,
                                label: <Link to={pages.contacts.path}>Contacts</Link>,
                            },
                            {
                                key: 'logout',
                                icon: <LogoutOutlined/>,
                                label: 'Déconnexion',
                                onClick: onLogout,
                            },
                        ]}/>
                    }>
                        <UserOutlined/>
                    </Dropdown>
                </a>
            </div>
        </Header>
        <Content className="content services">
            {renderMenu()}
            <Layout className="content">
                <VitaleContextProvider>
                    <MyComponent/>
                </VitaleContextProvider>
            </Layout>
        </Content>
    </Layout> : null;
};

export default memo(ServicesLayout);
