import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import pages from '../../pages';
import { useExpertiseActions } from '../../redux/actions/expertise.action';
import { AppState } from '../../redux/reducers';

export const Expertises = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { getExpertise } = useExpertiseActions();
    const { session } = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    useEffect(() => {
        const expertiseId = pathname.split('/')[2];
        getExpertise(expertiseId)
            .then((expertise) => {
                if (expertise.requester.id === session.user?.id) {
                    navigate(pages.expertisesSentChat.path.replace(':id', String(expertiseId)));
                } else {
                    navigate(pages.expertisesReceivedChat.path.replace(':id', String(expertiseId)));
                }
            })
            .catch(() => {
                navigate(pages.expertisesSent.path);
            });
    }, []);


    return null;
};