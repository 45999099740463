import {ReactElement} from 'react';

const currencyFormat = (num: number, precision = 2)  => {
    return num.toFixed(precision).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + '€';
};

interface IProps {
    amount: number,
    precision?: number
}

const Money = ({amount, precision = 2}: IProps): ReactElement =>
{
    return <span>{currencyFormat(amount / 100, precision)}</span>;
};

export default Money;
