import React, { createContext, useEffect, useMemo, useState } from 'react';
import { DevBoxVitale } from 'services/devbox/vitale';

interface IProps {
    isAvailable: boolean,
    devBox: DevBoxVitale
}

let timer: NodeJS.Timeout | null = null;

export const VitaleContext = createContext<IProps>({} as IProps);
export const VitaleContextProvider = ({ children }: React.PropsWithChildren) => {
    const [isAvailable, setIsDevBoxAvailable] = useState<boolean>(false);
    const devBox = useMemo(() => new DevBoxVitale(), []);

    const refreshDevBoxStatus = (timeout: number = 5000) => {
        devBox.isAvailable()
            .then((isAvailable) => setIsDevBoxAvailable(isAvailable))
            .catch(error => {
                console.error(error);
                setIsDevBoxAvailable(false);
                timer && clearTimeout(timer);
                timer = setTimeout(() => refreshDevBoxStatus(Math.min(30000, Math.max(5000, timeout) + 1000)), timeout);
            });
    };

    useEffect(() => {
        if (!isAvailable) {
            refreshDevBoxStatus();
        }

        return () => {
            timer && clearTimeout(timer);
        };
    }, [isAvailable]);

    return <VitaleContext.Provider value={{
        isAvailable: isAvailable,
        devBox,
    }}>
        {children}
    </VitaleContext.Provider>;
};
