import { Alert, AutoComplete, Empty, Spin } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { memo, ReactElement, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import './index.less';
import { renderBirthDate } from 'services/helpers';

interface IPatientOption extends DefaultOptionType {
    patient?: IPatient;
}

interface IProps {
    getPatients: (value: string) => Promise<IPatient[] | undefined>;
    selectPatient: (patient?: IPatient) => Promise<void>;
    onClearPatient: () => void;
    placeholder?: string;
    isSearching: boolean;
    value?: IPatient;
}

const PatientsSearchComponent = (props: IProps): ReactElement | null => {
    const [options, setOptions] = useState<IPatientOption[]>([]);
    const [name, setName] = useState<string | undefined>(props.value?.INS);

    const debounced = useDebouncedCallback(
        async (value: string) => {
            const patientsIn = await props.getPatients(value);
            setOptions(getPatientOptions(patientsIn || []));
        }, 400,
    );

    const onSearchPatients = async (value: string) => {
        debounced(value)?.catch((err: Error) => {
            console.log(err);
        });
    };

    const onChangePatient = async (nameIn: string) => {
        if (nameIn === '') {
            setName(undefined);
        }
        else {
            setName(nameIn);
        }
    };

    const onSelectPatient = async (_value: string, item?: IPatientOption) => {
        await props.selectPatient(item?.patient);
        setName(getPatientName(item?.patient));
    };

    const onClearPatient = async () => {
        await props.selectPatient(undefined);
        props.onClearPatient();
    };

    const getPatientName = (patient?: IPatient): string => {
        const { firstName, lastName } = patient || {};

        return [firstName, lastName].filter(v => !!v).join(' ');
    };

    const getPatientOptions = (patients: IPatient[]): IPatientOption[] => {
        return patients.map((patient: IPatient, index: number) => {
            const { id, birthDate  } = patient;
            const fullName = getPatientName(patient);

            return {
                key: 'p' + index,
                value: fullName,
                label: <div data-id={id}>
                    <div className="list-item-main-info">
                        {fullName}
                    </div>
                    <div className="list-item-secondary-info">
                        {renderBirthDate(birthDate)}
                    </div>
                </div>,
                patient: patient,
            };
        });
    };

    const renderDropdown = (menu: ReactElement): ReactElement => {
        return props.isSearching ?
            <div className='search-loading-indicator'>
                <Spin tip='Loading' />
            </div> :
            <>
                {menu}
                <Alert className="search-warning"
                    type="warning"
                    message="Affinez votre recherche pour afficher plus de résultats"/>
            </>;
    };

    return <AutoComplete
        className="patients-search"
        showSearch
        placeholder={props.placeholder ?? 'Veuillez saisir un nom ou un prénom'}
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={onSearchPatients}
        onChange={onChangePatient}
        onSelect={onSelectPatient}
        notFoundContent={props.isSearching ?
            <div className='search-loading-indicator'>
                <Spin tip='Loading' />
            </div> :
            <Empty description={<span>Pas de résultat</span>} />
        }
        allowClear
        onClear={onClearPatient}
        options={options}
        value={name}
        dropdownRender={renderDropdown}
    />;
};

export default memo(PatientsSearchComponent);
