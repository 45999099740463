import { DeleteOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, List } from 'antd';
import PersonsSearch from 'components/search/persons';
import { memo, ReactElement, useEffect, useState } from 'react';
import { useDocumentActions } from 'redux/actions/document.action';
import './index.less';
import Viewer from './viewer';

interface IProps {
    message?: IMessage;
    document: IUploadedDocument;
    update: (document: IDocument, persons: IPerson[]) => void;
}


interface IUsePersons {
    persons: IPerson[];
    onSelectPerson: (person?: IPerson) => void;
    setPersons: (persons: IPerson[]) => void;
    renderPersons: () => ReactElement;
}

export function usePersons(initialPersons: IPerson[] = [], removePerson?: (person: IPerson) => void): IUsePersons {
    const [persons, setPersons] = useState<IPerson[]>(initialPersons);

    const onRemovePerson = (person: IPerson) => {
        if (removePerson) {
            removePerson(person);
        }
        else {
            setPersons(persons.filter((personIn: IPerson) => personIn.id !== person.id));
        }
    };

    const onSelectPerson = (person?: IPerson): void => {
        if (person
            && (person.id && !persons.find((personIn: IPerson) => personIn.id === person.id)
                ||
                !person.id && !persons.find((personIn: IPerson) => personIn.email === person.email)
            )
        ) {
            const personsIn = [
                ...persons,
                person,
            ];
            setPersons(personsIn);
        }
    };

    return {
        persons,
        onSelectPerson,
        setPersons,
        renderPersons: (): ReactElement => {
            return <List
                className="users-list"
                itemLayout="horizontal"
                dataSource={persons}
                renderItem={(personIn: IPerson, index: number) => {
                    const { name, civility, firstName, lastName, email } = personIn;

                    const mailElement = email ? <div>
                        <div><MailOutlined /></div>
                        <div>{email}</div>
                    </div> : undefined;

                    return <List.Item
                        actions={[<Button
                            onClick={() => onRemovePerson(personIn)}
                            className="delete"
                            type="link"
                            size="small"
                            key={'g' + index}
                            icon={<DeleteOutlined />}
                        />]}
                    >
                        <List.Item.Meta
                            title={<div className="list-item-main-info">
                                <div>{name ?? `${civility ?? ''} ${firstName ?? ''} ${lastName ?? ''}`}</div>
                            </div>}
                            description={<div className="list-item-secondary-info">
                                {mailElement}
                            </div>}
                        />
                    </List.Item>;
                }}
                locale={{
                    emptyText: 'Aucun utilisateur',
                }}
            />;
        },
    };
}


const DocumentComponent = (props: IProps): ReactElement => {
    const { getDocument } = useDocumentActions();
    const [form] = Form.useForm<IDocument>();
    const [formValues, setFormValues] = useState<IDocument>();

    const { renderPersons, persons, onSelectPerson } = usePersons();

    const onUpdateMeta = () => {
        setFormValues(form.getFieldsValue());
    };

    useEffect(() => {
        if (props.document) {
            props.update(form.getFieldsValue(), persons);
        }
    }, [persons, formValues]);

    useEffect(() => {
        if (props.document) {
            form.setFieldsValue(props.document);
        }
    }, [props.document]);

    return <div className="document">
        <Viewer
            document={props.document}
            getDocument={() => getDocument(props.document.id)}
        />
        <Form
            className="infos"
            form={form}
            labelCol={{ span: 6 }}
            onFieldsChange={onUpdateMeta}
            initialValues={{
                name: props.document?.name,
            }}
        >
            <Divider plain>Document</Divider>

            <Form.Item name="name" label="Titre">
                <Input maxLength={255} />
            </Form.Item>

            <Divider plain>Partager avec...</Divider>

            <PersonsSearch onChange={onSelectPerson} />
            {renderPersons()}
        </Form>
    </div>;
};

export default memo(DocumentComponent);
