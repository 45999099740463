export default class ApiError extends Error {
    public constructor(protected error: IApiError) {
        super(error.error ?? 'Server error');

        Object.setPrototypeOf(this, ApiError.prototype);
    }

    public get messages(): string[] {
        return this.error?.response?.data?.message ?? this.error.message ?? [];
    }
}
