import en from 'assets/translation/en';
import fr from 'assets/translation/fr';

const translation: Record<string, Record<string, string>> = {
    en: en,
    fr: fr,
};

export default class I18N {
    private static browserLanguage: string = I18N.getLanguageFromBrowser();

    private static getLanguageFromBrowser(): string {
        return navigator.language.split('-')[0];
    }

    public static translate(key: string): string {
        return translation[I18N.browserLanguage][key] || key;
    }
}

