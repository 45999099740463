import { Spin } from 'antd';
import React, { memo, ReactElement, ReactNode } from 'react';
import './index.less';

interface IProps {
    shown?: boolean;
    message?: ReactNode;
}

const Loader = (props: IProps): ReactElement | null => {
    return props.shown ? (
        <div className="loader">
            {props.message}
            <Spin />
        </div>
    ) : null;
};

export default memo(Loader);
